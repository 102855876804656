import React, { Component } from 'react';
import downArrow from '../../../Assests/Images/arrow.svg';
import Arrow from '../../../Assests/Images/SettingsNotOpenedArrow.svg';
import RightArrow from '../../../Assests/Images/Caret-Right.svg';
import '../Settings/settingsStyles.scss';
import {CustomCheckbox} from "../CustomCheckBox";
import {CustomRadio} from "../CustomRadio";
import { SELECTALL, DESELECTALL} from '../../../Common/Constants/constants';
import Highlight from 'react-highlighter';
import DisableIcon from '../../../Assests/Images/Disable.png';

class ApplyAllGroups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Children: this.props.groupData.children,
      groupId: this.props.groupData.value,
      isChildrenDisplayed: this.props.isChildrenDisplayed,
      totalChildCount: this.props.groupData.children.length,
      isSingleSelect: this.props.type || 0
    };
  }

  componentDidUpdate(prevProps) {
    this.updateGroupsList(prevProps) && this.setState({isChildrenDisplayed: this.props.isChildrenDisplayed});
    if(prevProps.groupData !== this.props.groupData && this.props.searchText !== undefined){
      this.setState({Children: this.props.groupData.children, totalChildCount: this.props.groupData.children.length, groupId: this.props.groupData.value})
      if(this.props.searchText.length === 0){
        this.setState({isChildrenDisplayed: this.props.isChildrenDisplayed})
      }
    } 
  }

  updateGroupsList = (prevProps) => {
    if(this.props.isChildrenDisplayed && (prevProps.isChildrenDisplayed !== this.props.isChildrenDisplayed || (this.props.searchPressed && prevProps.searchPressed !== this.props.searchPressed))) {
      return true;
    }
    else {
      return false;
    }
  }

  changeCheckBoxSelection = () => {
    this.props.saveSelectedGroups((this.props.selectedGroups.indexOf(this.props.groupData.value) <= -1), this.state.groupId, this.state.Children);
  }

  expandSettings = () => {
      this.setState({
          isChildrenDisplayed : !this.state.isChildrenDisplayed
      });
      this.props.updateSearch && this.props.updateSearch(false);
  }

  displayChildren = () => {
    if (this.state.Children !== null && this.state.Children !== undefined && this.state.Children && this.state.Children.length > 0 && this.state.isChildrenDisplayed) {
        return (
          this.state.Children.map((ChildGroup, index) => (
            <ApplyAllGroups 
            isChildrenDisplayed={this.props.isChildrenDisplayed} 
            type={this.state.isSingleSelect} 
            key={index} 
            TreeLevel={this.props.TreeLevel + 1} 
            groupData={ChildGroup} 
            saveSelectedGroups={this.props.saveSelectedGroups} 
            selectedGroups={this.props.selectedGroups} 
            searchText={this.props.searchText} 
            updateSearch={this.props.updateSearch} 
            searchPressed={this.props.searchPressed}/>
          ))
        )
    }
  }

  getTreeIcon = (isSingleSelect=false) => {
    if (this.state.isChildrenDisplayed)
      return (
        <div className={isSingleSelect ? "SingleSelectOpenedArrow" : "SettingsOpenedContainer"}>
          <img className="SettingsIconHover" src={downArrow} onClick={this.expandSettings} />
        </div>)
    else {
      return (
        <div className={isSingleSelect ? "NotOpenedArrayContainer" : "SettingsNotOpenedContainer"}>
          <img className={isSingleSelect ? "ArrowRightIcon" :"SettingsArrowRightIcon"} src={isSingleSelect ? RightArrow : Arrow} onClick={this.expandSettings}/>
        </div>
      )
    }
  }

  selectDeselectAll = () => {
    return (
      <div className={"SelectDeselectContainer"}>
	      <span className={"DeselectAll"} onClick={(e) => this.shouldSelectAllChildern(false)}>
          {DESELECTALL}
        </span>
      | 
        <span className={"SelectAll"} onClick={(e) => this.shouldSelectAllChildern(true)}>
          {SELECTALL}
        </span>
      </div>
    )
  }

  shouldSelectAllChildern = (shouldAdd) => {
    if(shouldAdd) {
      this.props.saveSelectedGroups(shouldAdd, this.state.groupId, this.state.Children, true);
    }
    else {
      this.props.saveSelectedGroups(shouldAdd, this.state.groupId, this.state.Children);
    }
  }

  saveSingleSelectSelection = () => {
    this.props.saveSelectedGroups(this.props.groupData);
  }

  displayApplyAllSingleSelect = (Indentation) => {
    let searchText = this.props.searchText;
    let groupName = this.props.groupData.label;
    return (
      <div className="settingsli" style={{ paddingLeft: Indentation + "px" }}>
        <div className="setting">
            { !!this.state.totalChildCount && this.getTreeIcon(true)}
            <div> 
              <CustomRadio color="default" checked={(this.props.selectedGroups === this.props.groupData.value)} onChange={this.saveSingleSelectSelection} /> 
            </div>
            <div className="SettingsExpendedCell groupLabel"> 
            {this.props.isChildrenDisplayed && searchText ? <Highlight search={searchText} matchClass={"searchHighlight"}>{groupName}</Highlight> : groupName}
            {!this.props.groupData.isActive && 
              <img className="GroupDisableIcon" src={DisableIcon} title="disabled" />
            }
        </div>
        </div>
        {this.displayChildren()}
      </div>
    )
  }

  displayApplyAllMultiSelect = (Indentation) => {
    return (
      <div className="settingsli" style={{ paddingLeft: Indentation + "px" }} title={(!this.props.groupData.isActive) ? "Group is disabled":""}>
        <div className={this.props.groupData.isActive && ((this.props.selectedGroups.indexOf(this.props.groupData.parentGroupID) > -1) || (this.props.TreeLevel == 0) ) ? "setting" : "settingdisabled" }>
            <div> 
              <CustomCheckbox color={(this.props.groupData.isActive) ? "default" : "secondary"} checked={(this.props.selectedGroups.indexOf(this.props.groupData.value) > -1)} onClick={this.changeCheckBoxSelection}/> 
            </div>
            { (this.state.totalChildCount > 0) ?
              <div className="SettingsExpendedCell" style={{width: "93%",display: "inline-flex"}}> 
                  <div className="SettingsExpandedCellLabel" onClick={this.expandSettings}>
                      {this.props.groupData.label}
                  </div>
                  {this.selectDeselectAll()}
              </div>
              :
              <div className="SettingsExpendedCell" style={{width: "93%"}}> {this.props.groupData.label}</div>
            }
            { (this.state.totalChildCount > 0) ? this.getTreeIcon() : ''}
        </div>
        {this.displayChildren()}
      </div>
    )
  }

  render() {
    let Indentation = 16;
    if (this.props.TreeLevel == 0) {
      Indentation = 0;
    }
    return this.state.isSingleSelect ? this.displayApplyAllSingleSelect(Indentation) : this.displayApplyAllMultiSelect(Indentation);
  }
}

export default ApplyAllGroups;