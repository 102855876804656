import React, {memo} from "react";
import userAuthStyles from "../UserAuthStyles";
import Grid from '@material-ui/core/Grid';
import {USERS_ACTIVE_LABEL} from '../../../../Common/Constants/constants';

const UserInsights = ({userStats}) => {
    const {data, totalUsersActive} = userStats;
    const {userStatsCount,usersStatsInfoDiv,statsGrid,userStatsRoot,totalUsersh,totalUsersDiv,
        usersInfoContainer} = userAuthStyles();
    return (
    <div className={userStatsRoot}>
        <div className={usersInfoContainer}>
            <div className={totalUsersDiv}>
                <h1 className={totalUsersh}>{totalUsersActive} {USERS_ACTIVE_LABEL}</h1>
            </div>
            <Grid item xs={12} spacing={4} container justify="center" className={statsGrid}>
                {!!data && data.map((dataItem,index) => {
                    const {id,value, label}=dataItem;
                    return (
                        <Grid key={index} key={value} id={id} item className={usersStatsInfoDiv}>
                            <span className={userStatsCount}>{value}</span>
                            {label}
                        </Grid>
                )})}
            </Grid>
        </div>
    </div>
)}

export default memo(UserInsights);