import React, { useState } from "react";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import permissionStyle from './permissionStyle';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { deletePermission } from "./permissionUtil";
import DialogBox from '../../../Shared/OptionsMenu/dialog';
import UpsertPermission from './upsertPermission';
import {Alert} from './PermissionTab';
import Snackbar from '@material-ui/core/Snackbar';
import { DELETEPERMISSIONHEADER, DELETEPERMISSIONCONTENT, DELETETEXT, ADDPERMISSIONSTEXT, UPDATEPERMISSIONSTEXT, PERMISSIONSREMOVEFAIL } from '../../../../Common/Constants/constants';

const PermissionTreeAction = (props) => {
  const { getPermissions, appId, permission, handleListClick, treeExpandStatus} = props;
  const{settingName, settingId, parentSettingId} = permission;
  const [errorOpen, setErrorOpen] = useState(false);
  const [error, seterror] = useState(null);
  const [deleteAction, setDeleteAction] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [upsertAction, setUpsertAction] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const { permissionDiv, listItemBackground, menuLink } = permissionStyle();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const removePermission = async ( parentSettingId, settingId ) => {
    try {
      setDeleteAction(false);
      await deletePermission(appId, parentSettingId, settingId);
      getPermissions()
    } catch (error) {
      seterror(PERMISSIONSREMOVEFAIL)
      setErrorOpen(true);
    }
  }

  const onCloseDeleteEvent = () => {
    setDeleteAction(false);
  }

  const onCloseUpsertEvent = () => {
    setUpsertAction(false);
    if (isEdit) { setIsEdit(false) }
  }

  const onOpenDeleteEvent = () => {
    setDeleteAction(true);
  }

  const onOpenUpsertEvent = (update) => {
    setUpsertAction(true);
    if (update) { setIsEdit(true) }
  }

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
  }

  return (
    <>
       <div className={permissionDiv}>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={menuOpen}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: 22 * 4.5,
                width: '30ch',
              },
            }}
          >
            <MenuItem className={listItemBackground} onClick={handleClose}><AddCircleIcon color="primary" /><div className={menuLink} onClick={() => onOpenUpsertEvent(false)}>{ADDPERMISSIONSTEXT}</div></MenuItem>
            <MenuItem className={listItemBackground} onClick={handleClose}><EditIcon color="primary" /><div className={menuLink} onClick={() => onOpenUpsertEvent(true)}>{UPDATEPERMISSIONSTEXT}</div></MenuItem>
            <MenuItem className={listItemBackground} onClick={handleClose}><DeleteForeverIcon color="primary" /><div className={menuLink} onClick={onOpenDeleteEvent}>{DELETEPERMISSIONHEADER}</div></MenuItem>
          </Menu>
        </div>
      <DialogBox dialogContent={DELETEPERMISSIONCONTENT} dialogHead={DELETEPERMISSIONHEADER} dialogButtonText={DELETETEXT} opennow={deleteAction} onCloseClick={() => removePermission(parentSettingId, settingId)} onCloseEvent={onCloseDeleteEvent} />
      {upsertAction && <UpsertPermission settingName={settingName} isEdit={isEdit} settingId={settingId} getPermissions={getPermissions} onCloseEvent={onCloseUpsertEvent} handleListClick={handleListClick} treeExpandStatus={treeExpandStatus} />}
      <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleErrorClose}>
        <Alert onClose={handleErrorClose} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </>
  )
}

export default PermissionTreeAction;