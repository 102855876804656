import React,{createRef} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import SearchWithSuggestions from '../SearchWithSuggestions/SearchWithSuggestions';
import Typography from '@material-ui/core/Typography';
import { SendInvitationStyles, HVAC_EMAIL_SPECIAL_CHAR_ERROR, HVAC_EMAIL_PLACEHOLDER, OR, EXTERNALEMAILREQUIREDERROR, EXTERNALEMAILINVALIDERROR, EMAILDISCLAIMER, INVITE, EXTERNALEMAILDOMAINERROR, COMPANY_DOMAIN, EXTERNALEMAILPLACEHOLDER,GET_CUSTOMEMAIL_TEMPLATE,CUSTOMTEMPLATEHEADER,NEWTEMPLATE,OVERRIDETEMPLATE,CREATE_UPDATE__NEW_TEMPLATE,SAVETEMPLATEBODY,CUSTOMTEMPLATE } from '../../../Common/Constants/constants';
import { intlShape, injectIntl } from "react-intl";
import { injectIntlTranslation } from "../../../Common/Utilities/utility";
import HvacLogo from '../../../Assests/Images/HvacLogo_New.png';
import Mail  from '../../../Assests/Images/Mail.svg';
import CustomEmailTemplate from './customEmailTemplate'
import appConfig from '../../../Environment/environments';
import axiosInstance from '../../Shared/Interceptor/interceptor';
import DialogBox from "../../Shared/OptionsMenu/dialog";

const styles = SendInvitationStyles;

class SendInvitation extends React.Component {

  constructor(props) {
    super(props);
    this.childRef = createRef();
    this.state = {
      externalUserEmail: '',
      hvacUser: '',
      name: '',
      nameError: '',
      userType: '',
      selectedUserInfo: '',
      selectedUserEmail: '',
      selectedUPN: '',
      selectedCarcglUserInfo: '',
      selectedCarcglUserEmail: '',
      selectedCarcglUPN: '',
      hvacNameError: '',
      isEditEnabled:false,
      customEmailTemplates:[],
      selectedFName : '',
      selectedLName : '',
      inviteEnabled:true,
      templateDetails:{id:'',groupId:'',supporEmail:'',subject:'',body:'',isSelected:false,isModified:false,name:'',title:'',icon:'',bannerExt:'',banner:''},
      opennow:false,
      default:'',
      resetSaveTemp:true,
      duplicateTemplateMessage:'',
      isInviteCall:false,
    }

    this.onInviteButtonHandler = this.onInviteButtonHandler.bind(this);
    this.handleData = this.handleData.bind(this);
    this.onTemplateChange = this.onTemplateChange.bind(this);  
    this.clearChildState = null
    
  }

  externalUserEmailChangeHandler = externalUserEmail => event => {
    //this.childRef.current.cleanTemplateForm()
    this.setState({ isEditEnabled:false })
    this.setState({ [externalUserEmail]: event.target.value }, () => {
      this.validateEmail();
    });
  };

  validateEmail = () => {
    const { externalUserEmail } = this.state;
    this.setState({
      nameError: this.verifyEmailFormatAndDomain(externalUserEmail, this.props)
    });
  }

  verifyEmailFormatAndDomain(externalUserEmail, properties) {
    let verifyEmailStatus = null;
    if (externalUserEmail == "") {
      if (properties.loggedInUserType === 0) {
        verifyEmailStatus = '';
      } else {
        verifyEmailStatus = EXTERNALEMAILREQUIREDERROR;
      }
    } else {
      if (this.validateEmailRegex(externalUserEmail)) {
        verifyEmailStatus = EXTERNALEMAILINVALIDERROR;
      } else if (externalUserEmail.match(COMPANY_DOMAIN)) {
        verifyEmailStatus = EXTERNALEMAILDOMAINERROR;
      }
    }

    return verifyEmailStatus;
  }

  validateEmailRegex(email) {
    if (email != "") {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return !(re.test(email));
    } else {
      return null
    }
  }

  onInviteButtonHandler(e) {
    e.preventDefault();
    if((!this.state.templateDetails.isModified  || this.props.isAppInvite))
    {
      let defaultSelectedId = localStorage.getItem("emailTemplateId")|| undefined;
      localStorage.setItem("emailTemplateId", this.state.templateDetails.id==="" && !defaultSelectedId?1:(this.state.templateDetails.id==="" && defaultSelectedId ? defaultSelectedId : this.state.templateDetails.id));
      this.inviteCallPostTemplateOptionSelect(this.state.templateDetails.id==="" && !defaultSelectedId?1:(this.state.templateDetails.id==="" && defaultSelectedId ? defaultSelectedId : this.state.templateDetails.id));
    }
  else{
    if(this.state.templateDetails.subject!=''||this.state.templateDetails.body!=''||this.state.templateDetails.supporEmail!='')
    this.setState({ isInviteCall: true })
    this.setState({ opennow: true })
  }
  }

  onSaveTemplateButtonHandler=(e)=> {
    e.preventDefault();
    if(this.state.templateDetails.subject!=''||this.state.templateDetails.body!=''||this.state.templateDetails.supporEmail!='')
    {
    this.setState({ opennow: true })
    }
  }

  inviteCallPostTemplateOptionSelect=(templateId)=>{
    if ((this.props.loggedInUserType === 2 || this.state.hvacUser !='') && this.isNullOrUndefined(this.state.externalUserEmail) && this.isNullOrUndefined(this.state.selectedUserEmail) && this.isNullOrUndefined(this.state.selectedCarcglUserEmail)) {
      const { hvacUser } = this.state;
      const hvacObject = {
        groupId: this.props.Id,
        isManager: this.props.isManager,
        userType: 2,
        email: "",
        name: "",
        upn: "",
        isCarCGL: true,
        hvacUserName: hvacUser,
        template:{
          id:templateId?templateId:this.state.templateDetails.id,
          body:this.state.templateDetails.title,
          subject: this.state.templateDetails.subject,
          message: this.state.templateDetails.body,
          support: this.state.templateDetails.supporEmail,
          isSelected: true,
          banner:this.state.templateDetails.bannerExt

        }
      };
      this.props.inviteHandleClose(hvacObject, false, true);
    }
    else {
      this.inviteHandler(templateId);
    }
  }

  onTemplateChange(e){
    e.preventDefault();
  }

  inviteHandler = (templateId) => {
    let specifiedFieldsCount = 0;
    const externalUserEmailCheck = this.isNullOrUndefined(this.state.externalUserEmail);
    const selectedUserEmailCheck = this.isNullOrUndefined(this.state.selectedUserEmail);
    const selectedCarcglUserEmailCheck = this.isNullOrUndefined(this.state.selectedCarcglUserEmail);
    const selectedHvacUserName = this.isNullOrUndefined(this.state.hvacUser);

    !externalUserEmailCheck && (specifiedFieldsCount = specifiedFieldsCount + 1);
    !selectedUserEmailCheck && (specifiedFieldsCount = specifiedFieldsCount + 1);
    !selectedCarcglUserEmailCheck && (specifiedFieldsCount = specifiedFieldsCount + 1);
    !selectedHvacUserName && (specifiedFieldsCount = specifiedFieldsCount + 1);

    const isBothUserTypesSelected = (specifiedFieldsCount === 1) ? false : true;
    const email = ((externalUserEmailCheck) ? ((selectedUserEmailCheck) ? this.state.selectedCarcglUserEmail : this.state.selectedUserEmail) : this.state.externalUserEmail);
    const UPN = ((externalUserEmailCheck) ? ((selectedUserEmailCheck) ? this.state.selectedCarcglUPN : this.state.selectedUPN) : this.state.externalUserEmail);
    const name = (this.state.selectedUserInfo) ? this.state.selectedUserInfo : this.state.selectedCarcglUserInfo;
    const obj = {
      groupId: this.props.Id,
      isManager: this.props.isManager,
      userType: (externalUserEmailCheck ? 0 : 1),
      email: email,
      name: name,
      UPN: UPN,
      fName: this.state.selectedFName,
      lName: this.state.selectedLName,
      template:{
        id:templateId?templateId:this.state.templateDetails.id,
        body:this.state.templateDetails.title,
        subject: this.state.templateDetails.subject,
        message: this.state.templateDetails.body,
        support: this.state.templateDetails.supporEmail,
        isSelected: true,
        banner:this.state.templateDetails.bannerExt
      }
    }
    !selectedCarcglUserEmailCheck && (obj.isCarCGL = true)
    if (obj.email !== "") {
      const updatedObj = JSON.stringify(obj);
      this.props.inviteHandleClose(updatedObj, isBothUserTypesSelected);
    }
  }

  handleData(isCarCgl, data, email, userPrincipalName,fName, lName) {
   // this.childRef.current.cleanTemplateForm()
    if (isCarCgl) {
      console.log(isCarCgl)
      console.log(data)
      this.setState({
        selectedCarcglUserInfo: data,
        selectedCarcglUserEmail: email,
        selectedCarcglUPN: userPrincipalName,
        selectedFName: fName,
        selectedLName: lName,
        isEditEnabled:false
      });
    }
    else {
      console.log(data)
      this.setState({
        selectedUserInfo: data,
        selectedUserEmail: email,
        selectedUPN: userPrincipalName,
        selectedFName: fName,
        selectedLName: lName,
        isEditEnabled:false
      });
    }
  }

  isNullOrUndefined(data) {
    return ((data === '' || data === " " || data === null || data === "null" || data === undefined) ? true : false);
  }

  createInternalUserField(classes, callback, isCarCgl = false, autoFocus = false) {
    return (
      <div className={classes.internalUserTextField}>
        <div className={classes.internalUserEmailTextField}>
          <SearchWithSuggestions autoFocus={autoFocus} isCarCgl={isCarCgl} getSelectedUserInfo={callback}
          />
        </div>
        <p className={classes.internalUserORText}>
          {OR}
        </p>
      </div>
    )
  }

  hvacFieldValidation = ({ target: { value } }) => {
    //this.childRef.current.cleanTemplateForm()
    this.setState({ isEditEnabled:false })
    const { hvacUser } = this.state;
    const hvacRegEx = /^(\d|\w)+$/;
      const hvacRegExTest = value != ''? !(hvacRegEx.test(value)):false;
    this.setState({
      hvacUser: value,
      hvacNameError:
        (hvacRegExTest ? HVAC_EMAIL_SPECIAL_CHAR_ERROR : '')
    });
  }

  createUserField = (classes, intl) => {
    const userPropsForExtrnal = {
      value: this.state.externalUserEmail,
      placeholder: injectIntlTranslation(intl, EXTERNALEMAILPLACEHOLDER),
      onChange: this.externalUserEmailChangeHandler('externalUserEmail')
    };

    return (
      <div className = {classes.nonInternalUser}>
      <img src={Mail} className = {classes.mailLogo}/>
      <TextField
            required
            InputProps={{
              classes: {
                root: classes.inputRoot,
                input: classes.inputStyles,
                focused: `${this.state.nameError ? classes.invalidTextField : classes.inputFocused}`,
                underline: `${this.state.nameError ? classes.invalidTextField : classes.inputUnderline}`
              }
            }}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused
              },
              required: false
            }}
            autoComplete="off"
            className={classes.externalUserEmailTextField}
            margin="normal"
            {...userPropsForExtrnal} />
      </div>
    )
  }

  createHvacUserField = (classes, intl) => {
    const userPropsForHvac = {
      value: this.state.hvacUser,
      placeholder: injectIntlTranslation(intl, HVAC_EMAIL_PLACEHOLDER),
      onChange: this.hvacFieldValidation
    };
    return (
        <div>
          <img src={HvacLogo} className = {classes.hvacLogo}/>
        <TextField
            required
            InputProps={{
              classes: {
                root: classes.inputRoot,
                input: classes.inputStyles,
                focused: `${this.state.hvacNameError ? classes.invalidTextField : classes.inputFocused}`,
                underline: `${this.state.hvacNameError ? classes.invalidTextField : classes.inputUnderline}`
              }
            }}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused
              },
              required: false
            }}
            autoComplete="off"
            className={classes.hvacUserEmailTextField}
            margin="normal"
            {...userPropsForHvac} />
        </div>
    )
  }

  editCustomizeInvite=()=>{
    axiosInstance.get(`${appConfig.api.development}${GET_CUSTOMEMAIL_TEMPLATE}${this.props.Id.toString()}&templateId=${"0"}`, {
      method: 'GET',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      }
  }).then(async res => {
    if(res != null && res.data != null)
    {
      let defaultSelectedId = localStorage.getItem("emailTemplateId")|| undefined;
    this.setState({
      default:(defaultSelectedId?res.data.find((obj) => obj.id === parseInt(defaultSelectedId) ):res.data.find((obj) => obj.id === 1)),
      isEditEnabled:true,
      customEmailTemplates:res.data,
    });
    }
  })
  }

  enableInvite=(isenable)=>{
    this.setState({
      inviteEnabled:isenable
    });
  }

  handleCallback = (childData) => {
    this.setState({
      templateDetails:childData
    });
}

onCloseEvent = (props) => {
  this.setState({ opennow: false,duplicateTemplateMessage:'' ,isInviteCall: false})
  this.clearChildState();
}

onSaveTemplate=()=>{
  if(this.state.templateDetails.name!='')
  {
    const templateObject = {
      id:"0",
      entityId:this.state.templateDetails.groupId,
      name: this.state.templateDetails.name,
      subject: this.state.templateDetails.subject,
      message: this.state.templateDetails.body,
      support: this.state.templateDetails.supporEmail,
      isSelected: true,
      body:this.state.templateDetails.title,
      banner:this.state.templateDetails.banner,
      bannerExt:(this.state.templateDetails.id && this.state.templateDetails.bannerExt!='' && (this.state.templateDetails.banner==''||this.state.templateDetails.banner==undefined))?this.state.templateDetails.id+'.'+this.state.templateDetails.bannerExt:this.state.templateDetails.bannerExt
    }
    this.axiosCallForTemplateUpdateAndCreate(templateObject)
    this.clearChildState();
  }
}

onOverrideTemplate=()=>{
  if(this.state.templateDetails.id!=1)
  {
    const templateObject = {
      id:this.state.templateDetails.id,
      entityId:this.state.templateDetails.groupId,
      name: this.state.templateDetails.name,
      subject: this.state.templateDetails.subject,
      message: this.state.templateDetails.body,
      support: this.state.templateDetails.supporEmail,
      isSelected: true,
      body:this.state.templateDetails.title,
      banner:this.state.templateDetails.banner,
      bannerExt:(this.state.templateDetails.id && this.state.templateDetails.bannerExt!='' && (this.state.templateDetails.banner==''||this.state.templateDetails.banner==undefined))?this.state.templateDetails.id+'.'+this.state.templateDetails.bannerExt:this.state.templateDetails.bannerExt
     
    }
    this.axiosCallForTemplateUpdateAndCreate(templateObject)
    
  }
}

axiosCallForTemplateUpdateAndCreate=(templateObject)=>{
  const url = CREATE_UPDATE__NEW_TEMPLATE;
  axiosInstance.post(appConfig.api.development + url, templateObject, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  })
  .then(res => {
    if (res){
      this.setState({ opennow: false,duplicateTemplateMessage:'' })
      if(this.state.templateDetails.isModified && this.state.isInviteCall)
      {
        this.setState({ isInviteCall: false })
        localStorage.setItem("emailTemplateId", res.data);
        this.inviteCallPostTemplateOptionSelect(res.data);
      }
      this.resetIsModified()
    this.childRef.current.cleanTemplateForm()
    this.editCustomizeInvite()
  }
  })
  .catch(error => {
    if(error.response.data.code==0)
    {
      let errorMessage = error.response.data.message
      this.setState({  duplicateTemplateMessage:errorMessage})
    }
  })
}

 resetIsModified=()=>{
  this.setState(prevState => ({
    templateDetails:{...prevState.templateDetails,isModified:false}
    }));
 }
selectedTemplate=(templateName)=>{
  this.setState(prevState => ({
    templateDetails:{...prevState.templateDetails,name:templateName}
    }));
}

updateSelectedValue=(selectedTemplate)=>{
  this.setState({
    default:selectedTemplate
  });
}

assignClearChildState=(childClearStateFunc) =>{
  this.clearChildState = childClearStateFunc;
}

  render() {
    const { classes } = this.props;
    const intl = this.props.intl;
    return (
      <div className={classes.userEmailInfo}>
        {
          (this.props.loggedInUserType === 0) &&
          <>
            <Typography classes={{ root: classes.disclaimer }}>
              {EMAILDISCLAIMER}
            </Typography>
            {this.createInternalUserField(classes, this.handleData, true, true)}
          </>
        }
        {/* {
          (this.props.loggedInUserType === 0 || this.props.loggedInUserType === 2) &&
          <>
          {this.createHvacUserField(classes, intl)}
          {this.state.hvacNameError &&
          <div className={classNames(classes.errorText,  classes.hvacError) }>{this.state.hvacNameError}</div>}
          <p className={classes.internalUserORText}>
          {OR}
        </p>
          </>
        } */}
        { this.createUserField(classes, intl)}
        <div className={classNames(classes.errorText, classes.externalError)}>{this.state.nameError}</div>
        {
          ((!this.state.isBothUserTypesSelected && this.state.nameError === null) || (this.state.hvacNameError === '' && this.state.hvacUser) || (this.state.selectedUserInfo) || (this.state.selectedCarcglUserInfo)) &&
          <>
           <span style={{"color":"white"}}> 
          <div className={ (this.state.isEditEnabled ==false && !this.props.isAppInvite) ? classes.editBody  : classes.customTemplateBody }>
           {this.state.isEditEnabled ==false && !this.props.isAppInvite ? 
            <>
             <EditIcon className={classes.editInvite} onClick={this.editCustomizeInvite}></EditIcon>
            <span className={classes.editInviteText}>{CUSTOMTEMPLATE}</span></>:(!this.props.isAppInvite ? 
           <CustomEmailTemplate  intl={this.props.intl} ref={this.childRef} default={this.state.default} 
            emailReciever={this.state.externalUserEmail|| this.state.selectedCarcglUserEmail || this.state.hvacUser}
             customEmailTemplates={this.state.customEmailTemplates}  supportEmailValidator={this.validateEmailRegex} 
             enabledInviteButton={this.enableInvite} templateDetailsUpdate={this.handleCallback} loggedInUserName={this.props.loggedInUserName}
             loadCustomInviteTemplate={this.editCustomizeInvite} groupId={this.props.Id} groupName={this.props.groupName} 
             updateSelectedValue={this.updateSelectedValue} onSaveTemplateButtonHandler={this.onSaveTemplateButtonHandler}></CustomEmailTemplate>
            :<></>
            )
            }
            </div>
            <div className={ (this.state.isEditEnabled ==false) ? classes.inviteUserButtonBeforeEdit  : classes.inviteUserButton }>
           <Button
              type="submit"
              variant="contained"
              disableRipple
              className={classNames(classes.margin, classes.updatebutton, classes.overRideInvite)}
              disabled={this.state.inviteEnabled ==true?false:true}
              onClick={this.onInviteButtonHandler}
            >
              {INVITE}
            </Button>
            </div>
          </span>
          </>
        }
        <DialogBox passClearStateFunc={this.assignClearChildState} selectedTemplate={this.state.templateDetails} 
        dialogContent={SAVETEMPLATEBODY} classes={classes} intl={this.props.intl}  
        dialogHead={CUSTOMTEMPLATEHEADER} dialogDisclaimer={""} dialogButtonText={OVERRIDETEMPLATE} 
        saveTemplate={NEWTEMPLATE} opennow={this.state.opennow} onCloseEvent={this.onCloseEvent} 
        onSaveTemplate={this.onSaveTemplate}   onCloseClick={this.onOverrideTemplate} duplicateTemplateMessage={this.state.duplicateTemplateMessage}
        templateValue={this.selectedTemplate}/>
      </div>
    )
  }
}

SendInvitation.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired
};

export default injectIntl(withStyles(styles, { withTheme: true })(SendInvitation));