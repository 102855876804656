import React, { useEffect, useState, useRef } from "react";
import './LangDropDown.scss';

const LangDropdown = ({ selectedItem, options, onChange }) => {
  
  const node = useRef();

  const [open, setOpen] = useState(false);

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      return;
    }
    setOpen(false);
  };

  const handleChange = (e, selectedObj) => {
    onChange(selectedObj);
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  
  return (
    <div ref={node} className="dropdown" id="languagedd">
      <span role="button" className="dropdown-toggler text-uppercase" onClick={e => setOpen(!open)}>
        <img src={selectedItem.img} alt={selectedItem.name} /> {selectedItem.name} <span className="caret" />
      </span>
      {open && (
        <ul id="langList" className="dropdown-menu">
          {options.map((opt, idx) => (
            <li className={opt.name === selectedItem.name ? 'text-uppercase active' : 'text-uppercase'}  key={idx} onClick={e => handleChange(e, opt)}>
              <img src={opt.img} alt="" /> {opt.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LangDropdown;