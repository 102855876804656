import React, { useState,useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import { usersAutoCompleteStyles } from "./usersAutoCompleteStyles";
import { USERSLIST, SEARCHERROR } from "../../../Common/Constants/constants";
import FilterUserList from "../../Groups/filterUserList";
import { apiGet } from "../Interceptor/apiService";
import CircularProgress from '@material-ui/core/CircularProgress';

export default function UsersAutoComplete(props) {
  const { saveSelectedUsers, selectedTab, selectedUsersList = [] } = props;
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [timer, setTimer] = useState(null);

  const { autoCompleteOptions, nonErrorBorder, autoCompleteDiv } = usersAutoCompleteStyles();

  useEffect(() => {
    setOpen(false);
    getUsersList(searchQuery);
  }, [ selectedTab ]) 

  const getUsers = async (query) => {
    setLoading(true);
    const queryText = (query.replace(/\s\s+/g, ' ')).replace(/ /g, '+');
    const apiUrl = `${USERSLIST}${queryText}&isAdmins=${selectedTab ? true : false}`;
    const { data = [] } = await apiGet(apiUrl);
    if (data) {
      setOptions(data);
      (data.length >= 0) && setOpen(true);
    }
    setLoading(false);
  };

  const handleChange = (event, values) => {
    setSearchQuery("");
    saveSelectedUsers(values);
  }

  const getUsersList = (value) => {
    setOpen(false);
    if (timer) clearTimeout(timer);
    let timeOut = setTimeout(() => {
      if (value) {
        getUsers(value);
      }
    }, 300);
    setTimer(timeOut);
  }

  const onSeachTextChange = ({ target: { value } }) => {
    setSearchQuery(value);
    getUsersList(value);
  };

  const removeOption = (userId) => {
    const selectedOptions = selectedUsersList.filter(
      (option) => option.userId !== userId
    )
    saveSelectedUsers(selectedOptions);
  };

  const isOptionSelected = (userId) => {
    return (selectedUsersList.filter((option) => option.userId === userId).length > 0);
  }

  const getSortedOptions = (options = []) => {
    return options.sort((x, y) => {
      const isXSelected = isOptionSelected(x.userId);
      const isYSelected = isOptionSelected(y.userId);
      return ((isXSelected === isYSelected) ? 0 : isXSelected ? -1 : 1)
    })
  }

  return (
    <div className={autoCompleteDiv}>
      <Autocomplete
        multiple
        id="usersAutoComplete"
        limitTags={30}
        filterOptions={x => x}
        noOptionsText={SEARCHERROR}
        open={open}
        onOpen={() => {
          setOpen(searchQuery ? true : false);
        }}
        onClose={() => {
          setOpen(false);
        }}
        loading={loading}
        options={getSortedOptions(options)}
        disableCloseOnSelect
        classes={{
          option: autoCompleteOptions
        }}
        getOptionLabel={(option) => option.name}
        value={selectedUsersList}
        inputValue={searchQuery}
        renderTags={(values) =>
          values.map((value) => (
            <Chip
              key={value.userId}
              label={(value.name == ' ')? value.email : value.name}
              onDelete={() => {
                removeOption(value.userId);
              }}
            />
          ))
        }
        getOptionSelected={(option, value) => option.userId === value.userId}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <FilterUserList
              isSelected={selected}
              option={option}
              searchText={searchQuery}
              searchPressed={searchQuery ? true : false}
            />
          </React.Fragment>
        )}
        style={{ width: 500 }}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            onChange={onSeachTextChange}
            InputProps={{
              ...params.InputProps,
              notchedOutline: nonErrorBorder,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        )}
      />
    </div>
  );
}