import { LOCALE_SET_START, LOCALE_SET_FULFILLED, LOCALE_SET_FAILED } from '../../Common/Constants/constants';

const initialState = {
    lang: 'en',
    name: 'English',
    error: ''
};

export default function localeReducer(state = initialState, action){
    switch(action.type){
        case LOCALE_SET_START:
            return {
                ...state,
            }
        case LOCALE_SET_FULFILLED:
            return {
                ...state,
                lang: action.data.lang,
                name: action.data.Language,
                unit: action.data.UnitSystem
            }
        case LOCALE_SET_FAILED:
            return {
                ...state,
                error: action.error
            }
        default:
        return state;
    }
}