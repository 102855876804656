import React from 'react';
import { GROUPNAME } from '../../Common/Constants/constants';
import TextField from '@material-ui/core/TextField';


export default function CustomTextField(props) {
  let index = (props.id) ? props.id : '';
  let inputWithSaveProps = { classes: { root: props.classes.inputRoot, input: props.classes.inputStyles, focused: `${props.nameError ? props.classes.invalidTextField : props.classes.inputFocused}`, underline: `${props.nameError ? props.classes.invalidTextField : props.classes.inputUnderline}` } };
  return (
    <>
        <TextField
            required
            disabled={props.disabled ? props.disabled : false}
            id={"groupName" + index}  
            label={(props.label) ? props.label : GROUPNAME}
            multiline
            rowsMax="6"
            value={props.name}
            InputProps={(props.type === 'app') ? {...inputWithSaveProps, ...props.endAdornment}: inputWithSaveProps}
            InputLabelProps={{
            classes: {
                root: props.classes.labelRoot,
                focused: props.classes.labelFocused
                },
                required: false
            }}
            onChange={props.nameChangeHandler}
            onBlur={props.validateName}
            className={props.classes.groupNameTextField}
            margin="normal"
        />
        </>
  );
}
