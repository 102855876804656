import React, { Component } from 'react';
import { connect } from 'react-redux';
import BlackEdit from '../../Assests/Images/Edit.svg';
import downArrow from '../../Assests/Images/arrow.svg';
import Arrow from '../../Assests/Images/Caret-Right.svg';
import '../../Assests/Styles/Styles.scss';
import GroupService from './Groups.services';
import Globe from '../../Assests/Images/Globe.svg';
import ReactTooltip from 'react-tooltip'
import { LOADING } from '../../Common/Constants/constants';
import Highlight from 'react-highlighter';
import DisableIcon from '../../Assests/Images/Disable.png';

class Group extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Children: [],
      groupdId: "",
      flag: false,
      isChildrenDisplayed: false,
      expand: false,
      LoadingChildren: (this.props.expandedGroups.indexOf(this.getGroupId) > -1) ? true : false,
    };
    (this.props.expandedGroups.indexOf(this.getGroupId) > -1) && this.handleHeaderClick(this.getGroupId, this.props.Group.groupCountIncludingChildren);
  }
  
  getGroupId() {
    return this.props.Group.groupId;
  }

  static getDerivedStateFromProps(props, state) {
    if(props.searchPressed && !state.expand){
     return {isChildrenDisplayed: true, Children: props.Group.children, groupdId: props.Group.groupId}
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps.searchPressed!==this.props.searchPressed && prevProps.searchText!==this.props.searchText && this.props.Group.children!==undefined){
      if(this.props.searchText.length === 0){
        this.setState({isChildrenDisplayed: false, Children: [], groupdId: "", expand: false})
      }
    }
    if(prevProps.clearFilter!==this.props.clearFilter){
      this.setState({isChildrenDisplayed: false, Children: [], groupdId: ""})
    }
  }

  handleHeaderClick(id, userCountIncludingChildren) {
    if(this.props.searchPressed){
      this.setState({
        isChildrenDisplayed:!this.state.isChildrenDisplayed,
        expand: !this.state.expand
      })
    }
    else{
      if (!this.state.isChildrenDisplayed && userCountIncludingChildren > 0) {
        if (this.state.Children && this.state.Children.length > 0) {
          this.setState({
            isChildrenDisplayed: !this.state.isChildrenDisplayed,
          }, () => {
            (this.state.isChildrenDisplayed) ? this.props.toBeExpandedGroups(true, id): this.props.toBeExpandedGroups(false, id);
          });
        } else {
          this.setState({
            LoadingChildren: true
          })
          GroupService.getGroupsById(id, (result) => {
            this.setState({
              Children: result,
              isChildrenDisplayed: !this.state.isChildrenDisplayed,
              LoadingChildren: !this.state.LoadingChildren
            });
            this.props.toBeExpandedGroups(true, id);
          })

          this.setState({groupdId:id})
        }
      } 
      else {
        this.setState({
          isChildrenDisplayed: !this.state.isChildrenDisplayed
        });
        this.props.toBeExpandedGroups(false, id);
      }
    }
  }

  DisplayChildren(id) {
    if (this.state.Children && this.state.Children.length > 0 && this.state.isChildrenDisplayed) {
      return (
        this.state.Children.map((ChildGroup, index) => (
          <Group 
          key={index} 
          TreeLevel={this.props.TreeLevel + 1} 
          Group={ChildGroup} 
          toBeExpandedGroups={this.props.toBeExpandedGroups} 
          expandedGroups={this.props.expandedGroups} 
          setEditGroupId={this.props.setEditGroupId}
          searchPressed= {this.props.searchPressed}
          searchText={this.props.searchText}
          clearFilter={this.props.clearFilter}
          filterApplied={this.props.filterApplied}
          usersList= {this.props.usersList}
          userPermissions={this.props.userPermissions}
          />
        ))
      )
    } else {
      return null;
    }
  }
  GetTreeIcon(userCountIncludingChildren, id) {
    if (userCountIncludingChildren > 0 && this.state.isChildrenDisplayed || (this.props.expandedGroups.indexOf(id) > -1))
      return <img className="ArrowIcon" src={downArrow} onClick={() => this.handleHeaderClick(id, this.props.Group.groupCountIncludingChildren)} />
    else if (userCountIncludingChildren > 0 && !this.state.isChildrenDisplayed) {
      return (
        <div className="NotOpenedArrayContainer" onClick={() => this.handleHeaderClick(id, this.props.Group.groupCountIncludingChildren)}>
          <img className="ArrowRightIcon" src={Arrow} />
        </div>
      )
    } else {
      return <img className="ArrowIcon" src={Globe} />
    }
  }
  DisplayLoadingBar() {
    if (this.state.LoadingChildren && (this.props.Group.groupCountIncludingChildren > 0)) {
      return <span>{LOADING}</span>
    } else {
      return null
    }
  }
  StyleChildrenNumberData(ChildrenNumber) {
    if (ChildrenNumber > 0)
      return ChildrenNumber
    else {
      return null
    }
  }

  render() {
    var Indentation = 16;
    let groupId =  this.getGroupId();
    let groupName = this.props.Group.groupName;
    let highlightText = this.props.searchPressed && this.props.searchText ? this.props.searchText 
    : ((this.props.usersList.length > 0 || this.props.userPermissions.length > 0) && this.props.Group.isMatch ? groupName : "");
    if (this.props.TreeLevel == 0)
      Indentation = 0
    return (
      <div className="Groupli" style={{ paddingLeft: Indentation + "px" }}>
        <div className="Group">
          {this.GetTreeIcon(this.props.Group.groupCountIncludingChildren, groupId)}
          <div onClick={() => this.handleHeaderClick(groupId, this.props.Group.groupCountIncludingChildren)} className="ExpendedCell">
            {((this.props.searchPressed && this.props.searchText) || (this.props.usersList && this.props.usersList.length > 0) || this.props.userPermissions && this.props.userPermissions.length > 0 )
            ? <Highlight search={highlightText} matchClass={"groupHighlight"}>{groupName}</Highlight>
            : groupName
            }
            {!this.props.Group.isActive && 
              <img className="GroupDisableIcon" src={DisableIcon} title="disabled" />
            }
          </div>
          <div data-tip={ "Edit " + groupName} className="ClickableColumn"><img className="GroupEdit" onClick={() => this.props.setEditGroupId(groupId)} src={BlackEdit} xs={2} /></div>
          <ReactTooltip place="top" type="dark" effect="float" />
          <div className="HeaderIconColumn">{this.StyleChildrenNumberData(this.props.Group.groupCountIncludingChildren)}</div>
          <div className="HeaderIconColumn">{this.props.Group.adminCount}</div>
          <div className="HeaderIconColumn">{this.props.Group.userCountIncludingChildren}</div>
        </div>
        {this.DisplayLoadingBar()}
        {this.DisplayChildren(groupId)}
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  usersList: state.filter.usersList,
  userPermissions: state.filter.userPermissions
});
export default connect(mapStateToProps,{})(Group);