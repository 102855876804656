import axiosInstance from '../../Shared/Interceptor/interceptor';
import appConfig from '../../../Environment/environments';
import {ADMINDATA,GROUPUSER} from '../../../Common/Constants/constants';
import {encryptData} from '../../../Common/Utilities/utility';

const apiHeaders = {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
    }
}

const getAllAdminData = async(id, successCallback, failureCallback) => {
    if (id) {
      try {
        const response = await axiosInstance.get(`${appConfig.api.development}${ADMINDATA}${encryptData(id).toString()}`, apiHeaders);
        successCallback && successCallback(response);
      }
      catch(error){
        failureCallback ? failureCallback(error) : console.log(error)
      }
    }
}

const getAllUserAdminData = async(id, successCallback, failureCallback) => {
    if (id) {
      try {
        const response = await axiosInstance.get(`${appConfig.api.development}${GROUPUSER}${encryptData(id).toString()}`, apiHeaders);
        successCallback && successCallback(response);
      }
      catch(error){
        failureCallback ? failureCallback(error) : console.log(error)
      }
    }
}

export {getAllUserAdminData,getAllAdminData};