import { makeStyles } from '@material-ui/core/styles';
const DomainsStyles = makeStyles(theme => ({
    domainContainer: {
        display: "flex",
        paddingBottom: "5px"
    },
    domainErrorMessage: {
        color: "#eb1616"
    },
    domainsChipContainer: {
        margin: "7px 0px",
        maxHeight: "100px",
        overflowX: "hidden"
    },
    domainsChip: {
        margin: "3px 3px 0px 3px"
    },
    domainInputContainer: {
        width: "91%",
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "unset"
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: "unset"
        }
    },
    domainInput: {
        padding: "11px 0px 11px 11px"
    },
    hrLine : {
      marginTop: "20px",
      border: "1px solid #b8b8b8"
    },
    retentionInputContainer: {
      padding: "0px 5px 0px 5px",
      margin: "0px 5px 0px 5px",
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "unset"
      },
      "& .MuiInputLabel-outlined.Mui-focused": {
          color: "unset"
      },
      "& .MuiOutlinedInput-input" : {
        padding : "10px"
      }
	  },
}));

export default DomainsStyles;