import React, {memo} from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

function TabBuilder(props) {
    const { tabs, selectedTab, handleTabChange } = props;

    const tabChangeHandler = (event, value) => {
        handleTabChange && handleTabChange(event, value);
    }

    return (
        <div>
            <Tabs
                value={selectedTab}
                onChange={tabChangeHandler}
                indicatorColor="primary"
                textColor="primary">
                    { tabs.map(tab => <Tab label={tab} />) }
            </Tabs>
        </div>
    )
};

export default memo(TabBuilder);