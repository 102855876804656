import { makeStyles } from '@material-ui/core/styles';

const permissionStyle = makeStyles({
  permissiontab: {
    width: "100%",
    display: 'flex',
    flexDirection: 'column',
    height: '99vh'
  },
  permissionDiv: {
    display: "flex",
    flexDirection: "row",
    "column-gap": "10px"
  },
  flexDiv: {
    "flex-direction": "column"
  },
  rootContainer: {
    "display": "flex",
    "flex-direction": "column",
    "height": "85%",
    "overflow-x": "hidden",
    "overflow-y": "auto"
  },
  nested: {
    paddingLeft: "10px"
  },
  listItemBackground: {
    backgroundColor: "white",
    "border-bottom": "1px solid #F7F7F7"
  },
  permissionHeading: {
    display: "flex",
    "column-gap": "400px"
  },
  permission: {
    display: "flex",
    flexDirection: "column",
    "row-gap": "50px"
  },
  addRootPermission: {
    opacity: '1',
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: '1rem',
    color: '#fff',
    lineHeight: '18px',
    backgroundColor: '#0033ab',
    borderRadius: '3px',
    minWidth: 72,
    minHeight: '0',
    height: '35px',
    margin: '8px 5px',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontWeight: '400',

    '&:hover': {
      borderRadius: '3px',
      fontWeight: '400',
      textDecoration: 'underline',
      boxShadow: 'none',
      backgroundColor: '#0033ab',
      color: '#ffffff'
    },

    '&:focus': {
      borderRadius: '3px',
      fontWeight: '400',
      textDecoration: 'underline',
      boxShadow: 'none',
      backgroundColor: '#0033ab',
      color: '#ffffff'
    },

    '&:active': {
      color: '#fff',
      backgroundColor: '#0033ab',
      borderColor: '#0033ab',
      borderRadius: '3px',
      fontWeight: '400',
    }

  },
  addPermissionDialog: {
    width: "400px",
  },
  errorText: {
    color: '#eb1616 !important',
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize: '11px',
    lineHeight: '18px',
    marginLeft: '12px'
  },
  permissionName: {
    fontFamily: [
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontWeight: '400',
    fontSize: 'small',
    marginLeft: '10px'
  },
  menuLink: {
    marginLeft: "10px"
  },
  appPermissionText: {
    display: 'flex',
    alignItems: 'center',
    width: '50%',
    margin: '0px 0px 0px 27px',
    "column-gap": "10px"
  },
  appPermissionButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '50%',
    margin: '0px 27px 0px 0px',
    justifyContent: 'flex-end',
  },
  upsertPermissionTitle: {
    backgroundColor: "#F7F7F7",
    padding: "14px 24px 4px"
  }

});

export default permissionStyle;