import React, { useState, useEffect } from 'react';
import {
  TableHeaderStyles, ACTION, DATE, AFFECTGROUP, AFFECTUSER, DETAILS, ADMINISTRATORTEXT
} from '../../../Common/Constants/constants';
import SwapVertIcon from '@material-ui/icons/SwapVert';

function AuditTableHeader(props) {
  const { gridType, sortData, type, isTimeout } = props;
  const [adminTabSort, setAdminTabSort] = useState(true);
  const [sortColumn, setSortColumn] = useState("");

  useEffect(() => {
    sortData && sortData(adminTabSort, sortColumn);
  }, [adminTabSort, sortColumn]);

  const sortGridData = (column) => {
    setSortColumn(column);
    setAdminTabSort(!adminTabSort);
  }

  return (
    <div className="table-head">
      <div className="auditTd">
        <div className="userInfo">{ACTION} </div>   
        <SwapVertIcon className="sort-icon" onClick={() => sortGridData('name')} />     
      </div>
      <div className="auditTd">
        <div className="userInfo">{DATE}</div>
        <SwapVertIcon className="sort-icon" onClick={() => sortGridData('createdDate')}/>  
      </div>
      <div className="auditTd">
        <div className="userInfo">{ADMINISTRATORTEXT}</div>
        <SwapVertIcon className="sort-icon" onClick={() => sortGridData('adminEmail')} />  
      </div>
      <div className="auditTd xs-hide">
        <div className="userInfo">{AFFECTGROUP}</div>
      </div>
      <div className="auditTd">
        <div className="userInfo">{AFFECTUSER}</div>
      </div>
      <div className="auditTd">
      </div>
    </div>
  );
}

export default AuditTableHeader;
