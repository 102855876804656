import { combineReducers } from 'redux';
import localeReducer from './localeReducer';
import globalAdminReducer from './globalAdminReducer';
import filterReducer from './filterReducer';

const rootReducer = combineReducers({
    locale: localeReducer,
    globalAdmin: globalAdminReducer,
    filter: filterReducer
});

export default rootReducer;