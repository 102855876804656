import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { FormControlLabel, Switch, InputAdornment, OutlinedInput } from '@material-ui/core';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as EditClose } from '../../../Assests/Images/EditGroupClose.svg';
import Info from '../../../Assests/Images/Info.svg';
import axiosInstance from '../../Shared/Interceptor/interceptor';
import appConfig from '../../../Environment/environments';
import ToastMessages from '../../Shared/Toast/ToastMessages'
import "./addgroup.scss";
import { injectIntlTranslation } from '../../../Common/Utilities/utility';
import { intlShape, injectIntl } from "react-intl";
import {
  EDITTOOLTIP, TimeoutStyles, RQRGROUP, CHANGE_PARENT_GROUP, SELECT_GROUP, AddGroupStyles, GROUPADD, NEWGROUP, GROUPAPI,
  FIELDREQ, REQUIREDFIELDDISCLAIMERTEXT, GROUPLENGTH, PARENTGROUP, USERALREADYEXISTSERROR, SAVETEXT,
  TIMEOUTLABEL, ON, OFF, DEACTIVATEGROUPMESSAGE, MINVALUE, MAXVALUE, WEEKS, GROUPEXPIRYINFO, NUMBERSREGEX, GET_GROUP_EXPIRY
} from '../../../Common/Constants/constants';
import CustomTextField from '../CustomTextField';

const IOSSwitch = withStyles(theme => TimeoutStyles)(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

class AddGroup extends React.Component {
  state = {
    labelWidth: 0,
    groupName: '',
    parentGroup: '',
    statusMessage: false,
    message: "",
    toastVPosition: 'top',
    toastHPosition: 'left',
    name: '',
    nameError: '',
    open: false,
    selectedIds: [],
    messageVariant: '',
    parentNameError: "",
    isTimeout: false,
    timeoutFreq: 12,
    nextTimeout: '(loading...)'
  }

  handleToast = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      statusMessage: false
    });
  }

  getTranslatedString = (constantName) => {
    return injectIntlTranslation(this.props.intl, constantName)
  }

  onSubmitHandler = (e) => {
    e.preventDefault();
    const obj = {
      parentGroupID: this.props.selectedItem.value,
      label: this.state.groupName,
      isTimeout: this.state.isTimeout
    };
    if (obj.parentGroupID && obj.label) {
      const updatedObj = JSON.stringify(obj);
      axiosInstance.post(appConfig.api.development + GROUPAPI, updatedObj, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then((res) => {
          this.props.setEditGroupId(res.data, true, true);
          this.setState({
            statusMessage: true,
            message: GROUPADD,
            messageVariant: "success"
          });
        }).catch((error) => {
          this.setState({
            statusMessage: true,
            message: USERALREADYEXISTSERROR,
            messageVariant: "error"
          })
        });
    }
    else {
      this.validateName();
      this.validateParentGroup();
    }
  }

  groupNameChangeHandler = groupName => event => {
    const name = event.target.value.trimStart();
    this.setState({ [groupName]: name }, () => {
      this.validateName();
    });
  };

  validateName = () => {
    const { groupName } = this.state;
    this.setState({
      nameError:
        ((groupName == "") ? FIELDREQ : (groupName.length > 100 ? GROUPLENGTH : null))
    });
  }

  validateParentGroup = () => {
    this.setState({
      parentNameError: ((this.props.selectedItem.value) ? "" : RQRGROUP)
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedItem.value !== this.props.selectedItem.value) {
      this.validateParentGroup();
    }
  }

  handleTimeout = (event) => {
    if (event.target.checked) {
      axiosInstance.get(appConfig.api.development + GET_GROUP_EXPIRY, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      }).then(res => {
        this.setState({
          nextTimeout: new Date(res.data).toLocaleDateString(navigator.language)
        });
      }).catch((error) => {
        this.setState({
          nextTimeout: ''
        });
      });

      this.setState({ isTimeout: true, timeoutFreq: 12 });
    }
    else
      this.setState({ isTimeout: false, timeoutFreq: 0 });
  }

  setGroupTimeOut = (event) => {
    if ((NUMBERSREGEX.test(event.target.value))) {
      this.setState({ timeoutFreq: event.target.value });
    }
  }

  render() {
    const { classes, intl } = this.props;
    return (
      <div className={classes.root}>
        <div>
          <Paper square={true} classes={{ root: classes.paperSize, elevation1: classes.editGroupPaper }}>
            <div className={classes.editTitle}>
              <p className={classes.editGroupTitle}>{NEWGROUP}
                <span className={classes.closeIcon} onClick={this.props.onClose}>
                  <EditClose></EditClose>
                </span>
              </p>
            </div>
            <div className={classes.editContent}>
              <Typography classes={{ root: classes.disclaimer }}>
                {REQUIREDFIELDDISCLAIMERTEXT}
              </Typography>
              <form className={classes.container} onSubmit={this.onSubmitHandler} noValidate>
                <span className={classes.requiredAsterik}>*</span>
                <span className={classes.groupName}>
                  <CustomTextField classes={classes} name={this.state.groupName} nameError={this.state.nameError} nameChangeHandler={this.groupNameChangeHandler('groupName')} validateName={this.validateName} />
                  <div className={classes.errorText}>{this.state.nameError}</div>
                </span>
                <span className="parentGroupSelect">
                  <div className="form-group">
                    <label className="label-group">* <span className="parentGroupName">{PARENTGROUP}</span></label>
                    <div className="addGroupParent">
                      <label className="label-Select">{this.props.selectedItem.label}</label>
                      <div>
                        <input type="button" value={injectIntlTranslation(intl, this.props.selectedItem.label ? CHANGE_PARENT_GROUP : SELECT_GROUP)} className="changeButton" onClick={() => this.props.openParallelDrawer && this.props.openParallelDrawer()}></input>
                      </div>
                    </div>
                    <div className={classes.errorText}>{this.state.parentNameError}</div>
                  </div>
                </span>
                <div className="timeoutGroup">
                  <span>
                    <span data-tip={this.getTranslatedString(GROUPEXPIRYINFO)} className="HeaderIconColumn_edit">
                      <img className="GroupsTableInfoIcon" src={Info} xs={2} />
                    </span>
                    <ReactTooltip place="top" type="dark" effect="float" multiline="true" />
                  </span>
                  <span>
                    <span>{TIMEOUTLABEL} : </span>
                    <span className="groupTimeout">
                      <FormControlLabel
                        control={
                          <IOSSwitch
                            checked={this.state.isTimeout} onChange={this.handleTimeout} name="isTimeout" value={this.state.isTimeout}
                          />
                        }
                        label={this.state.isTimeout ? ON : OFF}
                      />
                    </span>
                  </span>
                </div>
                {(this.state.isTimeout === true) &&
                  <span className="timeoutGroupDate">
                    {DEACTIVATEGROUPMESSAGE} <b> {this.state.nextTimeout} </b>
                  </span>
                }
                <div className={classes.saveButton}>
                  <Button type="submit" variant="contained" disableRipple className={classNames(classes.margin, classes.updatebutton)}>{SAVETEXT}</Button>
                </div>
              </form>
            </div>
          </Paper>
          <ToastMessages statusMessage={this.state.statusMessage} message={this.state.message} variant={this.state.messageVariant} toastHPosition={this.state.toastHPosition} toastVPosition={this.state.toastVPosition} close={this.handleToast.bind(this)} />
        </div>
      </div>
    )
  }
}

AddGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired
};

export default injectIntl(withRouter(withStyles(AddGroupStyles)(AddGroup)));