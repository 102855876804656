import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  ManageGroupStyles, INSIGHTS, LOGS, UNABLE_TO_ADD_USER, HVAC_INVALID_MESSAGE, HVAC_INACTIVE_MESSAGE,
  ADDADMIN, ADDUSER, ADMINSTRATORS, SEND_HVAC_INVITATION_GROUP, SENDINVITATIONGROUP, USERSTEXT,
  SPECIFYONLYONEFIELDERROR, USERADDEDTOGROUP, USERALREADYEXISTERROR, INVALID_DOMAIN_ERROR,ERRORMSG
} from '../../../Common/Constants/constants';
import AdminUsersGrid from '../../Shared/AdminUsersGrid/AdminUsersGrid';
import AuditGrid from '../../Shared/Audit/AuditGrid';
import UserInvite from '../../Shared/userInvite/userInvite';
import Insights from '../../Shared/Insights/Insights';
import CustomTabs from '../../Shared/CustomTabs/CustomTabs';
import '../../../Assests/Styles/Styles.scss';
import axiosInstance from '../../Shared/Interceptor/interceptor';
import appConfig from '../../../Environment/environments';
import ToastMessages from '../../Shared/Toast/ToastMessages';
import './manageUsers.scss';
import { injectIntlTranslation, getSortedData } from '../../../Common/Utilities/utility';
import { intlShape, injectIntl } from "react-intl";
import { getAllUserAdminData, getAllAdminData } from "./manageUsersHelper";
import TabContainer from "../../Shared/TabContainer";

class ManageUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      label: ADDADMIN,
      tabLabels: [ADMINSTRATORS, USERSTEXT, INSIGHTS, LOGS],
      //tabLabels: [ADMINSTRATORS, USERSTEXT, INSIGHTS],
      right: false,
      adminData: [],
      initialRender: false,
      userAdminData: [],
      userInitialRender: false,
      statusMessage: false,
      message: "",
      toastVPosition: 'top',
      toastHPosition: 'center',
      messageVariant: '',
      maxAdminCount: false,
      maxCount: 0
    };
  }

  componentDidMount() {
    getAllAdminData(this.props.Id, this.getAllAdminDataSuccess);
    getAllUserAdminData(this.props.Id, this.getAllUserAdminDataSuccess);
  }

  reloadAllUsers = (id) => {
    getAllAdminData(id, this.getAllAdminDataSuccess);
    getAllUserAdminData(id, this.getAllUserAdminDataSuccess);
  }

  getAllAdminDataSuccess = ({ data }) => {
    const maxCount = Number(data.maxAdminCount);
    if (data.admins.length >= maxCount) {
      this.setState({
        maxAdminCount: true
      })
    }
    else {
      this.setState({
        maxAdminCount: false
      })
    }
    this.setState({
      maxCount: maxCount,
      adminData: getSortedData(data.admins),
      initialRender: true
    })
  }

  onDeleteAdmin = (adminCount) => {
    if (adminCount >= this.maxCount) {
      this.setState({
        maxAdminCount: true
      })
    }
    else {
      this.setState({
        maxAdminCount: false
      })
    }
  }

  getAllUserAdminDataSuccess = ({ data }) =>
    this.setState({
      userAdminData: getSortedData(data),
      userInitialRender: true
    })

  onInviteButtonHandler(updatedObj,isBothUserTypesSelected = false, isHvac = false) {
    if (isBothUserTypesSelected) {
      this.setState({
        statusMessage: true,
        message: SPECIFYONLYONEFIELDERROR,
        messageVariant: "error"
      })
    } else {
      const url = isHvac ? SEND_HVAC_INVITATION_GROUP : SENDINVITATIONGROUP;
      axiosInstance.post(appConfig.api.development + url, updatedObj, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      })
      .then(res => {
        if (res) {
          this.setState({
            statusMessage: true,
            message: USERADDEDTOGROUP,
            messageVariant: "success",
            right: false
          })
        }
        if (this.state.label === ADDADMIN) {
          getAllAdminData(this.props.Id, this.getAllAdminDataSuccess);
        } else {
          getAllUserAdminData(this.props.Id, this.getAllUserAdminDataSuccess);
        }
        this.openInviteUserDrawer('right', this.state.right);
        this.props.onContentChange();
      })
      .catch(error => {
        if (error.response) {
          let errorMessage = ERRORMSG;
          if (error.response.data.message.indexOf("is inactive.") > -1) {
            errorMessage = (injectIntlTranslation(this.props.intl, HVAC_INACTIVE_MESSAGE)).replace('_HVACUSER_', "\"" + updatedObj.hvacUserName + "\"");
          } else if (error.response.data.message.indexOf("is invalid.") > -1) {
            errorMessage = (injectIntlTranslation(this.props.intl, HVAC_INVALID_MESSAGE)).replace('_HVACUSER_', "\"" + updatedObj.hvacUserName + "\"");
          } else if (error.response.data.message.indexOf("Invalid domain") > -1) {
            errorMessage = INVALID_DOMAIN_ERROR
          } else if (error.response.data.message.indexOf("exist") > -1) {
            errorMessage = USERALREADYEXISTERROR
          }
          this.setState({
            statusMessage: true,
            message: errorMessage,
            messageVariant: "error"
          })
        }
        else {
          this.setState({
            statusMessage: true,
            message: UNABLE_TO_ADD_USER,
            messageVariant: "error"
          })
        }
      })
    }
  }

  handleChange = (event, value) =>
    this.setState({
      value,
      label: (value === 0 ? ADDADMIN : ADDUSER)
    });

  handleToast(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      statusMessage: false
    });
  }

  openInviteUserDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    this.setState({ [side]: open, statusMessage: false });
  };

  setStatusMessage = (value) => {
    this.setState({ statusMessage: value });
  }

  parentEditGroup = (value) =>{
    this.props.setParentEditGroup(value)
  }

  showTabContent = (selectedTab) => {
    const { Id, groupName } = this.props;
    const tabProps = {
      onContentChange: this.props.onContentChange,
      groupId: Id,
      initialRender: this.state.userInitialRender,
      type: "group",
      isTimeout: this.props.isTimeout,
      nextTimeout: this.props.nextTimeout,
      onDeleteAdmin: this.onDeleteAdmin
    };

    switch (selectedTab) {
      case 0:
        return <TabContainer>
          <AdminUsersGrid buttonDisabled={this.props.isDisable} reloadAllUsers={this.reloadAllUsers} maxAdminCount={this.state.maxCount} adminData={this.state.adminData} gridType={0} {...tabProps} />
        </TabContainer>
        break;
      case 1:
        return <TabContainer>
          <AdminUsersGrid buttonDisabled={this.props.isDisable} reloadAllUsers={this.reloadAllUsers} maxAdminCount={this.state.maxCount} adminCount={this.state.adminData.length} adminData={this.state.userAdminData} gridType={1} {...tabProps} />
        </TabContainer>
        break;
      case 2:
        return <Insights groupName={groupName} groupId={Id} />;
        break;
      case 3:
        return <TabContainer>
          <AuditGrid reloadAllUsers={this.reloadAllUsers} maxAdminCount={this.state.maxCount} adminData={this.state.adminData} gridType={0} parentEditGroup={this.parentEditGroup} {...tabProps} />
        </TabContainer>
        break;
      default:
        return null;
        break;
    }
  }

  render() {
    const { root } = this.props.classes;
    const { value, tabLabels, label, right, maxAdminCount, initialRender, maxCount } = this.state;
    return (
      <div className={root}>
        {initialRender && <CustomTabs
          showButton={((maxAdminCount == true && label === ADDADMIN) || value == 3) ? false : (value !== 2) ? true : false}
          buttonLabel={label}
          onButtonClick={this.openInviteUserDrawer('right', true)}
          handleChange={this.handleChange}
          tabLabels={tabLabels}
          defaultSelected={value}
          maxAdminCount={maxAdminCount}
          maxCount={maxCount}
          buttonDisabled={this.props.isDisable}>
          {this.showTabContent(value)}
        </CustomTabs>}
        <UserInvite
          eventHandle={this.onInviteButtonHandler.bind(this)}
          historyid={this.props.historyid}
          open={this.state.right}
          onClose={this.openInviteUserDrawer('right', false)}
          ID={this.props.Id}
          loggedInUserType={this.props.loggedInUserType}
          isManager={value === 0 ? true : false}
          statusMessage={this.state.statusMessage}
          message={this.state.message}
          messageVariant={this.state.messageVariant}
          setStatusMessage={this.setStatusMessage}
          loggedInUserName={this.props.loggedInUserName}
          groupName={this.props.groupName}
        />
        {!right && <ToastMessages
          statusMessage={this.state.statusMessage}
          message={this.state.message}
          variant={this.state.messageVariant}
          toastHPosition={this.state.toastHPosition}
          toastVPosition={this.state.toastVPosition}
          close={this.handleToast.bind(this)}
        />}
      </div>
    );
  }
}

ManageUsers.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired
};

export default injectIntl(withStyles(ManageGroupStyles)(ManageUsers));