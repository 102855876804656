import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { groupRootContainerStyles} from '../../Common/Constants/constants';
import AppDetails from './AppAddEdit/AppDetails';
import EditAppTabs from  './EditAppTabs/EditAppTabs';
const styles = groupRootContainerStyles;

class EditApp extends React.Component {

  constructor(props) {
    super(props);
    this.setAppName = this.setAppName.bind(this);
  }

  state = {
    appId: ((this.props.location && this.props.location.id) ? this.props.location.id : ''),
    appName: '',
    apiKey:null
  }

  setAppName(appDetails) {
    this.setState({
      appName: appDetails.name,
      appUrl : appDetails.url,
      appClientId:appDetails.appId,
      apiKey:appDetails.apiKey
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.appEditRoot}>
        <div>
          <Grid container spacing={3} classes={{ container: classes.gridContainer }}>
            <Grid item xs={12} sm={12} md={3} classes={{ item: classes.appItem }}>
                <AppDetails appId={this.state.appId} type="edit" setAppName={this.setAppName}/>
            </Grid>
            <Grid item xs={12} sm={12} md={9} classes={{ item: classes.appEditTabItem }}>
              <Paper square={true} classes={{ root: classes.usersPaper, elevation2: classes.editGroupPaper }}>
               <EditAppTabs apiKey={this.state.apiKey} appId={this.state.appId} appName={this.state.appName} appUrl={this.state.appUrl} appClientId={this.state.appClientId} /> 
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
}

EditApp.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EditApp);