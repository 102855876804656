import React from 'react';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Menu, MenuItem, Chip } from '@material-ui/core';
import { ReactComponent as InternalUser } from '../../../Assests/Images/Logo.svg';
import { ReactComponent as HVACUser } from '../../../Assests/Images/HvacPartner.svg';
import { ReactComponent as ExternalUser } from '../../../Assests/Images/User.svg';
import { ReactComponent as Delete } from '../../../Assests/Images/Delete.svg';
import { ReactComponent as Mask } from '../../../Assests/Images/Mask.svg';
import {
  TableBodyStyles, INVITATIONPENDING, DELETETOOLTIP, DELETEUSER, MOVEUSER, SETADMIN, SETUSER, ON, OFF, MAXREACHED,
  INTERNALUSER, EXTERNALUSER, HVACUSER, DEACTIVATEDMESSAGE, ACCOUNTDEACTIVATION, DAYS, EXPIRYON, GROUPTIMEOUTDEACTIVATEDMESSAGE
} from '../../../Common/Constants/constants';
import { intlShape, injectIntl } from "react-intl";
import { injectIntlTranslation } from "../../../Common/Utilities/utility";

const CustomColorCheckbox = withStyles({
  root: {
    color: "#A6A6A6",
    "&$checked": {
      color: "#EB1616"
    }
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />);

function AuditTableBody(props) {
  const intl = props.intl;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
     <div className={`table-body userEmail ${props.status === 3 ? " table-body-gray" : " table-body-white"}`} >
      <div className="auditTd action">
        <div className="userInfo">{props.name}</div>
      </div>
      <div className='auditTd createdDate'>
        <div className="userInfo">
          {props.createdDate}
        </div>
      </div>
      <div className="auditTd xs-hide admin">
        <div className="userInfo">
          {/* <div className="userEmail">{props.createdBy}</div> */}
          <div className="userInfo">
          {/* {props.adminUserType == 0 ? <InternalUser title={injectIntlTranslation(intl, INTERNALUSER)} /> :
              props.adminUserType == 1 ? <ExternalUser title={injectIntlTranslation(intl, EXTERNALUSER)} /> :
                <HVACUser title={injectIntlTranslation(intl, HVACUSER)} />} */}
            {props.adminName != null || props.adminName !== "" ?
                  <span className="userName">{props.adminName}</span>: <></>}
            <div className="userEmail">{props.adminEmail}</div>
          </div>
        </div>
      </div>
      <div className="auditTd">
        <div className="userInfo">{props.affectedEntityDesc}</div>
      </div>
      <div className="auditTd xs-hide">
        <div className="userInfo">
        {/* {props.userType == 0 ? <InternalUser title={injectIntlTranslation(intl, INTERNALUSER)} /> :
            props.userType == 1 ? <ExternalUser title={injectIntlTranslation(intl, EXTERNALUSER)} /> :
              <HVACUser title={injectIntlTranslation(intl, HVACUSER)} />} */}
          {props.username != null || props.username !== "" ?
                <span className="userName">{props.username}</span>: <></>}
          <div className="userEmail">{props.email}</div>
        </div>
      </div>
      <div className="auditTd">
        <a href="#" onClick={() => props.onClickLogDetails(props)}>
          <Mask/> 
        </a>
      </div>
    </div >
  );
}


AuditTableBody.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(AuditTableBody);