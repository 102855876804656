import React from 'react';
import TabPanel from "./TabPanel";
import ReactTab from "./ReactTab";
import AngularTab from "./AngularTab";
import NodeTab from "./NodeTab";
import NugetTab from "./NugetTab";
import TabBuilder from "../../../Shared/TabBuilder/TabBuilder";
import useDeveloperTabStyles from './DeveloperTabStyles';
import APIKEYTAB from './ApiKeyTab'

export default function DeveloperTab(props) {
  const [value, setValue] = React.useState(0);
  const { developerDesc } = useDeveloperTabStyles();
  const { appUrl,Id,apiKey } = props;
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <div className={developerDesc}>auth-module is a javascript package which uses msal library for providing user authentication. It can be integrated with any UI application to provide user authentication. If user doesn't have access to the application then a error message stating that there are no permissions assigned will be displayed after login. If there are any API errors during authentication then a error message will be displayed stating that something went wrong.</div>
      <TabBuilder
        tabs={["REACT", "ANGULAR", "NODE", "NUGET","APIKEY"]}
        selectedTab={value}
        handleTabChange={handleTabChange}
      />
      <TabPanel value={value} index={0} indentation={3}>
        <ReactTab appUrl={appUrl} />
      </TabPanel>
      <TabPanel value={value} index={1} indentation={3}>
        <AngularTab appUrl={appUrl} />
      </TabPanel>
      <TabPanel value={value} index={2} indentation={3}>
        <NodeTab />
      </TabPanel>
      <TabPanel value={value} index={3} indentation={3}>
        <NugetTab />
      </TabPanel>
      <TabPanel value={value} index={4} indentation={4}>
        <APIKEYTAB Id={Id} apiKey={apiKey}/>
      </TabPanel>
    </div>
  );
}


