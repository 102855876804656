import React, { memo } from "react";
import { withStyles } from "@material-ui/core/styles";
import { CustomCheckbox } from "../Shared/CustomCheckBox";
import Highlight from 'react-highlighter';
import { ReactComponent as InternalUser } from "../../Assests/Images/Logo.svg";
import { ReactComponent as HVACUser } from "../../Assests/Images/HvacPartner.svg";
import { ReactComponent as ExternalUser } from "../../Assests/Images/User.svg";
import { FilterStyles } from "../../Common/Constants/constants";
const styles = FilterStyles;

const FilterUserList = (props) => {
  const { isSelected, option, searchText, searchPressed } = props;
  const displayUser = (highlight = false) => {
    const { userListStyle, userLogo, userDetails } = props.classes;
    return (
      <div className={userListStyle}>
        <CustomCheckbox
          checked={isSelected}
          color="default"
        />
        {/* <div className={userLogo}>
          {option.userType === 0 ? (
            <InternalUser />
          ) : option.userType === 1 ? (
            <ExternalUser />
          ) : (
            <HVACUser />
          )}
        </div> */}
        <div className={userDetails}>

          {highlight
            ? <div><Highlight search={searchText} matchClass={"groupHighlight"}>{option.email}</Highlight></div>
            : <div>{option.email}</div>
          }
          {highlight
            ? <div><Highlight search={searchText} matchClass={"groupHighlight"}>{option.name !== " " ? option.name : "-"}</Highlight></div>
            : <div>{option.name !== " " ? option.name : "-"}</div>
          }
        </div>
      </div>
    );
  };

  return searchPressed
    ? (option.email.toLowerCase().includes(searchText.toLowerCase()) ||
      option.name.toLowerCase().includes(searchText.toLowerCase())) &&
    displayUser(true)
    : displayUser(false);
}

export default withStyles(styles)(memo(FilterUserList));