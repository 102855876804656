import React, { memo } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { ReactComponent as EditClose } from "../../Assests/Images/EditGroupClose.svg";
import {
  SAVETEXT,
  USERNAMES,
  ALLUSERS,
  ONLYADMINS,
  FilterStyles,
} from "../../Common/Constants/constants";
import TabBuilder from "../Shared/TabBuilder/TabBuilder";
import UsersAutoComplete from "../Shared/UsersAutoComplete/usersAutoComplete";

const styles = FilterStyles;

class UsersList extends React.Component {
  state = {
    value: 0,
    selectedUsersList: this.props.selectedUsersList
  };

  handleTabChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  onSubmitHandler = (e) => {
    e.preventDefault();
    this.props.onSaveUsers(this.state.selectedUsersList);
  };

  saveSelectedUsers = (users) => this.setState({ selectedUsersList: users });

  render() {
    const { root, margin, updatebutton, paperSize, saveButton, editGroupPaper, editTitle, editGroupTitle, closeIcon } = this.props.classes;
    return (
      <div className={root}>
        <div>
          <Paper
            square={true}
            classes={{ root: paperSize, elevation1: editGroupPaper }}
          >
            <div className={editTitle}>
              <p className={editGroupTitle}>
                {USERNAMES}
                <span className={closeIcon} onClick={this.props.onClose}>
                  <EditClose></EditClose>
                </span>
              </p>
            </div>

            <div>
              <TabBuilder
                tabs={[ALLUSERS, ONLYADMINS]}
                selectedTab={this.state.value}
                handleTabChange={this.handleTabChange}
              />
              <UsersAutoComplete
                selectedTab={this.state.value}
                searchText={this.state.searchText}
                searchPressed={this.state.searchPressed}
                setSelectedUsers={this.setSelectedUsers}
                selectedUsersList={this.state.selectedUsersList}
                saveSelectedUsers={this.saveSelectedUsers}
              />
              <div className={saveButton}>
                <Button
                  onClick={this.onSubmitHandler}
                  variant="contained"
                  disableRipple
                  className={classNames(margin, updatebutton)}
                >
                  {SAVETEXT}
                </Button>
              </div>
            </div>
          </Paper>
        </div>
      </div>
    );
  }
}

UsersList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter((withStyles(styles)(memo(UsersList))));