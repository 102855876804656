import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';

const useStyles = makeStyles({
  root: {
    padding: "9px 6px !important",
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 3px #c1c0c0, inset 0 -1px 0 #c1c0c0',
    backgroundColor: 'transparent',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    boxShadow: "inset 0 0 0 3px #494646, inset 0 -1px 0 #494646",
    backgroundColor: "#494646",
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: 'grey',
    },
  },
});

export const CustomRadio = (props) => {
  const classes = useStyles();
  const {root, icon, checkedIcon} = classes;
  const {checked=false, onChange=()=>{}} = props;
  return (
    <Radio
      className={root}
      disableRipple
      color="default"
      checked={checked}
      onChange={onChange}
      checkedIcon={<span className={clsx(icon, checkedIcon)} />}
      icon={<span className={icon} />}
      {...props}
    />
  );
}