import { translation } from "../Utilities/utility";

export const COMPANY_DOMAIN = /@carrier.com/i;//Company Domain
export const GET_CARCGL_INTERNAL_USER_NAME =
  "/api/User/GetInternalUsersByName?name=";// API End point to Get compnay Internal user 
export const APPURL = "api/app/all";
export const GETGROUPAPPDETAILS =
  "/api/GroupApps/GetGroupAppDetails?isNew=true";
export const GROUPADD = translation("GROUPADD");
export const PARENTGROUPCHANGE = translation("PARENTGROUPCHANGE");
export const USERMOVEDSUCCESS = translation("USERMOVEDSUCCESS");
export const USERALREADYEXISTERROR = translation("USERALREADYEXISTERROR");
export const NEXTTIMEOUTLABEL = translation("NEXTTIMEOUTLABEL");
export const USERTIMEOUTERROR = translation("USERTIMEOUTERROR");
export const GROUPDEL = "GROUPDEL";
export const CHILDSUBGROUP = "/api/group/childsubgroups";
export const GROUPNAMES = "/api/users/GetGroups";
export const GROUPAPI = "/api/group";
export const PARENTGROUPCHANGEAPI = "/api/group/UpdateParentGroup";
export const MOVEUSERAPI = "/api/groupUser/MoveUserToGroup";
export const GET_ALL_DOMAINS = "/api/Domain/GetAllDomains";
export const SAVE_DOMAIN = "/api/Domain/SaveDomain";
export const APPDELETE = "/api/app/Id?AppId=";
export const GET_ADMIN_CONFIGURATION = "/api/utility/GetAdminConfiguration";
export const CHANGE_ADMIN_CONFIGURATION =
  "/api/utility/ChangeAdminConfiguration";
export const GET_GROUP_EXPIRY = "/api/utility/GetGroupAccountExpiry";
export const APPUSERSTATUS = "/api/users/RefreshUserDeactivatedTime?userId=";
export const INACTIVE_DURATION = "InactivityDuration";
export const INACTIVEERRORMESSAGE = translation("INACTIVEERRORMESSAGE");
export const INACTIVITYDURATION = translation("INACTIVITYDURATION");
export const INACTIVITYDURATIONPARA1 = translation("INACTIVITYDURATIONPARA1");
export const INACTIVITYDURATIONPARA2 = translation("INACTIVITYDURATIONPARA2");
export const REMAINDERFREQUENCY = translation("REMAINDERFREQUENCY");
export const REMAINDERFREQUENCYPARA1 = translation("REMAINDERFREQUENCYPARA1");
export const WEEKS = translation("WEEKS");
export const GROUPTIMEOUTFREQ = translation("GROUPTIMEOUTFREQ");
export const GROUPTIMEOUTFREQSUB = translation("GROUPTIMEOUTFREQSUB");
export const MINVALUE = 1;
export const MAXVALUE = 100;
export const API_ACTIVITY_ERROR = translation("APIACTIVITYERROR");
export const API_ACTIVITY_SUCCESS = translation("APIACTIVITYSUCCESS");
export const FIELDREQ = translation("FIELDREQ");
export const RQRGROUP = translation("RQRGROUP");
export const FILTERERROR = translation("FILTERERROR");
export const GROUPLENGTH = translation("GROUPLENGTH");
export const GROUPLENGTH50 = translation("GROUPLENGTH50");
export const DEVURLDISCLAIMER = translation("DEVURLDISCLAIMER");
export const CLOSE = translation("CLOSE");
export const APITREE = translation("APITREE");
export const ENTER = "Enter";
export const SEARCHERROR = translation("SEARCH_ERROR");
export const COPIED = "Copied";
export const COPYFAILED = "Failed to Copy";
export const DEACTIVATEDMESSAGE = translation("DEACTIVATEDMESSAGE");
export const GROUPTIMEOUTDEACTIVATEDMESSAGE = translation(
  "GROUPTIMEOUTDEACTIVATEDMESSAGE"
);
export const ACCOUNTDEACTIVATION = translation("ACCOUNTDEACTIVATION");
export const DAYS = translation("days");
export const REACTWRAPPERINSTALL = `npm install --save "@CCR/reactauthwrapper@authVersion"`;
export const AUTHMODULEINSTALL = `npm install --save "@CCR/auth-module@authVersion"`;
export const NODEMIDDLEWAREINSTALL = `npm install --save "@Folder Name/auth-node-middleware"`;
export const NPMRCFILEPATH =
  "https://staticfilesappshubdev.coolsystemsselect.com/files/npmrc";
export const REACTAUTHWRAPPER = `import {ReactAuthWrapper} from '@CCR/reactauthwrapper';
ReactDOM.render(
  <ReactAuthWrapper>
    <App />
  </ReactAuthWrapper>,
  document.getElementById('root')
);`;
export const APPCONFIG = `export const appConfig = {
  uiUrl: "appUrl",
  apiUrl: "@apiUrl",
  postLogoutRedirectUri: "http://localhost:3000" //For local only
}`;

export const APPCONFIGTEMPLATE = `export const appConfig = {
  uiUrl: "appUrl",
  appClientId: "@appClientId"
}`;

export const NUGETCORE_AUTH = `"AuthSettings": {
  "AzureIss": "@azureIss",
  "AWSIss": "@awsIss"
}`;

export const NUGETFRAMEWORK_AUTH = `<AppSettings>
	<add Key="AzureIss" Value="@azureIss"/>
	<add Key="AWSIss" Value="@awsIss"/>
</AppSettings>`;

export const NUGETCORE_REGISTER = `services.Configure<TokenValidation.Model.AuthSettings>(Configuration.GetSection("AuthSettings"));
services.AddSingleton<TokenValidation.Filters.AuthenticationFilterAttribute>()`;

export const NUGET_WRAPPER = `using TokenValidation.Filters;
[ServiceFilter(typeof(AuthenticationFilterAttribute))]
public class AppController
{
  public async Task<JsonResult> GetApp()
  {
    // Logic..
  }
}`;

export const NUGETFRAMEWORK_WRAPPER = `using TokenValidation.Filters;
[AuthenticationFilter]
public class AppController
{
  public async Task<JsonResult> GetApp()
  {
    // Logic..
  }
}`;

export const WRAPPER = `import {ReactAuthWrapper} from '@CCR/reactauthwrapper';
import appConfig from './appConfig';
import { appLogo } from './images/appLogo.svg';
import { noPermissionText } from './constants';
ReactDOM.render(
  <ReactAuthWrapper appConfig={appConfig} getPermissions={callback} errorScreenLogo={appLogo} noPermissionText={noPermissionText}>
    <App />
  </ReactAuthWrapper>,
  document.getElementById('root')
);
const callback = (permissions) => {
  console.log('permissions->',permissions);
}`;
export const LOGOUTCODE = `import {logout} from '@CCR/auth-module';
import appConfig from './appConfig' //or valid path to appconfig file
function appLogout() {
      //other cleanup code
      logout(appConfig);
}`;
export const GETPERMISSIONSCODE = `import {GetUserProfileAndPermissions} from '@CCR/auth-module';
function GetUserProfileAndPermissions() {
    GetUserProfileAndPermissions('<<apiUrl>>', '<<applicationUrl>>', successCallback, failureCallback);
}`;
export const GETUSERSBYPERMISSIONCODE = `import {GetUsersBasedOnPermissions} from '@CCR/auth-module';
function GetUsersBasedOnPermissions() {
    GetUsersBasedOnPermissions('<<apiUrl>>', 'reqObj', successCallback, failureCallback);
}`;
export const GETUSERSBYIDCODE = `import {GetUserDetailsById} from '@CCR/auth-module';
function FetchUserDetailsById() {
    GetUserDetailsById('<<apiUrl>>', 'userid', successCallback, failureCallback);
}`;
export const GETGROUPCODE = `import {GetGroupDetailsById} from '@CCR/auth-module';
function FetchGroupDetailsById() {
    GetGroupDetailsById('<<apiUrl>>', 'groupid', successCallback, failureCallback);
}`;
export const ANGULARWRAPPER = `import { Component } from '@angular/core';
import {appConfig} from '../appConfig';
import {login, logout} from '@CCR/auth-module';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
   auth = false;
   permissions = {};
   config = appConfig;
  constructor(){}
  ngOnInit(){
      this.Authentication();
  }
  async Authentication(){
    [this.auth, this.permissions ]= await login(appConfig);
    console.log('permissions',this.permissions);
  }
  logoutApp(appConfig:any){
    logout(appConfig);
  }
}`;
export const ANGULARTEMPLATE = `<div class = "app" *ngIf="auth"> 
  <h1>Welcome to Angular Application</h1>
  <button (click)="logoutApp(config)">Logout</button>
</div>`;
export const NODEDECLARATION = `const withAuth = require('@Folder Name/auth-node-middleware');`;
export const NODEEXAMPLE = `// As it doesn't passes any param, it point Production B2C
app.use(withAuth.ValidateToken());
(or)
// As it passes environment param, it points to respective B2C environment
app.use(withAuth.ValidateToken('DEV'));`;
export const APIKEY=`ApiKey:"@apiKey"`;
export const AUTHTOKEN = "Bearer {{Token}}";
export const GETVERB = "GET";
export const POSTVERB = "POST";
export const DESCRIPTION = translation("DESCRIPTION");
export const GROUPS = "/Groups";
export const USERS = "/Users";
export const ADMINISTRATOR = "/Administrator";
export const DASHBOARD = "/dashboard";
export const INVALIDHVACUSERWARNING = "/invalidHvacError";
export const ADDEDITAPP = "/EditApp";
export const EDITPROFILE = "/profile";
export const DASHBOARDTEXT = translation("DASHBOARD");
export const GROUPTEXT = translation("GROUPS");
export const ADMINISTRATORTEXT = translation("ADMINISTRATORTEXT");
export const WHITELIST = translation("WHITELIST");
export const INVALID_DOMAIN_ERROR = translation("INVALID_DOMAIN_ERROR");
export const MANAGE_DOMAINS = translation("MANAGE_DOMAINS");
export const ADD_DOMAIN = translation("ADD_DOMAIN");
export const ADD_DOMAIN_ERROR = translation("ADD_DOMAIN_ERROR");
export const DOMAIN_ALREADY_ADDED = translation("DOMAIN_ALREADY_ADDED");
export const ADD_DOMAIN_API_SUCCESS = translation("ADD_DOMAIN_API_SUCCESS");
export const ADD_DOMAIN_API_ERROR = translation("ADD_DOMAIN_API_ERROR");
export const MANAGE_DOMAINS_FAIL = translation("MANAGE_DOMAINS_FAIL");
export const REFRESH = translation("REFRESH");
export const FILTER_SELECTED_GROUP = translation("FILTER_SELECTED_GROUP");
export const FILTER_SELECTED_GROUP_CHILD = translation(
  "FILTER_SELECTED_GROUP_CHILD"
);
export const ALL_PERMISSIONS = translation("ALL_PERMISSIONS");
export const FILTER_GROUPS = translation("FILTER_GROUPS");
export const FILTER_PERMISSIONS = translation("FILTER_PERMISSIONS");
export const WHITE_LIST_NOTE = translation("WHITE_LIST_NOTE");
export const BLACKLIST = translation("BLACKLIST");
export const WHITELIST_PARA_1 = translation("WHITELIST_PARA_1");
export const WHITELIST_PARA_2 = translation("WHITELIST_PARA_2");
export const WHITELIST_PARA_3 = translation("WHITELIST_PARA_3");
export const WHITELIST_PARA_4 = translation("WHITELIST_PARA_4");
export const EDITBYID = "/api/group/id?Id=";
export const GROUPSEARCH = "/api/group/Search";
export const USERSLIST = "/api/group/SubGroupsUsers?searchStr=";
export const ADDADMIN = translation("ADDADMIN");
export const GROUPEDIT = translation("GROUPEDIT");
export const ADDUSER = translation("ADDUSER");
export const APPADMINTEXT = translation("APPADMINTEXT");
export const APPGROUPTEXT = translation("APPGROUPTEXT");
export const GROUPBYID = "/api/groupUser/GetAllSubGroups?parentGroupId=";
export const GROUPALL = "/api/groupUser/GetAllGroups";
export const ISEXIST = "/api/users/exists?useremail=";
export const LASTSIGNEDIN = "/api/users/UpdateUserLastSignedIn";
export const CREATEUSER = "/api/users/create";
export const ROOTSETTINGS = "/api/GroupAppSettings?id=";
export const SAVESETTINGS = "/api/GroupAppSettings";
export const GROUPAPPSETTINGSDETAILS =
  "/api/GroupAppSettings/GroupAppSettingDetails";
export const GROUPAPPCHILDSETTINGSDETAILS =
  "/api/GroupAppSettings/GroupAppChildSettingDetails";
export const GETAPPSETTINGSCHILDREN =
  "/api/GroupAppSettings/GroupAppSettingsChilds";
export const CALLBACK = "/authcallback";
export const GROUPUSER = "/api/groupUser/groupusers?groupId=";
export const DELETEGROUP = translation("DELETEGROUP");
export const CHANGEPARENTGROUP = "CHANGEPARENTGROUP";
export const CHANGE_PARENT_GROUP = "CHANGE_PARENT_GROUP";
export const SELECT_GROUP = "SELECT_GROUP";
export const DELETEGRPDIALOG = translation("DELETEGRPDIALOG");
export const APPSGROUPSNOACCESSTEXT = translation("APPSGROUPSNOACCESSTEXT");
export const CONTACTADMINTEXT = translation("CONTACTADMINTEXT");
export const USERDETAIL = "/api/users/GetUserDetails?useremail=";
export const NOADMINDATA = translation("NOADMINDATA");
export const ADMINDATA = "/api/groupUser/groupadmins?groupId=";
export const APPADMINDATA = "/api/AppUsers/AppAdmins?appId=";
export const GETAPPSGROUPDATA = "/api/GroupApps/childgroups?appId=";
export const GROUPUSERAPI = "/api/groupUser?GroupUserId=";
export const GROUPSWAPUSERAPI = "/api/GroupUser/Swap?groupUserId=";
export const APPUSERAPI = "/api/AppUsers?appUserId=";
export const USERACTIVE = "/api/groupUser/ActivateDeactivate?groupUserId=";
export const APPUSERACTIVE = "/api/AppUsers/ActivateDeactivate?appUserId=";
export const DELETEADMIN = translation("DELETEADMIN");
export const LOADINGTEXT = translation("LOADINGTEXT");
export const DELETETEXT = translation("DELETETEXT");
export const UPDATETEXT = translation("UPDATETEXT");
export const DELETETOOLTIP = "DELETETEXT";
export const CONTINUETEXT = translation("CONTINUETEXT");
export const CANCELTEXT = translation("CANCELTEXT");
export const DELETEUSER = translation("DELETEUSER");
export const MOVEUSER = translation("MOVEUSER");
export const SETADMIN = translation("SETADMIN");
export const SETUSER = translation("SETUSER");
export const ERRORMSG = translation("ERRORMSG");
export const USERSWAPSUCCESS = translation("USERSWAPSUCCESS");
export const USERUPDATESUCCESS = translation("USERUPDATESUCCESS");
export const USERUPDATENOTE = translation("USERUPDATENOTE");
export const MAXREACHED = translation("MAXREACHED");
export const NOUSERTEXT = translation("NOUSERTEXT");
export const SAVETEXT = translation("SAVETEXT");
export const ADDPERMISSIONSTEXT = translation("ADDPERMISSIONSTEXT");
export const UPDATEPERMISSIONSTEXT = translation("UPDATEPERMISSIONSTEXT");
export const CREATEPERMISSION = "/api/setting";
export const DELETEROOTPERMISSION = "/api/AppSettings/DeleteAppSettings?appId=";
export const DELETECHILDPERMISSION = "/api/Setting?SettingId=";
export const FETCHPERMISSIONS = "/api/AppSettings/GetAppPermissions?appId=";
export const ADDCHILDPERMISSIONS = translation("ADDCHILDPERMISSIONS");
export const PERMISSIONSSAVEFAIL = translation("PERMISSIONSSAVEFAIL");
export const PERMISSIONSUNIQUEFAIL = translation("PERMISSIONSUNIQUEFAIL");
export const PERMISSIONSREMOVEFAIL = translation("PERMISSIONSREMOVEFAIL");
export const PERMISSIONSSAVESUCCESS = translation("PERMISSIONSSAVESUCCESS");
export const PERMISSIONSREMOVESUCCESS = translation("PERMISSIONSREMOVESUCCESS");
export const PERMISSIONSFETCHFAIL = translation("PERMISSIONSFETCHFAIL");
export const NOPERMISSIONSTEXT = translation("NOPERMISSIONSTEXT");
export const CONFIRMATIONTEXT = translation("CONFIRMATIONTEXT");
export const ROLESTEXT = translation("ROLESTEXT");
export const REQUIREDFIELDDISCLAIMERTEXT = translation(
  "REQUIREDFIELDDISCLAIMERTEXT"
);
export const EDITGROUPTEXT = translation("EDITGROUPTEXT");
export const UNSAVEDCHANGESHEADERTEXT = translation("UNSAVEDCHANGESHEADERTEXT");
export const UNSAVEDCHANGESDESCRIPTION = translation(
  "UNSAVEDCHANGESDESCRIPTION"
);
export const DELETEADMINDIALOG = translation("DELETEADMINDIALOG");
export const MAXADMINCOUNTMESSAGE = translation("MAXADMINCOUNTMESSAGE");
export const DELETEUSERDIALOG = translation("DELETEUSERDIALOG");
export const EXTERNALEMAILREQUIREDERROR = translation(
  "EXTERNALEMAILREQUIREDERROR"
);
export const EXTERNALEMAILINVALIDERROR = translation(
  "EXTERNALEMAILINVALIDERROR"
);
export const EMAILDISCLAIMER = translation("EMAILDISCLAIMER");
export const EXTERNALEMAILPLACEHOLDER = "EXTERNALEMAILPLACEHOLDER";
export const EXTERNALEMAILDOMAINERROR = translation("EXTERNALEMAILDOMAINERROR");
export const GROUPROLES = "/api/GroupRoles/";
export const GETGROUPROLES = "/api/GroupRoles?id=";
export const GROUPROLESERROR = translation("GROUPROLESERROR");
export const GROUPROLESLOADING = translation("GROUPROLESLOADING");
export const GROUPROLESNOROLES = translation("GROUPROLESNOROLES");
export const SETTINGSLABEL = translation("SETTINGSLABEL");
export const LOADINGSETTINGSTEXT = translation("LOADINGSETTINGSTEXT");
export const NOSETTINGSTEXT = translation("NOSETTINGSTEXT");
export const SETTINGSSAVETEXT = translation("SETTINGSSAVETEXT");
export const SETTINGSERRORTEXT = translation("SETTINGSERRORTEXT");
export const USERALREADYEXISTSERROR = translation("USERALREADYEXISTSERROR");
export const CANNOTSELECTCHILDASPARENTGROUPERROR = translation(
  "CANNOTSELECTCHILDASPARENTGROUPERROR"
);
export const SAMEPARENTSELECT = translation("SAMEPARENTSELECT");
export const SAMEGROUPASPARENTSELECT = translation("SAMEGROUPASPARENTSELECT");
export const ADMINGRIDLABELTEXT = translation("ADMINGRIDLABELTEXT");
export const USERSGRIDLABELTEXT = translation("USERSGRIDLABELTEXT");
export const LASTSIGNEDINTEXT = translation("LASTSIGNEDINTEXT");
export const APPSLABELTEXT = translation("APPSLABELTEXT");
export const APPSLOADINGTEXT = translation("APPSLOADINGTEXT");
export const ADDAPP = translation("ADDAPP");
export const DELETEAPPHEADER = translation("DELETEAPPHEADER");
export const DELETEAPPCONTENT = translation("DELETEAPPCONTENT");
export const DELETEPERMISSIONHEADER = translation("DELETEPERMISSIONHEADER");
export const DELETEPERMISSIONCONTENT = translation("DELETEPERMISSIONCONTENT");
export const NEWAPP = translation("NEWAPP");
export const EDITAPP = translation("EDITAPP");
export const APPNAME = translation("APPNAME");
export const APPSGROUPSAVESUCCESS = translation("APPSGROUPSAVESUCCESS");
export const APPSGROUPSAVEFAIL = translation("APPSGROUPSAVEFAIL");
export const IMAGE = translation("IMAGE");
export const UPLOAD = translation("UPLOAD");
export const PREVIEW = translation("PREVIEW");
export const IMGRESERROR = translation("IMGRESERROR");
export const IMGTYPEERROR = translation("IMGTYPEERROR");
export const IMGSIZEERROR = translation("IMGSIZEERROR");
export const APPSAVEERROR = translation("APPSAVEERROR");
export const APPNOCHANGES = translation("APPNOCHANGES");
export const APPCREATESUCCESS = translation("APPCREATESUCCESS");
export const APPUPDATESUCCESS = translation("APPUPDATESUCCESS");
export const APPALREADYEXISTERROR = translation("APPALREADYEXISTERROR");
export const APPURLALREADYEXISTERROR = translation("APPURLALREADYEXISTERROR");
export const APPCREATEFAIL = translation("APPCREATEFAIL");
export const INVALIDURL = translation("INVALIDURL");
export const GETAPPDETAILSFAIL = translation("GETAPPDETAILSFAIL");
export const PARENTAPPLICATION = "Parent Application";
export const SYSTEMTOPLEVEL = "System (top level)";
export const CATEGORYCONTROL = "Category Control";
export const OTHERS = "Others";
export const PARENTAPPLICATIONSELECT =
  "Select a parent app to group diffrent applications";
export const GETSYSTEMAPPS = "/api/app/SystemApps";
export const GETCATEGORY = "/api/app/GetCategory";
export const ADDORUPDATECATEGORY = "/api/app/AddOrUpdateCategory";
export const UPDATEUSERDETAILS = "/api/users/update";
export const CREATECATEGORY = "Create a new category";
export const ADDCATEGORY = "Add a new category";
export const ADD = "Add";
export const CANCEL = "Cancel";
export const PARENTAPPLICATIONVALUE = 0;
export const CREATEAPP = "/api/app";
export const GETAPPDETAILS = "/api/app/id?isNew=true&appId=";
export const IMGUPLOADDISCLAIMER = translation("IMGUPLOADDISCLAIMER");
export const URL = translation("URL");
export const DEVURL = translation("DEVURL");
export const APPPERMISSIONNAME = translation("APPPERMISSIONNAME");
export const REDIRECTURL = translation("REDIRECTURL");
export const APPSNOTFOUNDTEXT = translation("APPSNOTFOUNDTEXT");
export const INVALIDHVACUSERTEXT = translation("INVALIDHVACUSERTEXT");
export const MIGRATIONWARNINGLOGOUTTEXT = translation(
  "MIGRATIONWARNINGLOGOUTTEXT"
);
export const LOGIN = translation("LOGIN");
export const SEARCHSUGGESTIONSERROR = translation("SEARCHSUGGESTIONSERROR");
export const SEARCHSUGGESTIONLABEL = translation("SEARCHSUGGESTIONLABEL");
export const LOGOUT = translation("LOGOUT");
export const DELETEACCOUNT = translation("DELETEACCOUNT");
export const EDITACCOUNT = translation("EDITACCOUNT");
export const UPDATEEMAIL = translation("UPDATEEMAIL");
export const UIDOCUMENTATION = translation("UIDOCUMENTATION");
export const DOCUMENTATION = translation("DOCUMENTATION");
export const FIRSTNAME = translation("FIRSTNAME");
export const LASTNAME = translation("LASTNAME");
export const EMAILADDRESS = translation("EMAILADDRESS");
export const COUNTRY = translation("COUNTRY");
export const STATE = translation("STATE");
export const CITY = translation("CITY");
export const LANGUAGE = translation("LANGUAGE");
export const EDITEMAIL = translation("EDITEMAIL");
export const DELETEACCOUNTDESCRIPTION = translation("DELETEACCOUNTDESCRIPTION");
export const DELETEACCOUNTDISCALIMER = translation("DELETEACCOUNTDISCALIMER");
export const EDITEMAILDISCALIMER = translation("EDITEMAILDISCALIMER");
export const EDITEMAILDESCRIPTION = translation("EDITEMAILDESCRIPTION");
export const CHANGEPASSWORD = translation("CHANGEPASSWORD");
export const EDITPASSWORDDISCALIMER = translation("EDITPASSWORDDISCALIMER");
export const EDITPASSWORDDESCRIPTION = translation("EDITPASSWORDDESCRIPTION");
export const DELETEACCOUNTAPIERROR = translation("DELETEACCOUNTAPIERROR");
export const UPDATEEMAILAPIERROR = translation("UPDATEEMAILAPIERROR");
export const DELETEACCOUNTAPI = "/api/users?userId=";
export const UPDATEEXTERNALEMAILAPI =
  "/api/users/UpdateExternalEmail?useremail=";
export const ACCOUNT = translation("ACCOUNT");
export const ACCOUNTTEXT = "ACCOUNT";
export const APPLYALLHEADERLABEL = translation("APPLYALLHEADERLABEL");
export const APPLYALLDESCRIPTIONLABEL = translation("APPLYALLDESCRIPTIONLABEL");
export const APPLYTEXT = translation("APPLYTEXT");
export const APPLYALLFAIL = translation("APPLYALLFAIL");
export const APPLYALLSUCCESS = translation("APPLYALLSUCCESS");
export const NOGROUPSFOUND = translation("NOGROUPSFOUND");
export const GETGROUPS = "/api/group/childgroupsbygroupid?gId=";
export const SAVEAPPLYALL =
  "/api/GroupAppSettings/ApplyGroupAppAettingsToChilds";
export const SAVEAPPGROUPS = "/api/GroupApps";
export const LOCALE_SET_START = "LOCALE_SET_START";
export const LOCALE_SET_FULFILLED = "LOCALE_SET_FULFILLED";
export const LOCALE_SET_FAILED = "LOCALE_SET_FAILED";
export const SET_GLOBAL_ADMIN = "SET_GLOBAL_ADMIN";
export const SET_PARENT_GROUP_FILTER = "SET_PARENT_GROUP_FILTER";
export const SET_USER_SELECTION = "SET_USER_SELECTION";
export const SET_USER_PERMISSIONS = "SET_USER_PERMISSIONS";
export const GETUSERLANGUAGE = "/api/users/GetUserLanguage";
export const SETUSERLANGUAGE = "/api/users/UserLanguage?languageCode=";
export const SENDINVITATIONGROUP = "/api/groupUser/SendInvitation";
export const SENDINVITATIONAPP = "/api/AppUsers/SendInvitation";
export const NEWGROUP = translation("NEWGROUP");
export const CHANGEGROUP = translation("CHANGEGROUP");
export const MOVEUSERTO = translation("MOVEUSERTO");
export const GROUPNAME = translation("GROUPNAME");
export const PARENTGROUP = translation("PARENTGROUP");
export const USERNAME = translation("USERNAME");
export const PERMISSION = translation("PERMISSION");
export const USERNAMES = translation("USERNAMES");
export const ALLUSERS = translation("ALLUSERS");
export const ONLYADMINS = translation("ONLYADMINS");
export const ADDFILTERBUTTON = "ADDFILTER";
export const FILTERS = "FILTERS";
export const CHANGEFILTER = "CHANGEFILTER";
export const ADDFILTER = translation("ADDFILTER");
export const ASSIGNEDGROUP = translation("ASSIGNEDGROUP");
export const ADMINSTRATORS = translation("ADMINISTRATORS");
export const INSIGHTS = translation("INSIGHTS");
export const LOGS = translation("LOGS");
export const USERS_LABEL = translation("USERS_LABEL");
export const ADMIN_LABEL = translation("ADMIN_LABEL");
export const USERS_LOGIN_LABEL = translation("USERS_LOGIN_LABEL");
export const USERS_LOGIN_TOOLTIP = translation("USERS_LOGIN_TOOLTIP");
export const GROUP_TEXT = translation("GROUP_TEXT");
export const USERS_CHILD_GROUPS = "USERS_CHILD_GROUPS";
export const INCLUDES = "INCLUDES";
export const USERS_PER_GROUP = translation("USERS_PER_GROUP");
export const USERS_ACTIVE_LABEL = translation("USERS_ACTIVE_LABEL");
export const USERS_ACTIVE_MONTH = translation("USERS_ACTIVE_MONTH");
export const USERS_ACTIVE_WEEK = translation("USERS_ACTIVE_WEEK");
export const USERS_ACTIVE_DAY = translation("USERS_ACTIVE_DAY");
export const USERS_ACTIVE_HOUR = translation("USERS_ACTIVE_HOUR");
export const AUTHENTICATED = translation("AUTHENTICATED");
export const NOT_ACTIVE = translation("NOT_ACTIVE");
export const PENDING = translation("PENDING");
export const DEVELOPERTAB = translation("DEVELOPERTAB");
export const TEMPLATETAB = translation("TEMPLATETAB");
export const PERMISSIONSTAB = translation("PERMISSIONSTAB");
export const PERMISSIONSTABTEXT = "PERMISSIONSTAB";
export const TENANT = translation("TENANT");
export const CLIENTID = translation("CLIENTID");
export const CLIENTSCOPE = translation("CLIENTSCOPE");
export const AUTHORITYBASE = translation("AUTHORITYBASE");
export const AUTHORITYURL = translation("AUTHORITYURL");
export const USERSTEXT = translation("USERSTEXT");
export const SPECIFYONLYONEFIELDERROR = translation("SPECIFYONLYONEFIELDERROR");
export const APPUSERALREADYEXISTERROR = translation("APPUSERALREADYEXISTERROR");
export const USERADDEDTOGROUP = translation("USERADDEDTOGROUP");
export const USERADDEDTOAPP = translation("USERADDEDTOAPP");
export const LOADING = translation("LOADING");
export const ADDAGROUP = "ADDAGROUP";
export const GROUPSTEXT = translation("GROUPS");
export const NOGROUPSCREATED = translation("NOGROUPSCREATED");
export const NORECORDFOUND = translation("NORECORDFOUND");
export const NAME = translation("NAME");
export const EDIT = translation("EDIT");
export const INVITATIONPENDING = translation("INVITATIONPENDING");
export const EMAIL = translation("EMAIL");
export const HVAC_EMAIL = translation("HVAC_EMAIL");
export const HVAC_EMAIL_SPECIAL_CHAR_ERROR = translation(
  "HVAC_EMAIL_SPECIAL_CHAR_ERROR"
);
export const UNABLE_TO_ADD_USER = translation("UNABLE_TO_ADD_USER");
export const HVAC_INACTIVE_MESSAGE = "HVAC_INACTIVE_MESSAGE";
export const HVAC_INVALID_MESSAGE = "HVAC_INVALID_MESSAGE";
export const HVAC_EMAIL_PLACEHOLDER = "HVAC_EMAIL_PLACEHOLDER";
export const SEND_HVAC_INVITATION_GROUP = "/api/groupUser/SendHvacInvitation";
export const SEND_HVAC_INVITATION_APP = "/api/AppUsers/SendHvacInvitation";
export const GET_ALL_USER_STATUS = "/api/users/GetAllUserStatus";
export const GET_GROUP_AUDIT = "/api/Audit/GetUsersActionDetails";
export const OR = translation("OR");
export const USERADD = translation("USERADD");
export const INTERNALUSER = "INTERNALUSER";
export const HVACUSER = "HVACUSER";
export const EXTERNALUSER = "EXTERNALUSER";
export const ADMINSTRATORSTOOLTIP = "ADMINISTRATORS";
export const GROUPSTOOLTIP = "GROUPS";
export const USERSTOOLTIP = "USERSTEXT";
export const EDITTOOLTIP = "EDIT";
export const COMPANYNAMEPLACEHOLDER = "COMPANYNAMEPLACEHOLDER";
export const ACTIVE = translation("ACTIVE");
export const ON = translation("ON");
export const OFF = translation("OFF");
export const INVITE = translation("INVITE");
export const SELECTALL = translation("SELECTALL");
export const DESELECTALL = translation("DESELECTALL");
export const GETINTERNALUSERNAME = "/api/User/GetUtcInternalUsersByName?name=";
export const DEFAULTVALUE = "Default";
export const EDITGROUP = "EditGroup";
export const TIMEOUTLABEL = translation("TIMEOUTLABEL");
export const GROUPDISABLE = translation("GROUPDISABLE");
export const GROUPDISABLEINFO = "GROUPDISABLEINFO";
export const EXPIRY = translation("EXPIRY");
export const EXPIRYON = translation("EXPIRYON");
export const DEACTIVATEGROUPMESSAGE = translation("DEACTIVATEGROUPMESSAGE");
export const GROUPDISABLEMSG = translation("GROUPDISABLEMSG");
export const ADDGROUPDISABLEMSG = translation("ADDGROUPDISABLEMSG");
export const PARENTGROUPDISABLEMSG = translation("PARENTGROUPDISABLEMSG");
export const GROUPEXPIRYINFO = "GROUPEXPIRYINFO";
export const TIMEOUTENABLED = "Enabled";
export const TIMEOUTDISABLED = "Disabled";
export const ACTION = translation("ACTION");
export const DATE = translation("DATE");
export const AFFECTGROUP = translation("AFFECTGROUP");
export const AFFECTUSER = translation("AFFECTUSER");
export const DETAILS = translation("DETAILS");
export const NUMBERSREGEX = /^[1-9][0-9]?$|^100$/;
export const GET_AUDIT_ACTION_DETAILS = "/api/Audit/GetActionDetails?actionId=";
export const ACTIONDETAILS = translation("ACTIONDETAILS");
export const ADMINISTRATORACTION = translation("ADMINISTRATORACTION");
export const IMPACTEDUSER = translation("IMPACTEDUSER");
export const UPDATEPASSWORDAPI="/api/users/UpdatePassword"
export const CREATEDYNAMICSCREENAPI="/api/app/Template";
export const GET_CUSTOMEMAIL_TEMPLATE='/api/groupUser/Template?groupId=';
export const CREATE_UPDATE__NEW_TEMPLATE='/api/groupUser/Template';
export const DELETE_TEMPLATE='/api/groupUser/Template?templateId=';
export const CREATEAPIKEY='/api/app/CreateApiKey?AppId=';
export const CHARTTEXT = {
  noUsers: "noUsers",
  autheticated: "autheticated",
  notActive: "notActive",
  pending: "pending",
  admins: "admins",
  users: "users",
  groupName: "groupName",
  lastMonth: "active last month",
  lastWeek: "active last week",
  lastDay: "active last day",
  lastHour: "active last hour",
};
export const IMGSIZEERRORFORAPPTEMPLATE=translation("IMGSIZEERRORFORAPPTEMPLATE");
export const SUBMITBUTTONERRORMESSAGE=translation("SUBMITBUTTONERRORMESSAGE");
export const DEVELOPERDESCRIPTION=translation("DEVELOPERDESCRIPTION")
export const STEP1DESCRIPTION=translation("STEP1DESCRIPTION");
export const APPCLIENTID=translation("APPCLIENTID");
export const BACKGROUNDIMAGE=translation("BACKGROUNDIMAGE")
export const APPIMAGE=translation("APPIMAGE")
export const FONTCOLOR=translation("FONTCOLOR")
export const SETDEFAULT=translation("SETDEFAULT")
export const STEP2DESCRIPTION=translation("STEP2DESCRIPTION")
export const CODESNIPPET=translation("CODESNIPPET")
export const APPCLIENTIDDESCRIPTION=translation("APPCLIENTIDDESCRIPTION")
export const HELPTEXT=translation("HELPTEXT")
export const BGIMGSIZEERROR=translation("BGIMGSIZEERROR")
export const FONTERRORMESSAGE=translation("FONTERRORMESSAGE")
export const APPTEMPLATEESUCCESS=translation("APPTEMPLATEESUCCESS")
export const CUSTOMTEMPLATEHEADER=translation("CUSTOMTEMPLATEHEADER")
export const OVERRIDETEMPLATE=translation("OVERRIDETEMPLATE")
export const NEWTEMPLATE=translation("NEWTEMPLATE")
export const SAVETEMPLATEBODY=translation("SAVETEMPLATEBODY")
export const TEMPLATENAME = "TEMPLATENAME"
export const CUSTOMTEMPLATE=translation("CUSTOMTEMPLATE")
export const EMAILSUBJECT = "EMAILSUBJECT"
export const ENTERSUBJECT = "ENTERSUBJECT"
export const ENTERMESSAGE="ENTERMESSAGE"
export const CUSTOMEMAIL="CUSTOMEMAIL"
export const CUSTOMMESSAGE = translation("CUSTOMMESSAGE")
export const CUSTOMTITLE="CUSTOMTITLE"
export const ENTERTITLE="ENTERTITLE"
export const CUSTOMICON="CUSTOMICON"
export const EMAILPREVIEW=translation("EMAILPREVIEW")
export const INVITATION=translation("INVITATION")
export const INVITATION_COLLABORATION=translation("INVITATION_COLLABORATION")
export const ACCEPT_INVITATION=translation("ACCEPT_INVITATION")
export const INTERNAL_USER=translation("INTERNAL_USER")
export const EMAIL_SENTTO=translation("EMAIL_SENTTO")
export const SUPPORT=translation("SUPPORT")
export const THANKS=translation("THANKS")
export const APPSHUB=translation("APPSHUB") //CARRIERAPPSHUB
export const ICONERROR="ICONERROR"
export const MAPDEFAULTGROUP=translation("MAPDEFAULTGROUP")
export const GENERATEAPIKEY=translation("GENERATEAPIKEY")
export const APIKEYDESC=translation("APIKEYDESC")
export const TimeoutStyles = {
  root: {
    width: 28,
    height: 16,
    padding: 0,
    marginRight: 8,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: "#f7f7f7",
    "&$checked": {
      transform: "translateX(12px)",
      color: "#f7f7f7",
      "& + $track": {
        opacity: 1,
        backgroundColor: "#ff0000",
        borderColor: "#ff0000",
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid #666666`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "#666666",
    height: "unset",
  },
  checked: {},
  focusVisible: {},
};
export const AppButtonStyles = {
  addAppButtonLabel: {
    textTransform: "none",
    fontSize: "1rem",
    color: "#fff",
    padding: "0",
    lineHeight: "18px",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
  },
  addAppButton: {
    textTransform: "none",
    fontSize: "1rem",
    color: "#fff",
    padding: "0px 10px",
    height: "37px",
    lineHeight: "18px",
    maxWidth: "164px",
    backgroundColor: "#0033ab",
    borderColor: "#0033ab",
    borderRadius: "3px",
    float: "right",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),

    "&:hover": {
      color: "#fff",
      backgroundColor: "#0033ab",
      borderColor: "#0033ab",
      borderRadius: "3px",
    },
  },
};
export const cardStyles = {
  backgroundColor: "#ffffff",
  boxShadow: "none",
  borderRadius: 0,
  height: "150px",
  transition: "box-shadow .2s ease-out",
};
export const AppCardStyles = {
  appEditLevelItem: {
    margin: "0px 25px 25px 25px",
    padding: 0,
  },
  appLevelItem: {
    padding: "5px !important",
  },
  appCard: {
    ...cardStyles,
    cursor: "default",
    boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2)",
  },
  card: {
    ...cardStyles,
    cursor: "pointer",
    border: "2px solid #f0f0f0",
    backgroundColor: "#fdfdfd",
    "&:hover": {
      border: "2px solid #3f51b5",
      boxShadow: "0 10px 25px rgba(0,0,0,0.2)",
      margin: "-2px",
    },

    "&:focus": {
      border: "2px solid #3f51b5",
      boxShadow: "0 10px 25px rgba(0,0,0,0.2)",
      margin: "-2px",
      outline: "none",
    },
  },
  media: {
    margin: "0 auto",
  },
  cardHeader: {
    padding: "8px",
    paddingBottom: "1.3rem",
    width: "100%",
    alignItems: "baseline",
  },
  cardContent: {
    display: "flex",
    padding: 0,
    height: "40%",
  },
  appTitle: {
    color: "#000000",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "1em",
    lineHeight: "1.5em",
    textAlign: "left",
    margin: 0,
    "word-break": "break-all",
  },
};
export const AppsStyles = {
  root: {
    flexGrow: 1,
  },
  appsListTitle: {
    marginBottom: "1rem",
    color: "#000000",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "2rem",
    fontWeight: "bold",
    lineHeight: "30px",
    padding: "5px",
  },
  gridContainer: {
    width: "100%",
    margin: 0,
    display: "inline-block",
  },
  appsListNotFound: {
    height: "100%",
    width: "100%",
    color: "black",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "22px",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
  },
  childAppMenu: {
    width: "17%",
    maxWidth: `calc(100% - 32px)`,
    minWidth: "16px",
    maxHeight: 200,
    boxShadow:
      "0px 3px 3px -3px rgb(0 0 0 / 20%), 0px 4px 5px 1px rgb(0 0 0 / 14%), 0px 3px 7px 2px rgb(0 0 0 / 12%)",
    ["@media only screen and (max-width: 768px)"]: {
      width: "35%",
    },
    ["@media only screen and (max-width: 750px)"]: {
      width: "auto",
    },
  },
  ...AppButtonStyles,
  ...AppCardStyles,
};

const genericSaveButton = (theme) => ({
  saveButton: {
    // minHeight: "90px",
    backgroundColor: "#0033ab",
    zIndex: "1",
    width: "100%",
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
    },
    bottom: 0,
  },
  
});

export const genericUpdateButtton = {
  updatebutton: {
    height: "34px",
    boxShadow: "none",
    textTransform: "none",
    fontSize: "12px",
    lineHeight: "18px",
    backgroundColor: "#ffffff",
    borderColor: "#ffffff",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    color: "#000000",
    textAlign: "center",
    borderRadius: "3px",
    margin: "19px 15px 17px 15px",
    width: "calc(100% - 30px)",

    "&:hover": {
      backgroundColor: "#ffffff",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#ffffff",
    },
    "&:focus": {
      boxShadow: "none",
      backgroundColor: "#ffffff",
    },
  },
};

export const AddFilterStyles = (theme) => ({
  chip: {
    marginLeft: theme.spacing(0.5),
  },
});

export const groupRootContainerStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  appEditRoot: {
    flexGrow: 1,
    overflow: "hidden",
  },
  gridContainer: {
    width: "100%",
    margin: 0,
  },
  appItem: {
    padding: "0 !important",
  },
  appEditTabItem: {
    padding: "0 !important",
    height: "100vh",
  },
  editGroupPaper: {
    boxShadow: "none",
  },
});

export const GroupStyles = (theme) => ({
  ...genericSaveButton(theme),
  ...groupRootContainerStyles(theme),
  ...genericUpdateButtton,
  ...commonLabelInputStyle,
  usersPaper: {
    height: "100vh",
    backgroundColor: "#f0f0f0",
  },
  editTitle: {
    height: "48px",
    backgroundColor: "#ffffff",
  },
  editAppContent: {
    overflow: "hidden auto",
    height: "calc(100% - 116px)",
    [theme.breakpoints.down("sm")]: {
      height: "inherit",
    },
  },
  editAddAppContent: {
    overflow: "hidden auto",
    height: "calc(100% - 116px)",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100% - 300px)",
    },
  },
  editGroupTitle: {
    margin: 0,
    color: "#000000",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "16px",
    lineHeight: "22px",
    padding: "12px",
  },
  changeGroupName: {
    display: "none",
    visibility: "hidden",
  },
  closeIcon: {
    float: "right",
    position: "relative",
    top: "50%",
    transform: "translateY(10%)",
    cursor: "pointer",
  },
  disclaimer: {
    color: "#000000",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "11px",
    lineHeight: "18px",
    padding: "12px",
    margin: 0,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  inputStyles: {
    padding: "6px 7px 3px",
    overflow: "auto",
  },
  inputFocused: {
    border: "1px solid #4f4f4f",
    backgroundColor: "#fff",
    borderBottom: "none",
    color: "#000000 !important",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "12px",
    lineHeight: "18px",
  },
  inputUnderline: {
    "&::before": {
      borderBottom: "1px solid #4f4f4f",
    },
    "&::after": {
      borderBottom: "none",
    },
    "&:hover:not(.MuiInput-disabled-345):not(.MuiInput-focused-344):not(.MuiInput-error-347):before": {
      borderBottom: "1px solid #4f4f4f",
    },
  },
  disabledTextField: {
    color: "#000000",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "12px",
    lineHeight: "18px",
  },
  disabledLabelRoot: {
    color: "#000000 !important",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "11px",
    lineHeight: "18px",
    transform: "none",
  },
  optionsButton: {
    marginLeft: "20px",
    color: "#0033ab",
    border: "1px solid #0033ab",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "12px",
    lineHeight: "18px",
    textAlign: "center",
    height: "32px",
    width: "70px",
    borderRadius: "3px",

    "&:hover": {
      backgroundColor: "#0033ab",
      color: "#fff",
      textTransform: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#0033ab",
      color: "#fff",
      textTransform: "none",
    },
    "&:focus": {
      boxShadow: "none",
      backgroundColor: "#0033ab",
      color: "#fff",
      textTransform: "none",
      outline: "none",
    },
  },
  optionsButtonText: {
    textTransform: "none",
    padding: "6px 0 8px 0",
  },
  requiredAsterik: {
    paddingRight: 0,
    paddingBottom: 0,
    color: "#000000",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "11px",
    lineHeight: "18px",
    padding: "12px",
    margin: 0,
  },
  addSubPermission: {
    color: "#0033ab",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "11px",
    lineHeight: "18px",
    margin: 0,
    cursor: "pointer",
  },
  addSubPermissionIcon: {
    marginTop: "6px",
  },
  groupName: {
    padding: 0,
    width: "calc(100% - 40px)",
  },
  groupNameTextField: {
    margin: "12px 20px 6px 6px",
    width: "100%",
  },
  selectParentName: {
    margin: "0px 0px 0px 10px;",
    width: "100%",
  },
  errorText: {
    color: "#eb1616 !important",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "11px",
    lineHeight: "18px",
    marginLeft: "5px",
  },
});

export const EditProfileStyles = (theme) => ({
  ...genericSaveButton(theme),
  ...genericUpdateButtton,
});

export const AddGroupStyles = (theme) => ({
  ...GroupStyles(theme),
  ...AddGroupSubStyles(theme),
  ...ApplyAllGroupsRootSetting(theme),
  ...AddFilterStyles(theme),
  ...AuditLogDetails(theme),
});

export const AddGroupSubStyles = (theme) => ({
  paperSize: {
    height: "100vh",
    backgroundColor: "#f7f7f7",
    position: "relative",
  },
  textField: {
    margin: "12px 20px 6px 20px",
    width: "100%",
  },
  invalidTextField: {
    border: "1px solid #eb1616",
    color: "#000000 !important",
    backgroundColor: "#fff",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "12px",
    lineHeight: "18px",
    padding: "6px 7px 3px",

    "&::before": {
      borderBottom: "none",
    },
    "&::after": {
      borderBottom: "none",
    },
    "&:hover:not(.MuiInput-disabled-345):not(.MuiInput-focused-344):not(.MuiInput-error-347):before": {
      borderBottom: "none",
    },
  },
});

export const GroupCommonStyles = (theme) => ({
  disableControl: {
    flexDirection: "column",
    height: "inherit",
    overflowX: "hidden",
    overflowY: "auto",
    opacity: 0.5,
    pointerEvents: "none",
  },
});

export const EditGroupStyles = (theme) => ({
  ...GroupStyles(theme),
  ...GroupCommonStyles(theme),
  ...EditGroupSubStyle(theme),
  ...AppButtonStyles,
  ...AppCardStyles,
  ...TemplateTabStyles
});

export const FilterStyles = (theme) => ({
  ...AddGroupStyles(theme),
  deleteFilter: {
    cursor: "pointer",
    background: "#228B28",
    borderRadius: "3px 0px 0px 3px",
    padding: "8px",
    "&:hover": {
      background: "#228B28 !important",
    },
  },
  editFilter: {
    cursor: "pointer",
    borderRadius: "0px 3px 3px 0px",
    background: "white",
    color: "#228B28",
    padding: "9px",
    "&:hover": {
      background: "white !important",
    },
  },
  tabBody: {
    maxHeight: "64vh !important",
    overflowY: "auto",
    marginTop: "10px",
  },
  userListStyle: {
    minHeight: "35px",
    overflow: "visible",
    borderLeft: "1px solid #F0F0F0",
    borderRight: "1px solid #F0F0F0",
    borderBottom: "1px solid #F0F0F0",
    backgroundColor: "#FFFFFF",
    width: "100%",
    display: "inline-flex",
  },
  userLogo: {
    paddingTop: "16px",
  },
  userDetails: {
    padding: "8px 10px",
  },
  userSearchBox: {
    margin: "10px 0px 0px 10px",
  },
  groupFilterTooltip: {
    width: "250px !important",
    "white-space": "normal !important",
    "word-wrap": "break-word  !important",
    fontSize: "13px !important",
  },
});

export const EditGroupSubStyle = (theme) => ({
  parentChangeButton: {
    width: "48%",
  },
  parentChangeButtonDisable: {
    width: "48%",
    opacity: "0.3",
    cursor: "not-allowed",
  },
  parentGroupContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "8px 21px",
    width: "calc(100% - 40px)",
    padding: "0px",
    overflow: "hidden",
  },
  parentGroup: {
    display: "flex",
    margin: "14px 9px",
    width: "100%",
  },
  parentGroupSpan: {
    display: "inline-flex",
    width: "50%",
    alignItems: "center",
  },
  optionalField: {
    marginLeft: "12px",
  },
  parentApplication: {
    width: "calc(100% - 40px)",
  },
  imageUploadError: {
    margin: "0px 8px",
  },
  adjustDisclaimerPadding: {
    padding: "8px 12px",
  },
  removeDisclaimerPadding: {
    padding: "0px",
    fontWeight: "500",
    fontSize: "13px",
    minHeight: "18px",
  },
  preview: {
    padding: "0px 11px 7px",
    fontWeight: "500",
  },
  cardContent: {
    padding: "7px 8px",
  },
  imageDisclaimerContainer: {
    padding: "0px 14px",
  },
  card: {
    width: "100%",
    height: "177px",
    margin: "0px 25px 25px 25px",
  },
  media: {
    height: "143px",
  },
  appPermissionsTextField: {
    margin: "12px 20px 12px 6px",
    width: "100%",
  },
  permissionSaveIcon: {
    color: "#0033ab",
    cursor: "pointer",
    marginBottom: "58px",
  },
  permissionCloseIcon: {
    marginBottom: "58px",
    cursor: "pointer",
  },
  permissionsFieldContainer: {
    display: "flex",
    marginBottom: "0px",
    backgroundColor: "white",
    borderTop: "1px solid #d8d0d0",
  },
  permissionsSubFieldContainer: {
    display: "flex",
    width: "100%",
    height: "35px",
    marginBottom: "0px",
    backgroundColor: "#F7F7F7",
    alignItems: "center",
    paddingLeft: "13px",
  },
  noPermissions: {
    margin: "0px 0px 0px 27px",
  },
  fieldContainer: {
    display: "flex",
    width: "100%",
    marginBottom: "0px",
  },
  uploadDiv: {
    display: "flex",
    flexDirection: "row",
    margin: "0px 25px",
    alignItems: "center",
  },
  uploadFieldContainer: {
    display: "flex",
    width: "100%",
    marginBottom: "0px",
    flexDirection: "column",
  },
  fileName: {
    fontWeight: "bold",
  },
  uploadButton: {
    textTransform: "none",
    fontSize: "1rem",
    color: "#fff",
    padding: "0px 10px",
    marginRight: "8px",
    height: "32px",
    lineHeight: "18px",
    width: "70px",
    backgroundColor: "#0033ab",
    borderColor: "#0033ab",
    borderRadius: "3px",
    float: "right",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),

    "&:hover": {
      color: "#fff",
      backgroundColor: "#0033ab",
      borderColor: "#0033ab",
      borderRadius: "3px",
    },
  },
  editPaperSize: {
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      height: "90vh",
    },
    backgroundColor: "#f7f7f7",
    position: "relative",
  },
  editAppPaperSize: {
    height: "100vh",
    backgroundColor: "#f7f7f7",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      minHeight: "129vh",
      maxHeight: "169vh",
      height: "unset",
    },
  },
  rolesField: {
    color: "#000000",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "11px",
    lineHeight: "18px",
    padding: "12px 12px 0px 12px",
    margin: 0,
  },
  editTextField: {
    margin: "12px 20px 24px 20px",
    width: "100%",
  },
  rolesSelectField: {
    margin: "12px 10px 6px",
    width: "100%",
  },
  invalidTextField: {
    border: "1px solid #eb1616",
    color: "#000000 !important",
    backgroundColor: "#fff",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "12px",
    lineHeight: "18px",
    padding: "6px 0px 3px 7px",

    "&::before": {
      borderBottom: "none",
    },
    "&::after": {
      borderBottom: "none",
    },
    "&:hover:not(.MuiInput-disabled-345):not(.MuiInput-focused-344):not(.MuiInput-error-347):before": {
      borderBottom: "none",
    },
  },
  userButtonRootContainerMargin: {
    margin: "12px",
  },
  userButtonRootContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "0px 12px",
  },
  userButtonRootContainerDisable: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "0px 12px",
    opacity: 0.5,
    cursor: "not-allowed",
  },
  userButtonRoot: {
    opacity: "1",
    border: "1px solid #0033ab",
    boxShadow: "none",
    textTransform: "none",
    fontSize: "1rem",
    color: "#0033ab",
    lineHeight: "18px",
    backgroundColor: "transparent",
    borderRadius: "3px",
    width: "100%",
    minHeight: "32px",
    maxHeight: "64px",
    padding: "6px",
    margin: "0px 5px 5px 0px",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontWeight: "400",

    "&:hover": {
      borderRadius: "3px",
      fontWeight: "400",
      boxShadow: "none",
      backgroundColor: "#0033ab",
      color: "white",
    },

    "&:focus": {
      borderRadius: "3px",
      fontWeight: "400",
      boxShadow: "none",
      backgroundColor: "#0033ab",
      color: "white",
    },

    "&:active": {
      color: "#0033ab",
      backgroundColor: "transparent",
      borderColor: "#0033ab",
      borderRadius: "3px",
      fontWeight: "400",
    },
  },
  divDefaultApp:{
    display:"inline",
  },
  spanDefaultAppCheckBox:{
    marginLeft:"16px"
  }

});

export const TabsStyles = {
  tabsRoot: {
    borderBottom: "none",
    width: "100%",
    display: "inline-block",
  },
  tabsIndicator: {
    background: "none",
  },
  tabRoot: {
    textTransform: "capitalize",
    fontWeight: "normal",
  },

  "&$tabSelected": {
    color: "#000000",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: "normal",
  },
  tabSelected: {},
  userButtonRoot: {
    opacity: "1",
    boxShadow: "none",
    backgroundColor: "#0033ab",
    color: "#ffffff",
  },

  "&:active": {
    color: "#fff",
    backgroundColor: "#0033ab",
    borderColor: "#0033ab",
    borderRadius: "3px",
    fontWeight: "400",
    position: "absolute",
    right: "20px",

    "&:hover": {
      borderRadius: "3px",
      fontWeight: "400",
      textDecoration: "underline",
      boxShadow: "none",
      backgroundColor: "#0033ab",
      color: "#ffffff",
    },

    "&:focus": {
      borderRadius: "3px",
      fontWeight: "400",
      textDecoration: "underline",
      boxShadow: "none",
      backgroundColor: "#0033ab",
      color: "#ffffff",
    },

    "&:active": {
      color: "#fff",
      backgroundColor: "#0033ab",
      borderColor: "#0033ab",
      borderRadius: "3px",
      fontWeight: "400",
    },
  },
};

export const ManageGroupStyles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#f0f0f0",
  },
  typography: {
    padding: theme.spacing.unit * 3,
  },
  ...TabsStyles,
  ...HeaderTextAndButtonStyles(theme),
});

export const HeaderTextAndButtonStyles = (theme) => ({
  spanPermissionText: {
    marginLeft: "3px",
  },
  appAdminText: {
    display: "flex",
    alignItems: "center",
    width: "50%",
    margin: "0px 0px 0px 27px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      margin: "0px 0px 6px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  appAdminButtonContainer: {
    display: "flex",
    alignItems: "center",
    width: "50%",
    margin: "0px 27px 0px 0px",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "block",
    },
  },
  userAppButtonRoot: {
    opacity: "1",
    boxShadow: "none",
    textTransform: "none",
    fontSize: "1rem",
    color: "#fff",
    lineHeight: "18px",
    backgroundColor: "#0033ab",
    borderRadius: "3px",
    minWidth: 72,
    minHeight: "0",
    height: "35px",
    margin: "8px 5px",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontWeight: "400",

    "&:hover": {
      borderRadius: "3px",
      fontWeight: "400",
      textDecoration: "underline",
      boxShadow: "none",
      backgroundColor: "#0033ab",
      color: "#ffffff",
    },

    "&:focus": {
      borderRadius: "3px",
      fontWeight: "400",
      textDecoration: "underline",
      boxShadow: "none",
      backgroundColor: "#0033ab",
      color: "#ffffff",
    },

    "&:active": {
      color: "#fff",
      backgroundColor: "#0033ab",
      borderColor: "#0033ab",
      borderRadius: "3px",
      fontWeight: "400",
    },
    [theme.breakpoints.down("sm")]: {
      width: "87%",
    },
  },
});

export const TableBodyStyles = {
  root: {
    width: 28,
    height: 16,
    padding: 0,
    marginRight: 8,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: "#f7f7f7",
    "&$checked": {
      transform: "translateX(12px)",
      color: "#f7f7f7",
      "& + $track": {
        opacity: 1,
        backgroundColor: "#34cb81",
        borderColor: "#34cb81",
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid #666666`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "#666666",
    height: "unset",
  },
  checked: {},
  focusVisible: {},
};

export const TableHeaderStyles = (theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    marginRight: theme.spacing(1),
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: "#666666",
    "&$checked": {
      transform: "translateX(12px)",
      color: "#666666",
      "& + $track": {
        opacity: 1,
        backgroundColor: "#e8e8e8",
        border: "1px solid #666",
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    borderRadius: 16 / 2,
    opacity: 1,
    background: "none",
    height: "unset",
    border: "1px solid #666",
  },
  checked: {},
  focusVisible: {},
});

export const DrawerWidth = 240;

export const HeaderDialogContentStyles = {
  root: {
    margin: 0,
    padding: "1.3rem",
  },
};

export const HeaderDialogActionStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit,
    justifyContent: "flex-start",
    height: "34px",
    boxSizing: "content-box",
  },
});

export const HeaderDialogStyles = (theme) => ({
  root: {
    margin: 0,
    padding: "7px",
    backgroundColor: "#f7f7f7",
    color: "#000000",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "1rem",
    lineHeight: "1.3rem",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: "#000000",
    fontSize: "0.8rem",
    lineHeight: "1.3rem",
    padding: 0,
  },
});

export const HeaderStyles = (theme) => ({
  root: {
    width: "100%",
    display: "flex",
  },
  navBar: {
    backgroundColor: "#0033ab",
    boxShadow: "none",
    height: "50px",
  },
  navToolBar: {
    minHeight: "50px",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  tabsRoot: {},
  tabsIndicator: {
    display: "none",
  },
  tabRoot: {
    opacity: "1",
    boxShadow: "none",
    textTransform: "none",
    fontSize: "1rem",
    color: "#fff",
    padding: "0",
    lineHeight: "18px",
    backgroundColor: "#565dde",
    borderRadius: "3px",
    minWidth: 72,
    minHeight: "0",
    height: "35px",
    margin: "8px 5px",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontWeight: "400",

    "&:hover": {
      borderRadius: "3px",
      fontWeight: "400",
      textDecoration: "underline",
      boxShadow: "0 5px 0.8rem rgba(0,0,0,0.2)",
      backgroundColor: "#000F7B",
      color: "#ffffff",
    },

    "&:focus": {
      borderRadius: "3px",
      fontWeight: "400",
      textDecoration: "underline",
      boxShadow: "0 5px 0.8rem rgba(0,0,0,0.2)",
      backgroundColor: "#000F7B",
      color: "#ffffff",
    },

    "&:active": {
      color: "#fff",
      backgroundColor: "#565dde",
      borderColor: "#565dde",
      borderRadius: "3px",
      fontWeight: "400",
    },

    "&$tabSelected": {
      height: "35px",
      color: "#000000",
      fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
        ","
      ),
      fontSize: "1rem",
      lineHeight: "18px",
      textAlign: "center",
      textDecoration: "underline",
      backgroundColor: "#ffffff",
      borderRadius: "3px",
      boxShadow: "none",
      textTransform: "none",
      margin: "8px 5px",
      fontWeight: "400",
      minHeight: "0",
    },
  },
  tabSelected: {},
  tabLabelContainer: {
    padding: "0.5rem 1rem",
  },
  accountButtonRoot: {
    width: "34px",
    height: "34px",
    backgroundColor: "#000F7B",
    margin: "8px",
    padding: 0,
    "&:hover": {
      backgroundColor: "#000F7B",
    },
  },
  accountButtonRootOutline: {
    width: "34px",
    height: "34px",
    backgroundColor: "#565dde",
    border: "2px solid #fff",
    margin: "8px",
    padding: 0,
    "&:hover": {
      backgroundColor: "#565dde",
      cursor: "pointer",
    },
  },
  userAccountDialogPaper: {
    borderRadius: "0px",
    minHeight: "172px",
    width: "300px",
    right: 0,
    position: "absolute",
    margin: 0,
    top: "50px",
  },
  userAccountDialogBackdrop: {
    background: "none",
  },
  logoutButtonLabel: {
    textTransform: "none",
    fontSize: "1rem",
    color: "#fff",
    padding: "0",
    lineHeight: "18px",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
  },
  logoutButton: {
    textTransform: "none",
    fontSize: "1rem",
    color: "#fff",
    padding: "0px 10px",
    height: "100%",
    lineHeight: "18px",
    backgroundColor: "#0033ab",
    borderColor: "#0033ab",
    borderRadius: "3px",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),

    "&:hover": {
      color: "#fff",
      backgroundColor: "#0033ab",
      borderColor: "#0033ab",
      borderRadius: "3px",
    },
  },
  deleteAccountLabel: {
    textTransform: "none",
    fontSize: "1rem",
    color: "#0033ab",
    boxShadow: "none",
    padding: "0",
    lineHeight: "18px",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
  },
  deleteAccount: {
    textTransform: "none",
    fontSize: "1rem",
    color: "#0033ab",
    textDecoration: "underline",
    padding: "0",
    lineHeight: "18px",
    backgroundColor: "transparent",
    flexDirection: "row-reverse",
    width: "53%",
    justifyContent: "flex-end",
    boxShadow: "none",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),

    "&:hover": {
      color: "#0033ab",
      backgroundColor: "transparent",
    },
  },
  updateEmail: {
    textTransform: "none",
    fontSize: "1rem",
    color: "#0033ab",
    textDecoration: "underline",
    padding: "0",
    lineHeight: "18px",
    backgroundColor: "transparent",
    flexDirection: "row-reverse",
    width: "40%",
    justifyContent: "flex-end",
    boxShadow: "none",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),

    "&:hover": {
      color: "#0033ab",
      backgroundColor: "transparent",
    },
  },
  updateEmailLabel: {
    textTransform: "none",
    fontSize: "1rem",
    color: "#0033ab",
    boxShadow: "none",
    padding: "0",
    lineHeight: "18px",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
  },
  editEmailSm: {
    textTransform: "none",
    fontSize: "1rem",
    color: "white",
    boxShadow: "none",
    padding: "0",
    lineHeight: "18px",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
  },
  editEmailSm: {
    textTransform: "none",
    fontSize: "1rem",
    color: "white",
    textDecoration: "underline",
    padding: "0",
    lineHeight: "18px",
    backgroundColor: "transparent",
    flexDirection: "row-reverse",
    boxShadow: "none",
    margin: "0px 5px",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),

    "&:hover": {
      color: "white",
      backgroundColor: "transparent",
    },
  },
  deleteAccountLabelSm: {
    textTransform: "none",
    fontSize: "1rem",
    color: "white",
    boxShadow: "none",
    padding: "0",
    lineHeight: "18px",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
  },
  deleteAccountSm: {
    textTransform: "none",
    fontSize: "1rem",
    color: "white",
    textDecoration: "underline",
    padding: "0",
    lineHeight: "18px",
    backgroundColor: "transparent",
    flexDirection: "row-reverse",
    boxShadow: "none",
    margin: "0px 5px",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),

    "&:hover": {
      color: "white",
      backgroundColor: "transparent",
    },
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: DrawerWidth,
      flexShrink: 0,
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: DrawerWidth,
    backgroundColor: "#000f7b",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  listItemIcon: {
    color: "#fff",
  },
  listItemUserIcon: {
    color: "#fff",
    backgroundColor: "#0033ab",
    borderRadius: "50%",
    padding: "5px",
    marginRight: "5px",
    minWidth: "0",
  },
  listItemLogoutButton: {
    height: "34px",
  },
  listItemText: {
    color: "#FFFFFF",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "1rem",
    lineHeight: "18px",
  },
  menuItem: {
    "&$selectedListItem": {
      backgroundColor: "#565dde",

      "&:hover": {
        backgroundColor: "#565dde",
      },
    },
  },
  menuItemUser: {
    position: "fixed",
    bottom: "73px",

    "&$selectedListItem": {
      backgroundColor: "#565dde",

      "&:hover": {
        backgroundColor: "#565dde",
      },
    },
  },
  menuItemEditMail: {
    position: "fixed",
    bottom: "47px",

    "&$selectedListItem": {
      backgroundColor: "#565dde",

      "&:hover": {
        backgroundColor: "#565dde",
      },
    },
  },
  menuItemLogoutButton: {
    cursor: "default",
    position: "fixed",
    bottom: "7px",

    "&$selectedListItem": {
      backgroundColor: "#565dde",

      "&:hover": {
        backgroundColor: "#565dde",
      },
    },
  },
  selectedListItem: {},
  userNameText: {
    color: "#000000",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "2rem",
    fontWeight: "bold",
    lineHeight: "30px",
    marginBottom: 0,
  },
  drawercloseButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit,
  },
  drawerDivider: {
    height: 0,
  },
  headerGutters: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
  versionNumber: {
    color: "#000000",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "1rem",
    lineHeight: "18px",
    margin: "0px 5px",
    width: "37%",
  },
  pointer: { cursor: "pointer" },
  iconDiv: {
    paddingLeft: "30%",
    "flex-direction": "row",
    "column-gap": "10px;",
    display: "flex;",
  },
  iconDivBorder: {
    padding: "5px",
    "border-radius": "50%",
    "border-style": "solid",
    "border-width": "1px",
    "border-color": "#add8e6",
    "&:hover": {
      backgroundColor: "#e6eef1",
      "border-color": "#0033ab",
    },
  },
  iconDivDrawer: {
    paddingRight: "10%",
    "flex-direction": "row",
    "column-gap": "10px;",
    display: "flex;",
  },
});

export const OptionMenuDialogStyles = {
  root: {
    width: "300px",
  },
  deletetitle: {
    color: "#000000",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "12px",
  },
  deleteDialogBackdrop: {
    background: "none",
  },
  dialogActions:{
    padding:"0px 22px 8px 20px !important",
  },
  actionButton:{
    width:"75% !important",
  },
  actionButtonAdd:{
    width:"75% !important",
    color: "#FFFFFF !important",
    backgroundColor: "#0033AB",
    '&:hover': {
      color: '#FFFFFF !important',
      backgroundColor: '#0033AB !important',
    }
  },
  dialogTitle:{
    padding:"14px 22px 4px !important"
  },
  templateName:{
    width:"100% !important",
    margin:"6px 0px 0px 0px !important"
  },
  labelRoot: {
    color: "#000000",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "11px",
    lineHeight: "18px",
    transform: "none",
  },
  labelFocused: {
    color: "#000000 !important",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "11px",
    lineHeight: "18px",
    transform: "none",
    marginBottom: "3px",
  },
  closeDialog:{
    float:"right",
    padding:"3px !important"
  },
  errorText: {
    color: "#eb1616 !important",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "11px",
    lineHeight: "18px",
  },

};

export const OptionMenuStyles = {
  optionsButton: {
    color: "#0033ab",
    border: "1px solid #0033ab",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "12px",
    lineHeight: "18px",
    textAlign: "center",
    height: "32px",
    width: "100%",
    borderRadius: "3px",

    "&:hover": {
      backgroundColor: "#0033ab",
      color: "#fff",
      textTransform: "none",
    },
    "&:focus": {
      boxShadow: "none",
      color: "#0033ab",
      textTransform: "none",
      outline: "none",
    },
  },
  optionsButtonText: {
    textTransform: "none",
    padding: "6px 0 8px 0",
    "&:hover": {
      color: "#fff",
    },
  },
  optionsMenuPaper: {
    backgroundColor: "#ffffff",
    width: "150px",
  },
  buttonDisable: {
    cursor: "not-allowed !important",
  },
  optionsMenuItem: {
    minHeight: "32px",
    color: "#0033ab",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "12px",
    lineHeight: "18px",
    textAlign: "center",

    "&:hover": {
      textDecoration: "underline",
      color: "#000",
      fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
        ","
      ),
      fontSize: "12px",
      lineHeight: "18px",
      textAlign: "center",
    },
  },
};

export const ToastMessageStyle1 = (theme) => ({
  success: {
    backgroundColor: "#34CB81",
    height: 40,
  },
  error: {
    backgroundColor: theme.palette.error.dark,
    height: 40,
  },
  warning: {
    backgroundColor: "orange",
    height: 40,
  },
  icon: {
    fontSize: 15,
    float: "left",
    color: "white",
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
    marginLeft: 5,
    color: "white",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "12px",
  },
});

export const ToastMessageStyle2 = (theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
});

export const commonLabelInputStyle = {
  inputRoot: {
    color: "#000000",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "12px",
    lineHeight: "18px",
  },
  labelRoot: {
    color: "#000000",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "11px",
    lineHeight: "18px",
    transform: "none",
  },
  labelFocused: {
    color: "#000000 !important",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "11px",
    lineHeight: "18px",
    transform: "none",
    marginBottom: "3px",
  },
};

export const SendInvitationStyles = (theme) => ({
  ...genericSaveButton(theme),
  ...genericUpdateButtton,
  ...commonLabelInputStyle,
  nonInternalUser: {
    display: "flex",
  },
  hvacLogo: {
    margin: "10px 0px 0px 15px",
  },
  mailLogo: {
    margin: "22px 0px 4px 10px",
  },
  root: {
    flexGrow: 1,
  },
  inputStyles: {
    padding: "7px 0px 5px 0px",
    color: "#000000",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "12px",
    lineHeight: "18px",

    "&::placeholder": {
      textOverflow: "ellipsis !important",
      fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
        ","
      ),
      fontSize: "12px",
      lineHeight: "18px",
      color: "#A6A6A6",
    },
  },
  inputFocused: {
    border: "1px solid #4f4f4f",
    backgroundColor: "#fff",
    borderBottom: "#4f4f4f !important",
    color: "#000000 !important",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "12px",
    lineHeight: "18px",
  },
  inputUnderline: {
    "&::before": {
      borderBottom: "1px solid #a6a6a6",
    },
    "&::after": {
      borderBottom: "none",
    },
    "&:hover:not(.MuiInput-disabled-345):not(.MuiInput-focused-344):not(.MuiInput-error-347):before": {
      borderBottom: "1px solid #a6a6a6",
    },
  },
  userEmailInfo: {
    minWidth: "345px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
    },
  },
  internalUserTextField: {
    margin: "0px 9px",
    width: "calc(100% - 24px)",
    marginBottom: "6px",
    position: "relative",
  },
  externalUserEmailTextField: {
    margin: "15px 9px",
    width: "calc(83% - 24px)",
    marginBottom: "6px",
    position: "relative",
  },
  hvacUserEmailTextField: {
    margin: "15px 14px",
    width: "calc(82% - 23px)",
    marginBottom: "6px",
    position: "relative",
  },
  invalidTextField: {
    border: "1px solid #eb1616",
    color: "#000000 !important",
    backgroundColor: "#fff",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "12px",
    lineHeight: "18px",

    "&::before": {
      borderBottom: "none",
    },
    "&::after": {
      borderBottom: "none",
    },
    "&:hover:not(.MuiInput-disabled-345):not(.MuiInput-focused-344):not(.MuiInput-error-347):before": {
      borderBottom: "none",
    },
  },
  errorText: {
    color: "#eb1616 !important",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "11px",
    lineHeight: "18px",
  },
  externalError: {
    marginLeft: "72px",
  },
  hvacError: {
    marginLeft: "70px",
    marginTop:"-19px"
  },
  internalUserORText: {
    textAlign: "center",
    marginBottom: 0,
    marginTop:0
  },
  internalUserEmailTextField: {
    position: "relative",
  },
  disclaimer: {
    color: "#000000",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "11px",
    lineHeight: "18px",
    padding: "12px",
    margin: 0,
  },
  editInvite:{
    margin:"8px 8px -3px 15px",
    fontSize:"1.3rem",
    cursor:"pointer"
  },
  editInviteText:{
    fontSize:"12px",
  },
  overRideInvite:{
    marginTop:"10px !important",
  },
  customInviteSelectSpan:{
    display:"flex",
    margin:"10px 0px 0px 15px"
  },
  customInviteSelect:{
    minWidth:"75%",
    marginRight:"4px",
    backgroundColor: "#0033ab",
    color: "white",
    minHeight: "19px",
    
    "& .MuiFormControl-root":{
      borderColor: "#BAC0D0",
      borderRadius: "4px",
      border: "1px solid"
    },
    "& .MuiInput-underline:after" :{
      transform: "none",
      borderBottom :"1px solid"             
    },
    "& .MuiInputBase-input":{
      color:"white",
      marginLeft:"5px"
    },
    "& .MuiInput-underline:after" :{
      transform: "none !important",
      borderBottom :"1px solid white !important"       
    },
    "& .MuiSvgIcon-root":{
      color:"white"
    }
  },
  customInviteBodySubSpan:{
    display:"flex",
    margin:"0px 0px 0px 15px",
    "& .MuiFormControl-marginNormal": {
      width:"92%",
      marginTop:"10px !important",
    },
    "& .MuiInputBase-root":{
      color:"white !important",
      borderBottom: "1px solid !important"
    },
    "& .MuiInput-underline:after" :{
      transform: "none !important",
      borderBottom :"1px solid white !important"       
    }
  },
  iconUpload:{
    display:"flex",
    margin:"6px 0px 0px 15px",
  },
  iconSpan:{
    marginLeft:"15px",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    )
  },
  labelRootCutomizeTemplate:{
    color:"white !important",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "11px",
    lineHeight: "18px",
    transform: "none",
  },
  saveIcon:{
    height:"22px",
    fontSize:"1.5rem",
  },
  deleteIcon:{
    float:"right",
  },
  liAutocomplete:{
   width:"93%",
   wordWrap:"break-word"
  },
  spanError:{
    marginLeft:"15px"
  },
  liAutoComplete:{
    display:"contents",
    width:"100%"
  },
  root:{
    borderRadius: "4px",
    color:"white !important",
    background:"#4255bd !important",
    opacity:"0.5 !important",
    marginRight:"5px"
  },
  customHoverFocus: {
    "&:hover, &.Mui-focusVisible": {  background:"#4255bd !important", opacity:"1 !important" }
  },
  customTemplateBody: {
    maxHeight: "226px",
    backgroundColor: "#0033ab",
    zIndex: "1",
    width: "100%",
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
    },
    // top: "287px",
    bottom:"61px",
    overflowY:"scroll",
    '&::-webkit-scrollbar': {
      width: "10px", // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
  },
  '&::-webkit-scrollbar-track': {
    background: "#f1f1f1", // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
},
'&::-webkit-scrollbar-thumb': {
  background: "#888", // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
},
'&::-webkit-scrollbar-thumb:hover':  {
  background: "#555", // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
}
},
  inviteUserButton: {
    backgroundColor: "#0033ab",
    zIndex: "1",
    width: "100%",
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
    },
    bottom: 0,
    overflowY:"scroll",
    '&::-webkit-scrollbar': {
      width: "10px", // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
  },
  '&::-webkit-scrollbar-track': {
    background: "#f1f1f1", // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
},
'&::-webkit-scrollbar-thumb': {
  background: "#888", // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
},
'&::-webkit-scrollbar-thumb:hover':  {
  background: "#555", // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
}
  },
  inviteUserButtonBeforeEdit: {
    backgroundColor: "#0033ab",
    zIndex: "1",
    width: "100%",
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
    },
    bottom: 0
  },
  editBody: {
    backgroundColor: "#0033ab",
    zIndex: "1",
    width: "100%",
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
    },
    // top: "517px",
    bottom:"61px"
  },
  bgImageStyle:{
    color:"#0000 !important",
    width:"100px !important"
},
fileNameSpan:{
  marginTop:"3px"
},
emailPreview:{
  height: "100vh",
  backgroundColor: "#f7f7f7",
  width: "357px",
  right:"345px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
  },
  overflowY:"scroll",
  overflowX: "hidden",
  '&::-webkit-scrollbar': {
    width: "10px", // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
},
'&::-webkit-scrollbar-track': {
  background: "#f1f1f1", // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
},
'&::-webkit-scrollbar-thumb': {
background: "#888", // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
},
'&::-webkit-scrollbar-thumb:hover':  {
background: "#555", // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
}
}
});

export const UserInviteStyles = (theme) => ({
  list: {
    width: 250,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  fullList: {
    width: "auto",
  },
  paperRoot: {
    height: "100vh",
    backgroundColor: "#f7f7f7",
    width: "345px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  }
  ,
  editTitle: {
    height: "48px",
    backgroundColor: "#ffffff",
    width: "345px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  editGroupTitle: {
    margin: 0,
    color: "#000000",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "16px",
    lineHeight: "22px",
    padding: "12px",
  },
  closeIcon: {
    float: "right",
    position: "relative",
    top: "50%",
    transform: "translateY(10%)",
    cursor: "pointer",
  },
});

export const EmailPreview=(theme)=>({
  previewTitle: {
    height: "48px",
    backgroundColor: "#ffffff",
    width: "345px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  previewDisplayTitle: {
    margin: 0,
    color: "#000000",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "16px",
    lineHeight: "22px",
    padding: "12px",
  },
  closeIcon: {
    float: "right",
    position: "relative",
    top: "50%",
    transform: "translateY(10%)",
    cursor: "pointer",
    // marginRight: "10px"
  },
  iconImage:{
    maxHeight: "50px",
    marginTop:"5px"
  },
  parentDiv:{
    backgroundColor: "#F4F4F4", marginTop:"16px"
  },
  parentTable:{
    width:"100%",
     border:"0",
      cellSpacing:"0",
      cellPadding:"0"
  },
  parentTd:{
    fontWeight: "normal",
    color: "black",
    fontSize: "20px",
    alignContent: "center",
    backgroundColor: "#FFFFFF",
    textAlign:"center",
    tableLayout:"fixed",
    wordBreak:"break-word"
  },
  acceptInvitation:{
    backgroundColor: "#0033ab",
    borderRadius: "4px",
    color: "#ffffff",
    display: "inline-block",
    fontFamily: "sans-serif",
    fontSize: "13px",
    fontWeight: "bold",
    lineHeight: "30px",
    textAlign: "center",
    textDecoration: "none",
    width: "160px",
    background:"linear-gradient(99.7deg, #15205E -19.43%, #0076F4 80.93%)"
  }

});

export const SettingsDrawerStyles = (theme) => ({
  ...HeaderTextAndButtonStyles(theme),
  list: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  appContainerList: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    height: "99vh",
    [theme.breakpoints.down("sm")]: {
      height: "81vh",
    },
  },
  disclaimer: {
    color: "#000000",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "11px",
    lineHeight: "18px",
    padding: "12px",
    margin: 0,
    fontWeight: 500,
  },
  paperRoot: {
    height: "100%",
    width: "600px",
    backgroundColor: "#f7f7f7",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
    },
    overflow: "hidden",
  },
  filterPaperRoot: {
    height: "100%",
    width: "350px",
    backgroundColor: "#f7f7f7",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
    },
    overflow: "hidden",
  },
  editTitle: {
    height: "48px",
    backgroundColor: "#ffffff",
    width: "100%",
    marginBottom: "10px",
  },
  editGroupTitle: {
    margin: 0,
    color: "#000000",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "22px",
    padding: "12px",
  },
  loadingTitle: {
    margin: "0px auto",
    color: "#000000",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",
    padding: "12px",
  },
  appsGroupsNoAccess: {
    margin: "16px 24px 0px 24px",
  },
  closeIcon: {
    float: "right",
    position: "relative",
    top: "50%",
    transform: "translateY(10%)",
    cursor: "pointer",
  },
  disableRootSettings: {
    flexDirection: "column",
    height: "inherit",
    overflowX: "hidden",
    overflowY: "auto",
    opacity: 0.5,
    pointerEvents: "none",
  },
  settingsSaveButton: {
    height: "70px",
    backgroundColor: "#0033ab",
    width: "100%",
    bottom: 0,
  },
  loadingAppTitle: {
    margin: "0px 24px",
  },
  ...ApplyAllGroupsRootSetting(theme),
  ...genericUpdateButtton,
});

export const ApplyAllGroupsRootSetting = (theme) => ({
  rootSettings: {
    flexDirection: "column",
    height: "93%",
    overflowX: "hidden",
    overflowY: "auto",
  },
  changeGroupsListContainer: {
    marginTop: "6px",
    width: "100%",
    position: "relative",
  },
  GroupSearchBox: {
    margin: "0px 0px 10px 6px",
  },
  groupSuggestion: {
    listStyleType: "none",
    padding: "5px",
    "&:hover": {
      backgroundColor: "#DCDCDC",
    },
  },
  changeGroupRootSettings: {
    flexDirection: "column",
    height: (props) => props.customHeight || "70vh",
    overflowX: "hidden",
    overflowY: "auto",
  },
  loadingChangeGroup: {
    margin: "9px auto",
    fontSize: "14px",
  },
});

export const CustomCheckBoxStyles = {
  root: {
    color: "grey",
    "&$checked": {
      color: "#5a5757",
    },
  },
  checked: {},
};

export const RolesMenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      minWidth: 200,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};

export const OptionMenuEditEmailDialogStyles = {
  root: {
    width: "400px",
  },
  editemailtitle: {
    color: "#000000",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "12px",
  },
  editemaildisclaimer: {
    color: "#000000",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "12px",
    fontStyle: "italic",
  },
  errorText: {
    color: "#eb1616 !important",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "11px",
    lineHeight: "18px",
    marginLeft: "12px",
  },
  deleteDialogBackdrop: {
    background: "none",
  },
  editEmailSm: {
    textTransform: "none",
    fontSize: "1rem",
    color: "white",
    boxShadow: "none",
    padding: "0",
    lineHeight: "18px",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
  },
  editEmailSm: {
    textTransform: "none",
    fontSize: "1rem",
    color: "white",
    textDecoration: "underline",
    padding: "0",
    lineHeight: "18px",
    backgroundColor: "transparent",
    flexDirection: "row-reverse",
    boxShadow: "none",
    margin: "0px 5px",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),

    "&:hover": {
      color: "white",
      backgroundColor: "transparent",
    },
  },
};

export const AuditLogDetails = (theme) => ({
  auditDetails: {
    width: "100% !important",
    height: "59px !important",
    background: "#F7F7F7 !important",
    boxShadow: "0px 1px 1px #e6e6e6 !important",
    overflowY: "hidden !important",
    borderBottom: "1px solid #f0f0f0",

    "& .MuiInputLabel-root": {
      top: "3px !important",
      left: "-2px",
      color: "#000000 !important",
      weight: 400,
      fontSize: "10px !important",
      fontStyle: "Regular !important",
      fontWeight: "normal !important",
      lineHeight: "12px !important",
      fontFamily: ['Helvetica Neue', "Helvetica", "Arial", "sans-serif"].join(
        ","
      ),
    },
    "& .MuiOutlinedInput-root": {
      padding: "0px",
    },

    "& .MuiOutlinedInput-input": {
      left: "calc(50% - 316px/2)",
      color: "#000000 !important",
      order: 1,
      width: "316px",
      margin: "30px 11px",
      weight: 400,
      position: "static",
      flexGrow: 0,
      fontSize: "15px",
      alignSelf: "stretch",
      fontStyle: "Regular",
      fontFamily: ['Helvetica Neue', "Helvetica", "Arial", "sans-serif"].join(
        ","
      ),
      lineHeight: "16px",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "0px !important",
    },
  },
  groupInfoHeader: {
    top: "calc(50% - 16px/2 - 231px)",
    color: "#000000",
    fontSize: "15px",
    background: "#f0f0f0",
    textAlign: "center",
    fontFamily: ['Helvetica Neue', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontWeight: "400 !important",
    lineHeight: "41px",
  },
  auditGroupDetailsRow: {
    flex: 1,
    display: "flex",
    minHeight: "15% !important",
    alignItems: "center",
    borderRight: "1px solid #F0F0F0",
  },
  groupDetailLabel: {
    top: "7px",
    color: " #0033AB",
    right: "206px",
    fontSize: "15px",
    fontStyle: "normal",
    fontFamily:  ['Helvetica Neue', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontWeight: 400,
    lineHeight: "25px",
    marginLeft: "10px",
  },

  groupLaunchAnchor: {
    color: "#0033AB",
    textDecoration: "none",
  },

  launchIcon: {
    height: "0.7em",
  },

  breadcrumbSpan: {
    margin: "0px !important",

    "& .MuiTypography-colorTextSecondary": {
      margin: "0px 0px 5px 19px",
    },
    "& .MuiBreadcrumbs-ol": {
      alignItems: "end",
    },

    "& .MuiBreadcrumbs-separator" :{
      margin: "0px 2px 0px 2px !important"
  },

    "& .MuiTypography-body1": {
      color: "#000000",
      fontSize: "0.8rem !important",
      marginTop: "0px",
      fontFamily:  ['Helvetica Neue', "Helvetica", "Arial", "sans-serif"].join(
        ","
      ),
      lineHeight: 1.5,
    },
  },

  deleteBreadcrumbSpan: {
    margin: "0px !important",

    "& .MuiTypography-colorTextSecondary": {
      margin: "0px 0px 5px 0px",
    },
    "& .MuiBreadcrumbs-ol": {
      alignItems: "end",
    },

    "& .MuiBreadcrumbs-separator" :{
      margin: "0px 2px 0px 2px !important"
  },

    "& .MuiTypography-body1": {
      color: "#000000",
      fontSize: "0.8rem !important",
      marginTop: "0px",
      fontFamily:  ['Helvetica Neue', "Helvetica", "Arial", "sans-serif"].join(
        ","
      ),
      lineHeight: 1.5,
    },
  },
});

export const TemplateTabStyles = (theme) => ({
  groupNameTextField: {
    maxWidth:"20% !important",
    marginTop:"-22px !important"
  },
  inputRoot: {
    color: "#000000",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "1rem",
    lineHeight: "15px",
    marginTop:"23px !important",
    marginLeft:"5px !important",
    padding:"0px !important"
  },
  labelRoot: {
    color: "#000000",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "11px",
    lineHeight: "18px",
    transform: "none",
    display:"none"
  },
  labelFocused: {
    color: "#000000 !important",
    fontFamily: ['"Helvetica Neue"', "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "11px",
    lineHeight: "18px",
    transform: "none",
    marginBottom: "3px",
  },
});