import React from 'react';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import axiosInstance from '../../Shared/Interceptor/interceptor';
import appConfig from '../../../Environment/environments';
import { ReactComponent as NoUser } from '../../../Assests/Images/NoUser.svg';
import DialogBox from "../OptionsMenu/dialog";
import {
  MOVEUSERTO, USERACTIVE, GROUPUSERAPI, GROUPSWAPUSERAPI, APPUSERACTIVE, APPUSERAPI, DELETETEXT, LOADINGTEXT,
  NOADMINDATA, NOUSERTEXT, APPUSERSTATUS, USERTIMEOUTERROR, USERSWAPSUCCESS, ERRORMSG
} from '../../../Common/Constants/constants';
import { encryptData } from '../../../Common/Utilities/utility';
import RightDrawer from '../../Shared/RightDrawer/RightDrawer';
import ChangeGroup from '../../Groups/changeGroup/changeGroup';
import { getSortedData } from '../../../Common/Utilities/utility'
import ToastMessages from '../../Shared/Toast/ToastMessages';
import Users from '../../../Common/Services/Users';

class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changedialogWidth: { width: 600 },
      filtered: [],
      id: "",
      right: false,
      groupGrid: false,
      userId: 0,
      groupId: 0,
      statusMessage: false,
      message: '',
      toastVPosition: 'top',
      toastHPosition: 'center',
      messageVariant: '',
      swapAdminLabel: ''
    }
    this.onClickRemoveItem = this.onClickRemoveItem.bind(this);
    this.onhandleChangeActive = this.onhandleChangeActive.bind(this);
    this.onClickMoveItem = this.onClickMoveItem.bind(this);
    this.onClickStatusItem = this.onClickStatusItem.bind(this);
    this.onClickTimeout = this.onClickTimeout.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
  }

  componentDidMount() {
    this.setState({
      filtered: this.props.items,
      groupId: this.props.groupId,
      groupGrid: (this.props.type === 'group') ? true : false
    });
  }

  static getDerivedStateFromProps(props, state) {
    return { ...state, filtered: props.items };
  }

  toggleDrawer = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
  }

  handleToast(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      statusMessage: false
    });
  }

  onhandleChangeActive(entry) {
    let currentList = this.props.items;
    let newList = currentList.map((item) => {
      let result = (this.props.type === 'group') ? (entry.groupUserId === item.groupUserId) : (entry.appUserId == item.appUserId);
      if (result) {
        item.isActive = !item.isActive;
        let URL = (this.props.type === 'app') ? (APPUSERACTIVE + encryptData(item.appUserId).toString()) : (USERACTIVE + encryptData(item.groupUserId));
        axiosInstance.put(appConfig.api.development + URL + "&userStatus=" + encryptData(item.isActive), {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        }).then(() => {
        });
      }
      return item;
    });

    this.setState({ filtered: newList });
    this.props.onContentChange && this.props.onContentChange();
  }

  onClickStatusItem(entry) {
    let currentList = this.props.items;
    let newList = currentList.map((item) => {
      let result = (entry.userId === item.userId)
      if (result) {
        item.status = 0;
        axiosInstance.put(appConfig.api.development + APPUSERSTATUS + encryptData(entry.userId), {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        })
      }
      return item;
    });
    this.setState({ filtered: newList });
    this.props.onContentChange && this.props.onContentChange();
  }

  onClickTimeout(entry) {
    let currentList = this.props.items;
    let newList = currentList.map((item) => {
      let result = (this.props.type === 'group') ? (entry.groupUserId === item.groupUserId) : (entry.appUserId == item.appUserId);
      if (result) {
        item.isTimeoutOff = !item.isTimeoutOff;
        let URL = (this.props.type === 'app') ? (APPUSERACTIVE + encryptData(item.appUserId).toString()) : (USERACTIVE + encryptData(item.groupUserId));
        axiosInstance.put(appConfig.api.development + URL + "&action=1" + "&userStatus=" + encryptData(item.isTimeoutOff), {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        }).catch((error) => {
          item.isTimeoutOff = !item.isTimeoutOff;
          let errorMessage = '';
          if (error.response.data.message.indexOf("timeout") > -1) {
            errorMessage = USERTIMEOUTERROR;
          }
          this.setState({
            statusMessage: true,
            message: errorMessage,
            messageVariant: "error"
          });
        });
      }
      return item;
    });
    this.setState({ filtered: newList });
    this.props.onContentChange && this.props.onContentChange();
  }

  onClickRemoveItem(entry, index) {
    this.setState({
      opennow: true,
      id: (this.props.type === 'group') ? entry.groupUserId : entry.appUserId
    });
  }
  onClickMoveItem(entry) {
    this.setState({
      right: true,
      userId: entry.userId,
      id: (this.props.type === 'group') ? entry.groupUserId : entry.appUserId
    });
    this.toggleDrawer('right', true);
  }

  onCloseEvent = (props) => {
    this.setState({ opennow: false })
  }

  onCloseParentPopUp = () => {
    this.setState({
      right: false
    });
  }
  onCloseSaveClick = () => {
    let currentList = this.props.items;
    let mylist;
    currentList.map((item, itemIndex) => {
      let result = (this.props.type === 'group') ? (this.state.id === item.groupUserId) : (this.state.id == item.appUserId);
      if (result) {
        mylist = itemIndex
        return item;
      }
    });
    currentList.splice(mylist, 1)
    this.setState({ filtered: currentList });
    if (this.props.gridType === 0) {
      this.props.onDeleteAdmin && this.props.onDeleteAdmin(currentList.length);
    }
    this.props.onContentChange && this.props.onContentChange();
  }

  handleCloseDialogue = () => {
    let currentList = this.props.items;
    let mylist
    this.setState({ opennow: false });
    currentList.map((item, itemIndex) => {
      let result = (this.props.type === 'group') ? (this.state.id === item.groupUserId) : (this.state.id == item.appUserId);
      if (result) {
        let URL = (this.props.type === 'app') ? (APPUSERAPI + encryptData(item.appUserId).toString()) : (GROUPUSERAPI + encryptData(item.groupUserId).toString());
        axiosInstance.delete(appConfig.api.development + URL, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          }
        })
        mylist = itemIndex
        return item;
      }
    });
    currentList.splice(mylist, 1)
    this.setState({ filtered: currentList });
    if (this.props.gridType === 0) {
      this.props.onDeleteAdmin && this.props.onDeleteAdmin(currentList.length);
    }
    this.props.onContentChange && this.props.onContentChange();
  };

  onClickSwapUser = (entry) => {
    let URL = (entry.type === 'app') ? (APPUSERAPI + encryptData(entry.appUserId).toString()) : (GROUPSWAPUSERAPI + encryptData(entry.groupUserId).toString());
    axiosInstance.put(appConfig.api.development + URL, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    }).then(res => {
      if (res) {
        this.setState({
          statusMessage: true,
          message: USERSWAPSUCCESS,
          messageVariant: "success"
        });

        // Reload admins and users grid
        this.props.reloadAllUsers && this.props.reloadAllUsers(this.state.groupId);
        this.props.onContentChange && this.props.onContentChange();

        //Reload if user swapped user or admin
        var userInstance = new Users();
        if (entry.email == userInstance.getUserEmail())
          window.location.reload();
      }
    }).catch((error) => {
      let errorMessage = ERRORMSG;
      if (error.response.data != undefined && error.response.data.message.indexOf("Maximum") > -1) {
        errorMessage = error.response.data.message;
      }
      this.setState({
        statusMessage: true,
        message: errorMessage,
        messageVariant: "error"
      });
    });
  }

  sortData = (sort) => {
    const sortedData = getSortedData(this.state.filtered, sort)
    this.setState({ filtered: sortedData })
  }

  render() {
    return (
      <div className="App">
        <div className="table">
          <TableHeader gridType={this.props.gridType} isTimeout={this.props.isTimeout} type={this.props.type} sortData={this.sortData} />
          {
            (this.state.filtered.length != 0) ? this.state.filtered.map((item, idx) =>
              <TableBody
                key={idx}
                {...item}
                onClickRemoveItem={this.onClickRemoveItem}
                onhandleChangeActive={this.onhandleChangeActive}
                onClickMoveItem={this.onClickMoveItem}
                onClickStatusItem={this.onClickStatusItem}
                onClickSwapUser={this.onClickSwapUser}
                onClickTimeout={this.onClickTimeout}
                type={this.props.type}
                gridType={this.props.gridType}
                isTimeout={this.props.isTimeout}
                nextTimeout={this.props.nextTimeout}
                adminCount={this.props.adminCount}
                maxAdminCount={this.props.maxAdminCount}
                buttonDisabled={this.props.buttonDisabled}
              />
            ) : ((this.state.filtered.length == 0 && this.props.initialRender == false) ?
              <div className="noUsersInfo">
                <p className="noUsersContent">{LOADINGTEXT}</p>
              </div> :
              <div className="noUsersInfo">
                <span className="noUsersIcon">
                  <NoUser />
                </span>
                <p className="noUsersContent">{(this.props.gridType === 0) ? NOADMINDATA : NOUSERTEXT}</p>
              </div>)
          }
        </div>
        <ToastMessages statusMessage={this.state.statusMessage}
          message={this.state.message}
          variant={this.state.messageVariant}
          toastHPosition={this.state.toastHPosition}
          toastVPosition={this.state.toastVPosition}
          close={this.handleToast.bind(this)} />
        <DialogBox dialogContent={this.props.dialogContent} dialogHead={this.props.dialogHead} dialogButtonText={DELETETEXT} opennow={this.state.opennow} onCloseClick={this.handleCloseDialogue} onCloseEvent={this.onCloseEvent} />
        <RightDrawer customProps={this.state.changedialogWidth} open={this.state.right}>
          <ChangeGroup
            callApiOnSave
            headerLabel={MOVEUSERTO}
            saveType="moveUser"
            gridType={this.props.gridType}
            userId={this.state.userId}
            GroupId={this.state.groupId}
            onSave={this.onCloseSaveClick}
            onClose={this.onCloseParentPopUp} />
        </RightDrawer>
      </div>
    );
  }
}

export default Table;