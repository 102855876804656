import React from 'react';
import Typography from '@material-ui/core/Typography';
import { NODEMIDDLEWAREINSTALL, NODEDECLARATION, NODEEXAMPLE } from '../../../../Common/Constants/constants';
import CopySnippet from '../CopySnippet/CopySnippet';
import useDeveloperTabStyles from './DeveloperTabStyles';

export default function NodeTab() {
    const { instructions, refLink } = useDeveloperTabStyles();
    return (
        <div className={instructions}>
            <Typography>Auth-Node-Middleware library is a middleware any Node developer can use in order to verify the token validity provided by  Apps Hub. Below are the steps to be followed to integrate the package with node application.</Typography> <br />
            <Typography><b>Step 1: </b>install node middleware package as a dependency in your node application.</Typography>
            <CopySnippet copyCode={NODEMIDDLEWAREINSTALL} />
            <Typography><b>Step 2: </b>In your server.js file declare this library as below:</Typography>
            <CopySnippet copyCode={NODEDECLARATION} />
            <Typography><b>Step 3: </b>When you want to protect your route, just insert the withAuth const as a middleware in you app.use function. The middleware function ValidateToken() has an optional param which refers to B2C environment. If Node application doesn't pass any value like 'DEV' or 'QA', by default it refers to Production.</Typography>
            <CopySnippet copyCode={NODEEXAMPLE} />
            <div>
                For further details refer the link -
                <span>
                    <a className={refLink} target="_blank" href="">Node Middleware</a>
                     {/* Provide Git Hub path for Node Auth Module in href */}
                </span>
            </div>
        </div>
    );
}
