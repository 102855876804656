import deImg from '../../Assests/Images/lang/NL.png';
import enImg from '../../Assests/Images/lang/EN-US.png';
import frImg from '../../Assests/Images/lang/FR.png';
import geImg from '../../Assests/Images/lang/DE.png';
import itImg from '../../Assests/Images/lang/IT.png';
import ruImg from '../../Assests/Images/lang/RU.png';
import plImg from '../../Assests/Images/lang/pl-PL.png';
import trImg from '../../Assests/Images/lang/tr-TR.png';
import esImg from '../../Assests/Images/lang/ES.png';
import ptImg from '../../Assests/Images/lang/PT.png';
import svImg from '../../Assests/Images/lang/SV.png';

export const LangOpt = [
    {
        name: "Dutch",
        lang: "nl",
        fullLangCode: "nl-NL",
        img: deImg
    },
    {
        name: "English",
        lang: "en",
        fullLangCode: "en-US",
        img: enImg
    },
    {
        name: "French",
        lang: "fr",
        fullLangCode: "fr-FR",
        img: frImg
    },
    {
        name: "German",
        lang: "de",
        fullLangCode: "de-DE",
        img: geImg
    },
    {
        name: "Italian",
        lang: "it",
        fullLangCode: "it-IT",
        img: itImg
    },
    {
        name: "Polish",
        lang: "pl",
        fullLangCode: "pl-PL",
        img: plImg
    },
    {
        name: "Portuguese",
        lang: "pt",
        fullLangCode: "pt-PT",
        img: ptImg
    },
    {
        name: "Russian",
        lang: "ru",
        fullLangCode: "ru-RU",
        img: ruImg
    },
    {
        name: "Spanish",
        lang: "es",
        fullLangCode: "es-ES",
        img: esImg
    },
    {
        name: "Swedish",
        lang: "sv",
        fullLangCode: "sv-SE",
        img: svImg
    },
    {
        name: "Turkish",
        lang: "tr",
        fullLangCode: "tr-TR",
        img: trImg
    }
];
