import { makeStyles } from '@material-ui/core/styles';
const tabStyles = makeStyles(theme => ({
    tabSelected: {},
    manageUsersHeader: {
        display: "flex",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column"
        }
    },
    manageUsersInviteButton: {
        right: "0px",
        position: "unset",
        margin: "0px 23px"
    },
    tabsRoot: {
        borderBottom: 'none',
        width: '100%',
        display: 'inline-block',
        "max-width": "70%"
    },
    maxAdminCountMsg: {
        "margin-top": "2%",
        color: "gray"
    },
    tabsIndicator: {
        background: 'none',
    },
    tabRoot: {
        textTransform: 'capitalize',
        fontWeight: 'normal',
        marginRight: 0,
        color: '#0033AB',
        fontFamily: [
            '"Helvetica Neue"',
            'Helvetica',
            'Arial',
            'sans-serif'
        ].join(','),
        fontSize: '1rem',
        lineHeight: '18px',
        width: "max-content",
        minWidth: 0,
        paddingLeft: "24px",

        '&:hover': {
            fontWeight: 'normal',
            marginRight: 0,
            color: '#0033AB',
            fontFamily: [
                '"Helvetica Neue"',
                'Helvetica',
                'Arial',
                'sans-serif'
            ].join(','),
            fontSize: '1rem',
            lineHeight: '18px'
        },

        '&:focus': {
            color: '#000000',
            fontFamily: [
                '"Helvetica Neue"',
                'Helvetica',
                'Arial',
                'sans-serif'
            ].join(','),
            fontSize: '16px',
            lineHeight: '22px',
            fontWeight: 'normal'
        },

        '&$tabSelected': {
            color: '#000000',
            fontFamily: [
                '"Helvetica Neue"',
                'Helvetica',
                'Arial',
                'sans-serif'
            ].join(','),
            fontSize: '16px',
            lineHeight: '22px',
            fontWeight: 'normal',
        },
    },
    userButtonRoot: {
        [theme.breakpoints.down('sm')]: {
            position: "unset",
            right: "0px"
        },
        opacity: '1',
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: '1rem',
        color: '#fff',
        lineHeight: '18px',
        backgroundColor: '#0033ab',
        borderRadius: '3px',
        minWidth: 72,
        minHeight: '0',
        height: '35px',
        margin: '8px 5px',
        fontFamily: [
            '"Helvetica Neue"',
            'Helvetica',
            'Arial',
            'sans-serif'
        ].join(','),
        fontWeight: '400',
        position: 'absolute',
        right: '20px',

        '&:hover': {
            borderRadius: '3px',
            fontWeight: '400',
            textDecoration: 'underline',
            boxShadow: 'none',
            backgroundColor: '#0033ab',
            color: '#ffffff'
        },

        '&:focus': {
            borderRadius: '3px',
            fontWeight: '400',
            textDecoration: 'underline',
            boxShadow: 'none',
            backgroundColor: '#0033ab',
            color: '#ffffff'
        },

        '&:active': {
            color: '#fff',
            backgroundColor: '#0033ab',
            borderColor: '#0033ab',
            borderRadius: '3px',
            fontWeight: '400',
        },
    }
}), { withTheme: true });

export default tabStyles;