import React, {useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import useDeveloperTabStyles from './DeveloperTabStyles';
import Button from '@material-ui/core/Button';
import CopySnippet from '../CopySnippet/CopySnippet';
import { APIKEY,CREATEAPIKEY,GENERATEAPIKEY,APIKEYDESC } from '../../../../Common/Constants/constants';
import appConfig from '../../../../Environment/environments';
import axiosInstance from '../../../Shared/Interceptor/interceptor';
import { encryptData } from '../../../../Common/Utilities/utility';

export default function ApiKeyTab(props){
    const { instructions,developerDesc,step1,normalBtn } = useDeveloperTabStyles();
    const [apiKey, setApiKey] = React.useState("");

    useEffect(() => {
    setApiKey(props.apiKey)
    },[]);

    const generateApiKey=()=>{
        let request = axiosInstance.post(`${appConfig.api.development}${CREATEAPIKEY}${encryptData(props.Id)}`,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      });
      request.then(res => {
        setApiKey(res.data);
    })
}
    return(
        <div className={instructions}>
             <Typography>{APIKEYDESC}</Typography> <br />
             <div className={developerDesc, step1}>
            {apiKey &&
            <span>
            <CopySnippet copyCode={APIKEY.replace("@apiKey",apiKey)} />
            </span>
            }
             <span >
                <Button className={`${normalBtn}`} onClick={generateApiKey} >
                {GENERATEAPIKEY}
            </Button>
            </span>
          </div>

        </div>
    );

}
