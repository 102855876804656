import React, { memo } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import tabStyles from "./CustomTabStyles";
import Typography from '@material-ui/core/Typography';
import { ADDADMIN, MAXADMINCOUNTMESSAGE } from '../../../Common/Constants/constants';

const CustomTabs = (props) => {
    const { buttonDisabled = false, variant = "scrollable", children, buttonLabel, onButtonClick, showButton, handleChange, tabLabels, defaultSelected, maxAdminCount, maxCount } = props;
    const { tabSelected, manageUsersInviteButton, userButtonRoot, tabsRoot, tabRoot, tabsIndicator, maxAdminCountMsg } = tabStyles();
    const createTabLabels = () => tabLabels.map(label =>
        <Tab
            disableRipple
            classes={{ root: tabRoot, selected: tabSelected }}
            label={label}
        />
    )
    return (
        <>
            <div className="ManageUsersHeader">
                {!!tabLabels &&
                    <Tabs
                        value={defaultSelected}
                        onChange={handleChange}
                        variant={variant}
                        scrollButtons="off"
                        classes={{ root: tabsRoot, indicator: tabsIndicator }}
                    >
                        {createTabLabels()}
                    </Tabs>
                }
                {showButton &&
                    <Button disabled={buttonDisabled} variant="contained" id={manageUsersInviteButton} disableRipple classes={{ root: userButtonRoot }} onClick={onButtonClick} >
                        {buttonLabel}
                    </Button>
                }
                {maxAdminCount && buttonLabel === ADDADMIN &&
                    <Typography classes={{ root: maxAdminCountMsg }}>{MAXADMINCOUNTMESSAGE} {maxCount}/{maxCount}</Typography>
                }
            </div>
            {children}
        </>)
}

export default memo(CustomTabs);