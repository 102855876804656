import { makeStyles } from '@material-ui/core/styles';
export const RightDrawerStyles = makeStyles((theme) => ({
    list: {
      width: props => props.width || 330,
      [theme.breakpoints.down('sm')]: {
        width: '100% !important',
      }
    },
    fullscreenList: {
      width: '99vw',
      [theme.breakpoints.down('sm')]: {
        width: '100% !important',
      }
    },
    addGroupDrawerPaper: {
        overflow: 'hidden',
        right: props => props.right || "0px !important",
        [theme.breakpoints.down('sm')]: {
          width: '100% !important',
          right: "0px !important"
        }
    },
    fullScreenGroupDrawerPaper: {
        overflow: 'auto',
        [theme.breakpoints.down('sm')]: {
          width: '100% !important',
        }
    }
  }));