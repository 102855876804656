import React, { useState, useEffect } from "react";
import { PERMISSIONSFETCHFAIL, ADDPERMISSIONSTEXT, PERMISSIONSTAB } from '../../../../Common/Constants/constants';
import List from "@material-ui/core/List";
import PermissionTree from "./PermissionTree";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import permissionStyle from './permissionStyle';
import { getPermission } from './permissionUtil';
import UpsertPermission from './upsertPermission';

const PermissionTab = (props) => {
  const { Id: appId, appName } = props;
  const [permission, setPermission] = useState(null);
  const [error, seterror] = useState(null);
  const [addAction, setAddAction] = useState(false);
  const [open, setOpen] = React.useState(false);
  const { permissiontab, appPermissionText, appPermissionButtonContainer,
    permissionHeading, addRootPermission, flexDiv, rootContainer } = permissionStyle();

  useEffect(() => {
    getPermissions();
  }, []);

  const getPermissions = async () => {
    try {
      if (appId) {
        const permissions = await getPermission(appId);
        setPermission(permissions);
      }
    } catch (error) {
      seterror(PERMISSIONSFETCHFAIL)
      setOpen(true);
    }
  }

  const onOpenAddEvent = () => {
    setAddAction(true);
  }

  const onCloseAddEvent = () => {
    setAddAction(false);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div className={flexDiv}>
      <div className={permissionHeading}>
        <div className={appPermissionText}>
          <strong>{appName}</strong>
          <span>{PERMISSIONSTAB}</span>
        </div>
        <div className={appPermissionButtonContainer}>
          <Button color="primary" variant="outlined" autoFocus className={addRootPermission} onClick={onOpenAddEvent}>
            {ADDPERMISSIONSTEXT}
          </Button>
        </div>
      </div>
      <div className={permissiontab}>
        <div className={rootContainer}>
          {permission ?
            <List disablePadding dense>
              {permission.map((permissionTree) => (
                <PermissionTree
                  key={permissionTree.settingId}
                  tree={permissionTree}
                  getPermissions={getPermissions}
                  appId={appId}
                />
              ))}
            </List> : <LinearProgress />}
          {addAction && <UpsertPermission isRootPermission={true} getPermissions={getPermissions} appId={appId} onCloseEvent={onCloseAddEvent} />}
        </div>
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </div>);
}

export function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default PermissionTab;
