import Users from '../../../Common/Services/Users';
import { USERDETAIL, EDITBYID } from '../../../Common/Constants/constants';
import appConfig from '../../../Environment/environments';
import axiosInstance from '../../Shared/Interceptor/interceptor';
import { encryptData } from '../../../Common/Utilities/utility';

const getLoggedInUserDetails = async (callback) => {
    let userInstance = new Users();
    let emailid = userInstance.getUserEmail();
    await axiosInstance.get(`${appConfig.api.development}${USERDETAIL}${encryptData(emailid).toString()}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    })
    .then(res => {
        let userdata = res.data;
        callback(userdata.isManager,userdata.firstname,userdata.lastname);
    })
    .catch((error) => console.log(error))
}

const getGroupDetailsByID = async (groupId, callback) => {
    await axiosInstance.get(appConfig.api.development + EDITBYID + encryptData(groupId).toString())
        .then(response => {
            const { label, parentGroupName, parentGroupID, isEditable, isTimeout, timeoutFreq, nextTimeoutDate, isActive, isParentActive, isDisableEdit } = response.data;
            callback(label, parentGroupName, parentGroupID, isEditable, isTimeout, timeoutFreq, nextTimeoutDate, isActive, isParentActive, isDisableEdit);
        })
        .catch((error) => console.log(error))
}

export { getLoggedInUserDetails, getGroupDetailsByID };