import React from 'react';
import Typography from '@material-ui/core/Typography';
import { AUTHMODULEINSTALL, APPCONFIG, ANGULARWRAPPER, ANGULARTEMPLATE, LOGOUTCODE, GETPERMISSIONSCODE, GETUSERSBYPERMISSIONCODE, GETUSERSBYIDCODE, GETGROUPCODE, NPMRCFILEPATH } from '../../../../Common/Constants/constants';
import CopySnippet from '../CopySnippet/CopySnippet';
import useDeveloperTabStyles from './DeveloperTabStyles';
import appConfig from '../../../../Environment/environments';

export default function AngularTab(props) {
    const { appUrl } = props;
    const { instructions, refLink, developerDesc, step1 } = useDeveloperTabStyles();
    return (
        <div className={instructions}>
            <Typography>Follow below steps to integrate the auth-module with any angular application.</Typography> <br />
            <Typography className={step1}><b>Step 1: </b>To download internal packages, it requires .npmrc file which has required information. Download the .npmrc file <a href={NPMRCFILEPATH} target="_blank">here </a>and place it at "C:\Users\YourUserName" path.</Typography>
            <Typography><b>Step 2: </b>Install auth-module package as a dependency.</Typography>
            <CopySnippet copyCode={AUTHMODULEINSTALL.replace("authVersion", appConfig.api.authVersion) } />
            <Typography><b>Step 3: </b>Import the login method from '@CCR/auth-module' in the root component of the application(App Component) and call it by passing the appConfig object as shown in the below code snippet.</Typography> <br />
            <Typography className={developerDesc}><b>Note: </b>
                Passing of appConfig object is not a mandatory thing.
                If we don't pass the appConfig then by default it will be pointed to production environment.
                Authentication works out of the box when your app is hosted on the same endpoint that is listed on
                appshub.com URL field. For other environments or custom needs, especially when serving your
                app via localhost, you will need to pass appConfig object to login method. appConfig object needs to
                contain uiUrl, apiUrl and postLogoutRedirectUri. The uiUrl of the application is your frontend endpoint listed
                in appshub.com. The apiUrl of the application is your beckend endpoint of CCRappshub.com. The postLogoutRedirectUri is the redirect URL after login or logout.
                It has to match the Dev URL field on CCRappshub.com and your Angular App local url.
            </Typography>
            <CopySnippet copyCode={ANGULARWRAPPER} />
            <CopySnippet copyCode={APPCONFIG.replace("appUrl", appUrl).replace("@apiUrl", appConfig.api.loginConfig.apiUrl)} />
            <Typography><b>Step 4: </b>Based on the return value from the login method, conditionally render the app component as shown below.</Typography>
            <CopySnippet copyCode={ANGULARTEMPLATE} />
            <Typography><b>Other functions which can be used are as follows:</b></Typography> <br />
            <Typography><b>1) Logout Code: </b>To Logout of Application use below code where you want to write your logout logic:</Typography>
            <CopySnippet copyCode={LOGOUTCODE} />
            <Typography><b>2) Fetch App Permissions code: </b>To fetch permissions and user info related to Application from UM use below code:</Typography>
            <CopySnippet copyCode={GETPERMISSIONSCODE} />
            <Typography><b>3) Fetch users based on permissions code: </b>To fetch users based on permissions related to Application from UM use below code:</Typography>
            <CopySnippet copyCode={GETUSERSBYPERMISSIONCODE} />
            <Typography><b>4) Fetch user details based on user id: </b>To fetch user details based on userid use below code:</Typography>
            <CopySnippet copyCode={GETUSERSBYIDCODE} />
            <Typography><b>5) Fetch group details based on group id: </b>To fetch group details based on groupid use below code:</Typography>
            <CopySnippet copyCode={GETGROUPCODE} />
            <Typography>
                Details related to parameters passed to above methods are as below:
                <p>1)apiUrl: API url used to fetch permissions related to app from UM(defaults to {appConfig.api.development} if not passed).</p>
                <p>2)applicationUrl: Application url to use in fetchAppPermissions call to UM (defaults to window.location.origin if not passed).</p>
                <p>3)successCallback: function used to invoke on successfull execution of api call.</p>
                <p>4)failureCallback: function used to invoke on any error in api call.</p>
                <p>5)reqObj: The request payload used in the FetchUsersBasedOnPermissions API call.</p>
                <p>6)userid: User Id of the user.</p>
                <p>7)groupid: group Id of the Group.</p>
                <div>
                    For further details refer the link -
                    <span>
                        <a className={refLink} target="_blank" href="https://scegithub.apps.carrier.com/SCE-Diapason/CCR-Auth-Module">Auth Module</a> 
                    </span>
                </div>
            </Typography>
        </div>
    );
}
