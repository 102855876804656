import React, { memo } from 'react';
import './AdminUsersGrid.css';
import Table from '../Table/Table';
import { DELETEADMIN, DELETEUSER, DELETEADMINDIALOG, DELETEUSERDIALOG } from '../../../Common/Constants/constants';

const AdminUsersGrid = (props) => {
  const { onContentChange, adminData, reloadAllUsers, maxAdminCount, adminCount,
    groupId, initialRender, gridType, type, isTimeout, nextTimeout, onDeleteAdmin, buttonDisabled } = props;

  const dialogHead = (gridType === 0) ? DELETEADMIN : DELETEUSER;
  const dialogContent = (gridType === 0) ? DELETEADMINDIALOG : DELETEUSERDIALOG;
  return (
    <div className="App">
      <Table
        onContentChange={onContentChange}
        onDeleteAdmin={onDeleteAdmin}
        items={adminData}
        adminCount={adminCount}
        maxAdminCount={maxAdminCount}
        groupId={groupId}
        reloadAllUsers={reloadAllUsers}
        initialRender={initialRender}
        gridType={gridType}
        type={type}
        dialogContent={dialogContent}
        dialogHead={dialogHead}
        isTimeout={isTimeout}
        nextTimeout={nextTimeout}
        buttonDisabled={buttonDisabled} />
    </div>
  );
}

export default memo(AdminUsersGrid);
