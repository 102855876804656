import { makeStyles } from "@material-ui/core/styles";

const TemplateTabStyles = makeStyles((theme) => ({
    mainDiv : {
        margin :'16px',
        height: "100vh"
    },
    leftDiv : {
        width: "50%",
        float: "left"
    },
    step1 : {
        paddingTop :'18px'
    },
    step2 : {
        paddingTop :'20px',
        paddingBottom :'7px'
    },
    step3 : {
        paddingBottom :'10px'
    },
    rootsignIn: {
        height: "100%",
        width: "100%",
        "background-size": "cover",
        "background-image": "url(https://gstqa2ywpckn3rhgbq.blob.core.windows.net/b2c/NG-ECAT/images/bg.png)",
        "background-repeat": "no-repeat",
        "background-attachment": "fixed",
        "background-color": "#f0f0f0",
        "font-family": "Helvetica Neue, Helvetica, Arial, sans-serif"
    },
    developerDesc: { 
        textAlign: 'justify',
    },
    buttonstyle: {
        margin: "4px",
        width: "220px",
    },
    brand: {
        width: "30%",
        border: "none",
        "background-color": "#0033AB",
        "padding-left": "0.5rem",
        "min-width": "375px",
        "box-sizing": "border-box"
    },
    "navbarbrandlogo": {
        height: "40px",
        margin: "10px"
    },
    button: {
        "margin-bottom": "1rem",
        border: "1px solid #0033AB",
        "border-radius": "3px",
        "color": "#003DCC",
        "font-size": "12px",
        "line-height": "12px",
        "text-align": "center",
        "background": "#FFF",
        padding: "0",
        "text-transform": "none",
        height: "32px",
        padding: "10px",
        width: "100%",
    },
    card: {
        marginLeft:"16px", 
        width: "160px", 
        display: "inline-block"
    },
    cardbody: {
        flex: "1 1 auto",
        "-ms-flex": "1 1 auto",
        "-webkit-box-flex": "1",
        padding: "0.25rem",
        "text-align": "center"
    },
    cardContent:{
        paddingBottom:"8px !important", 
        padding:"8px", 
        textAlign:"center",
        "border-top": "4px dotted #e3e2e2",
        "border-top-width": "1px",
        "margin-top": "1px"
    },
    cardContentLoad:{
        height: "72px",
        paddingTop:"32px !important",
        textAlign:"center"
   },
    logincompanylogo: {
        "margin-bottom": "5px",
        "text-align": "center"
    },
    form: {
        "margin-top": "0rem",
        "padding": "10px",
        display: "block",
        "text-align": "center",
        height: "100%"
    },
    formPanel: {
        "border-radius": "3px",
        "padding": "10px 20px 25px 20px"
    },
    buttons: {
        "margin-top": "1.5rem!important",
        float:"left",
        "padding-left" : "10px"
    },
    formFieldLabel: {
        "color": "#000000",
        "font-family": "Helvetica Neue, Helvetica, Arial, sans-serif",
        "font-size": "11.2px",
        "line-height": "16px",
        "text-align": "left",
        "margin-bottom": "10px",
        "margin-top": "5px",
    },
    divInput: {
        "margin-top": "-10px",
        width: "20%"
    },
    divNotes: {
        width: "100%",
        "padding-left" : "10px",
        float:"left"
    },
    formfield: {
        "width":"25%",
        "margin-left":"5px",
        "font-family": "Helvetica Neue, Helvetica, Arial, sans-serif;",
        color: "#000",
        "font-size": "12px",
        "line-height": "1em",
        // padding: "5px",
        "border-bottom": "1px solid #ced4da",
        "border-radius": "0",
        "box-shadow": "none",
        color: "#000000",
        "line-height": "18px",
        "-moz-box-sizing": "border-box",
        "box-sizing": "border-box",
        "padding-left": "5px",
        "border": "1px solid #a6a6a6",
        "overflow": "visible",
        "outline": "0",
        "outline-color": "initial",
        "outline-style": "initial",
        "outline-width": "0px",
        "& .MuiInputBase-input": {
            "font-size": "13px!important",
            "color": "#000000"
        }
    },
    cancelBtn: {
        color: "#0033ab",
        maxHeight: "28px",
        maxWidth: "102px",
        border: "1px solid #0033AB",
        "border-radius": "3px",
        fontSize: "1rem",
        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
        "margin-left": "14px",
        "&:hover": {
            "background-color": "#0033AB !important",
            color: "#FFF!important",
            "box-shadow": "0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15)",
            "-webkit-box-shadow": "0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15)",
            "outline": 0
        }
    },
    normalBtn: {
        maxHeight: "28px",
        minWidth: "102px",
        "background-color": "#0033AB !important",
        color: "#FFF!important",
        border: "1px solid #0033AB",
        "border-radius": "3px",
        fontSize: "1rem",
        "&:hover": {
            "box-shadow": "0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15)",
            "-webkit-box-shadow": "0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15)",
            "outline": 0
        },
        
    },
    account: {
        padding: "10px 20px 10px 20px"
    },
    select: {
        height: "30px",
        "border-bottom": "1px solid #ced4da",
        "color": "#000000",
        "font-family": "Helvetica Neue, Helvetica, Arial, sans-serif",
        "font-size": "12px",
        "line-height": "18px",
        "-webkit-box-shadow": "none",
        "outline": "0",
        "box-sizing": "border-box",
        "padding-left": "5px",
        "border": "1px solid #a6a6a6",
        "border-color": "#F0F0F0 #F0F0F0 #a6a6a6 #F0F0F0"
    },
    terms: {
        "padding-top": "12px",
        "text-align": "center"
    },
    termsDiv: {
        display: "flex",
        "flex-direction": "row",
        "column-gap": "5px"
    },
    validError: {
        "margin-top": "2px",
        "text-align": "left",
        color: "#f44336",
        "font-size": "12px"
    } ,
    appImageStyle:{
        "color":"#0000 !important",
        "width":"100px !important"
    },
    bgImageStyle:{
        "color":"#0000 !important",
        "width":"100px !important"
    },
    span_appImage : {
        "margin-left" :'7px'
    },
    span_bgImage : {
        "margin-left" :'7px'
    },
     span_AppClienId : {
        "margin-left" :'5px'
    },
    span_HelpText : {
        "margin-left" :'5px'
    },
    span_CodeSnippet : {
        "margin-top" :'5px'
    }
}));

export default TemplateTabStyles;
