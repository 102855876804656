import React, {memo} from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const tabConatinerStyles = makeStyles((theme) => ({
    tabContainer: { 
        padding: "0 24px"
    }
}));

const TabContainer = ({children}) => {
    const {tabContainer} = tabConatinerStyles();
    return (
        <Typography component="div" className={tabContainer}>
            {children}
        </Typography>
    );  
}
  
TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

export default memo(TabContainer);