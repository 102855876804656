import { LangOpt } from '../../Common/Utilities/languagesutils';
import appConfig from '../../Environment/environments';
import axiosInstance from '../../Components/Shared/Interceptor/interceptor';
import { LOCALE_SET_START, GETUSERLANGUAGE, SETUSERLANGUAGE, LOCALE_SET_FULFILLED, LOCALE_SET_FAILED } from '../../Common/Constants/constants';
import { encryptData } from '../../Common/Utilities/utility';

export const getLocale = (lang) => (dispatch) => {
    let selLang;
    LangOpt.forEach(opt => {
        if (opt.fullLangCode.toUpperCase() === lang.toUpperCase()) {
            selLang = opt.lang;
            return;
        }
    });
    dispatch({
        type: LOCALE_SET_FULFILLED,
        data: { lang: selLang, Language: lang }
    });
}

export const UserPerference = (opt) => (dispatch) => {
    dispatch({ type: LOCALE_SET_START });
    axiosInstance({
        url: `${appConfig.api.development}${SETUSERLANGUAGE}${encryptData(opt.fullLangCode).toString()}`,
        method: "PUT",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }).then(() => {
        dispatch({
            type: LOCALE_SET_FULFILLED,
            data: { lang: opt.lang }
        });
    }).catch(err => {
        dispatch({
            type: LOCALE_SET_FAILED,
            error: err
        });
    });
};

