import React from 'react';
import { getUserType, getIDToken } from '../Utilities/utility';

class Users extends React.Component {
  constructor() {
    super();
  }
  
  getUserName() {
    const idToken = getIDToken();
    if (idToken)
      return idToken.given_name;
  }

  getCloudUserName() {
    const idToken = getIDToken();
    if (idToken)
      return idToken.username;
  }

  getUserFullName() {
    const idToken = getIDToken();
    if (idToken)
      return idToken.given_name + ", " + idToken.family_name;
  }

  getUser() {
    return getIDToken();
  }

  getUserEmail() {
    const idToken = getIDToken();
    if (idToken)
      return idToken.emails[0];
  }

  getAddUser() {
    var modal = {};
    const currentUser = this.getUser();
    if (currentUser) {
      modal.usertype = getUserType();
      modal.upn = currentUser.emails[0];
      modal.firstname = currentUser.given_name;
      modal.lastname = currentUser.family_name;
      modal.emailAddress = currentUser.emails[0];
      modal.countryName = currentUser.country;
      modal.objectId = currentUser.oid;
      modal.desktopUserID = getUserType() == 2 ? currentUser.hvacusername : "";
      modal.isActive = true;
      modal.isManager = false
      return modal;
    }

  }
  render() {
    return (
      <div></div>
    )
  }
}
export default Users;
