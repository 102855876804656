import React from "react";
import { connect } from "react-redux";
import "axios-progress-bar/dist/nprogress.css";
import "../../Assests/Styles/Styles.scss";
import GroupService from "./Groups.services";
import BlackEdit from "../../Assests/Images/edit-black.svg";
import Globe from "../../Assests/Images/Globe.svg";
import User from "../../Assests/Images/user-demo.svg";
import Admin from "../../Assests/Images/Admin.svg";
import Group from "./Group";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import ToastMessages from "../Shared/Toast/ToastMessages";
import RightDrawer from "../Shared/RightDrawer/RightDrawer";
import AddGroup from "./addGroup/addGroup";
import EditGroup from "./edit-group/edit-group";
import SettingsDrawer from "../Shared/Settings/SettingsDrawer";
import { apiGet, apiPost } from "../Shared/Interceptor/apiService";
import {
  LOADINGTEXT,
  ADDAGROUP,
  GROUPSTEXT,
  NOGROUPSCREATED,
  NAME,
  GROUPSTOOLTIP,
  ADMINSTRATORSTOOLTIP,
  USERSTOOLTIP,
  EDITTOOLTIP,
  GROUPNAMES,
  GROUPSEARCH,
  ENTER,
  SEARCHERROR,
  FILTERS,
  FilterStyles,
  EDIT,
  FILTER_PERMISSIONS,
  DEFAULTVALUE,
} from "../../Common/Constants/constants";
import { intlShape, injectIntl } from "react-intl";
import ChangeGroup from "./changeGroup/changeGroup";
import { injectIntlTranslation } from "../../Common/Utilities/utility";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import "../Shared/SearchWithSuggestions/SearchWithSuggestions.scss";
import AddFilter from "./addFilter";
import UsersList from "./usersList";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import {
  setParentGroupFilter,
  setUsersSelection,
  setUserPermissions,
} from "../../redux/Actions/filterAction";
const styles = FilterStyles;

class Groups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Group: [],
      value: 1,
      statusMessage: false,
      message: "",
      toastVPosition: "top",
      toastHPosition: "center",
      openParallelDrawer: false,
      parentSelectionData: {},
      offset: "0px",
      right: false,
      fullScreen: false,
      openFilterDrawer: false,
      openUserListDrawer: false,
      openPermissionsDrawer: false,
      messageVariant: "",
      editGroupId: 0,
      loading: true,
      reloadGroups: false,
      searchText: "",
      showSuggestion: false,
      suggestions: [],
      filteredSuggestions: [],
      noMatch: false,
      searchPressed: false,
      filterSelectionData: {},
      filterPermissions: [],
      filterTooltipMsg: "",
      isFilter: false,
      clearFilter: false,
      filterCount: 0,
      permissionCount: 0,
      filterApplied: false,
      usersSaved: false,
      noFilteredData: false,
      selectedUsersList: [],
      selectedPermissions: [],
      selectedApps: [],
      permissionName: "",
    };
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.ToBeExpandedGroups = this.ToBeExpandedGroups.bind(this);
    this.expandedGroups =
      this.props.location != undefined && this.props.location.expandedGroups
        ? this.props.location.expandedGroups
        : [];
    this.permissionsList = [];
  }

  componentDidMount() {
    this.fetchGroups();
    if(this.state.filterCount===0)
    {
      this.props.setParentGroupFilter({});
      this.props.setUsersSelection([]);
      this.props.setUserPermissions([], []);
      this.setState(
        {
          filterSelectionData: {},
          selectedUsersList: [],
          selectedPermissions:[]
    })
  }
}

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.filterSelectionData.value !==
        this.state.filterSelectionData.value ||
      prevState.selectedUsersList !== this.state.selectedUsersList ||
      prevState.selectedPermissions !== this.state.selectedPermissions
    ) {
      this.filterCount();
    }
  }

  filterCount = () => {
    let totalPermissionsSelected = 0;
    for (let key in this.state.selectedPermissions) {
      totalPermissionsSelected++;
      const settingsChildren = this.state.selectedPermissions[key];
      if (settingsChildren.Settings && settingsChildren.Settings.length > 0) {
        totalPermissionsSelected += settingsChildren.Settings.length;
      }
    }
    const count = this.state.filterSelectionData.value
      ? 1 + this.state.selectedUsersList.length + totalPermissionsSelected
      : this.state.selectedUsersList.length + totalPermissionsSelected;
    this.setState({
      filterCount: count,
      permissionCount: totalPermissionsSelected,
    });
  };

  fetchGroups = () => {
    this.expandedGroups = [];
    GroupService.getAllGroups((result) => {
      if (result.length > 0) {
        this.setState({
          Group: result,
          loading: false,
          reloadGroups: false,
        });
      } else {
        window.location.reload();
      }
    });
  };

  ToBeExpandedGroups(shouldAdd, id) {
    let index = this.expandedGroups.indexOf(id);
    if (shouldAdd) {
      index <= -1 && this.expandedGroups.push(id);
    } else {
      index > -1 &&
        this.expandedGroups.splice(index, this.expandedGroups.length - index);
    }
  }
  DisplayRootGroup() {
    if (this.state.Group.length > 0) {
      return this.state.Group.map((ChildGroup, index) => (
        <Group
          TreeLevel={0}
          key={index}
          Group={ChildGroup}
          toBeExpandedGroups={this.ToBeExpandedGroups}
          expandedGroups={this.expandedGroups}
          setEditGroupId={this.setEditGroupId}
          searchPressed={this.state.searchPressed}
          searchText={this.state.searchText}
          clearFilter={this.state.clearFilter}
          filterApplied={this.state.filterApplied}
        />
      ));
    }
  }
  handleToast(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      statusMessage: false,
    });
  }

  deleteGroupHandler = (statusMessage, message, messageVariant) => {
    this.setState(
      {
        statusMessage: statusMessage,
        message: message,
        messageVariant: messageVariant,
        fullScreen: false,
        Group: [],
        loading: true,
        suggestions: [],
      },
      () => {
        this.resetData();
      }
    );
  };

  setEditGroupId = (groupId, showEditGroup = true, reloadGroups = false) => {
    let reloadProps = {};
    if (reloadGroups && this.expandedGroups.length > 0) {
      reloadProps = {
        Group: [],
        loading: true,
      };
    }
    this.setState(
      {
        suggestions: [],
        editGroupId: groupId,
        fullScreen: showEditGroup,
        right: false,
        ...reloadProps,
      },
      () => {
        this.resetData(reloadGroups);
      }
    );
  };

  resetData = async (reloadGroups = true) => {
    let userIdsList = [];
    if (this.props.usersList) {
      userIdsList = this.props.usersList.map((user) => user.userId);
    }
    if (
      this.state.filterSelectionData.value ||
      this.state.selectedUsersList.length > 0 ||
      this.state.selectedPermissions.length > 0
    ) {
      const obj = {
        Type: "2",
        IsAllInfo: true,
        PGroupId: this.state.filterSelectionData.value,
        UserIds: userIdsList,
        Apps: this.state.selectedPermissions,
      };
      const updatedObj = JSON.stringify(obj);
      const { data } = await apiPost(GROUPSEARCH, updatedObj);
      data && this.searchSuccessCB(data);
    } else if (this.state.searchPressed && this.state.searchText) {
      let obj = {
        Query: this.state.searchText,
        IsAllInfo: true,
        Type: 1,
      };
      const apiInput = JSON.stringify(obj);
      const { data } = await apiPost(GROUPSEARCH, apiInput);
      data && this.searchSuccessCB(data);
    } else {
      reloadGroups && this.fetchGroups();
    }
  };

  openParallelDrawer = () =>
    this.setState({ openParallelDrawer: true, offset: "400px" });

  toggleDrawer = (side, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (side === "right") {
      this.setState({
        [side]: open,
        isFilter: false,
        parentSelectionData: {},
      });
    } else if (side === "openFilterDrawer") {
      if (open === false) {
        this.setState((state, props) => ({
          [side]: open,
          isFilter: true,
          filterSelectionData: props.parentGroupFilter,
          selectedUsersList: props.usersList,
          selectedPermissions: props.userPermissions,
          selectedApps: props.AppsSelection,
        }));
      } else {
        this.setState({
          [side]: open,
          isFilter: true,
        });
      }
    }
  };

  closeChangeGroup = () =>
    this.setState({ openParallelDrawer: false, offset: "0px" });

  closeUserList = () =>
    this.setState({
      openUserListDrawer: false,
      offset: "0px",
      usersSaved: false,
    });

  onSaveUsers = (users) =>
    this.setState({
      openUserListDrawer: false,
      offset: "0px",
      usersSaved: true,
      selectedUsersList: users,
    });

  onAddUserClick = () =>
    this.setState({ openUserListDrawer: true, offset: "350px" });

  onParentSelectionChange = (parentSelectionData) => {
    if (this.state.isFilter === true) {
      this.setState({
        filterSelectionData: parentSelectionData,
        openParallelDrawer: false,
        offset: "0px",
      });
    } else {
      this.setState({
        parentSelectionData: parentSelectionData,
        openParallelDrawer: false,
        offset: "0px",
      });
    }
  };

  getTranslatedString = (constantName) => {
    return injectIntlTranslation(this.props.intl, constantName);
  };

  onAddPermissions = () =>
    this.setState({ openPermissionsDrawer: true, offset: "350px" });

  closePermissionsDrawer = () =>
    this.setState({ openPermissionsDrawer: false, offset: "0px" });

  onChangeOfSearchText = (e) => {
    this.setState(
      { searchText: e.target.value, showSuggestion: true },
      async () => {
        if (
          this.state.searchText.length === 1 &&
          this.state.suggestions.length === 0
        ) {
          const { data } = await apiGet(GROUPNAMES);
          this.getGroupsSuccessCB(data);
        }
        if (this.state.searchText.length === 0) {
          this.expandedGroups = [];
          this.setState({ searchPressed: false });
        }
        this.filterSuggestions();
      }
    );
  };

  getGroupsSuccessCB = (groups) => {
    this.setState({ suggestions: groups });
    this.filterSuggestions();
  };

  filterSuggestions = () => {
    const suggestions = this.state.suggestions;
    if (suggestions.length !== 0) {
      const filteredSuggestions = suggestions.filter((group) =>
        group.toLowerCase().includes(this.state.searchText.toLowerCase())
      );
      this.state.searchText &&
        (filteredSuggestions.length === 0
          ? this.setState({ noMatch: true })
          : this.setState({ noMatch: false }));
      this.setState({ filteredSuggestions: filteredSuggestions });
    }
  };

  onSelectSuggestion = (selectedIndex) => {
    this.setState({
      searchText: this.state.filteredSuggestions[selectedIndex],
      showSuggestion: false,
    });
  };

  blurHandler = (event) => {
    this.setState({
      showSuggestion: false,
    });
  };

  onSearchClick = async () => {
    if (this.state.searchText && !this.state.noMatch) {
      let obj = {
        Query: this.state.searchText,
        IsAllInfo: true,
        Type: 1,
      };
      const apiInput = JSON.stringify(obj);
      const { data } = await apiPost(GROUPSEARCH, apiInput);
      this.searchSuccessCB(data);
    }
  };

  searchSuccessCB = (result) => {
    this.expandedGroups = [];
    if(result.length > 0){
       this.setState({
          Group: result,
          noFilteredData: false,
          showSuggestion: false,
          searchPressed: true,
          openFilterDrawer: false,
        })
     }else{
      this.setState({
        Group: result,
        loading: false,
        noMatch: true
      });
     }
  };

  filterSuccessCB = (result) => {
    this.expandedGroups = [];
    result.length > 0
      ? this.setState({
          Group: result,
          noFilteredData: false,
          searchPressed: true,
          openFilterDrawer: false,
          filterApplied: true,
        })
      : this.setState({ openFilterDrawer: false, noFilteredData: true });
  };

  onClearSearch = () => {
    if (this.state.searchText) {
      this.fetchGroups();
      this.setState(
        { searchText: "", showSuggestion: false, searchPressed: false },
        () => {
          this.expandedGroups = [];
        }
      );
    }
  };

  onClearFilter = () => {
    this.fetchGroups();
    this.setState(
      {
        filterSelectionData: {},
        selectedUsersList: [],
        isFilter: false,
        searchPressed: false,
        clearFilter: !this.state.clearFilter,
        filterCount: 0,
        permissionCount: 0,
        noFilteredData: false,
        selectedPermissions: [],
        selectedApps: [],
        permissionName: "",
      },
      () => {
        this.expandedGroups = [];
      }
    );
    this.props.setParentGroupFilter({});
    this.props.setUsersSelection([]);
    this.props.setUserPermissions([], []);
  };

  onClearFilterSelection = (type) => {
    if (type === "parentGroup") {
      this.setState({ filterSelectionData: {} });
    } else if (type === "userName") {
      this.setState({ selectedUsersList: [] });
    } else if (type === "permission") {
      this.setState({ selectedPermissions: [], selectedApps: [] });
    }
  };

  keyPressed = (e) => {
    if (e.key === ENTER) {
      this.onSearchClick();
    }
  };

  permissionsSave = (apps, selectedApps = []) => {
    const permissionName = this.permissionsList.filter(
      (item) => item.appId == apps[0].AppId
    )[0].name;
    this.setState({
      openPermissionsDrawer: false,
      offset: "0px",
      selectedPermissions: apps,
      selectedApps: selectedApps,
      permissionName: permissionName,
    });
  };

  getPermissions = (data) => {
    this.permissionsList = data;
  };

  getGroupFilterTooltip = () => {
    let tooltipMsg = "";
    if (this.state.filterSelectionData !== undefined && this.state.filterSelectionData !== null &&
      Object.keys(this.state.filterSelectionData).length != 0) {
      tooltipMsg += `Parent Group: ${this.state.filterSelectionData.label} <br /><br />`;
    }

    if (this.state.selectedUsersList && this.state.selectedUsersList.length != 0) {
      const userList = this.state.selectedUsersList.map((user) => (user.name == ' ')? user.email : user.name );
      tooltipMsg += `User Name(s): ${userList.join(", ")}<br /><br />`;
    }  

    let permissions = [];
    {this.state.selectedPermissions.map(app => {
        permissions.push(this.permissionsList.filter((item) => item.appId == app.AppId)[0].name);
    })};

    if(permissions.length != 0)
      tooltipMsg += `Permission(s): ${permissions.join(", ")}<br />`;

    return tooltipMsg;
  };

  render() {
    const {
      parentSelectionData: { label, value },
    } = this.state;
    const { classes } = this.props;
    return (
      <>
        <div>
          <div className="mainDiv">
            <div>
              {
              this.state.filterSelectionData.label ||
              this.state.selectedUsersList.length > 0 ||
              this.state.selectedPermissions.length > 0  ? (
                <div className="filterActions">
                  <Button
                    className={classes.deleteFilter}
                    variant="contained"
                    color="primary"
                    endIcon={<DeleteIcon onClick={this.onClearFilter} />}
                    data-tip
                    data-for="filterGroupTip"
                  >
                    {this.state.filterCount} Filter(s)
                  </Button>
                  <ReactTooltip
                    id="filterGroupTip"
                    type="dark"
                    place="bottom"
                    effect="solid"
                    multiline="true"
                    html={true}
                    className={classes.groupFilterTooltip}
                  >
                    {this.getGroupFilterTooltip()}
                  </ReactTooltip>
                  <Button
                    className={classes.editFilter}
                    variant="contained"
                    color="primary"
                    onClick={this.toggleDrawer("openFilterDrawer", true)}
                  >
                    {this.getTranslatedString(EDIT)}
                  </Button>
                </div>
              ) : (
                <input
                  type="button"
                  value={this.getTranslatedString(FILTERS)}
                  className="filterBtn"
                  onClick={this.toggleDrawer("openFilterDrawer", true)}
                ></input>
              )}
              <input
                type="button"
                value={this.getTranslatedString(ADDAGROUP)}
                className="addButton"
                onClick={this.toggleDrawer("right", true)}
              ></input>
              <div className="groupSearch">
                <TextField
                  value={this.state.searchText}
                  onChange={this.onChangeOfSearchText}
                  onKeyDown={this.keyPressed}
                  onBlur={this.blurHandler}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <IconButton>
                          <SearchIcon onClick={this.onSearchClick} />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment>
                        <IconButton>
                          <ClearIcon onClick={this.onClearSearch} />
                        </IconButton>
                      </InputAdornment>
                    ),
                    className: "dashboardSearchBox",
                  }}
                />
                {this.state.showSuggestion === true &&
                  this.state.searchText.length > 0 &&
                  this.state.filteredSuggestions.length > 0 && (
                    <div className="dashboardSuggestions" tabIndex={1}>
                      <ul className="internalUsersInfo">
                        {this.state.filteredSuggestions.map((group, index) => (
                          <li
                            className="suggestionHighlight"
                            key={index}
                            onMouseDown={() => this.onSelectSuggestion(index)}
                          >
                            <div className="internalUserName">{group}</div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
              </div>
            </div>
            <p className="h-2-black-bold">{GROUPSTEXT}</p>
          </div>
          {!this.state.Group.length > 0 ? (
            <p className="h-2-sub-title">
              {this.state.loading ? LOADINGTEXT : (this.state.noMatch && this.state.searchText)? 
              <div className={"searchError"}>{SEARCHERROR}</div>: LOADINGTEXT}
            </p>
          ) : (
            <React.Fragment>
              <CssBaseline />
              <Container maxWidth="xl">
                {(this.state.noMatch && this.state.searchText) ||
                this.state.noFilteredData ? (
                  <div className={"searchError"}>{SEARCHERROR}</div>
                ) : (
                  <ul className="GroupsList" style={{ paddingLeft: "0px" }}>
                    <li className="GroupsListHeader">
                      <div className="ExpendedHeaderColumn">{NAME}</div>
                      <div
                        data-tip={this.getTranslatedString(EDITTOOLTIP)}
                        className="HeaderIconColumn_edit"
                      >
                        <img
                          className="GroupsTableHeaderIcon"
                          src={BlackEdit}
                          xs={2}
                        />
                      </div>
                      <div
                        data-tip={this.getTranslatedString(GROUPSTOOLTIP)}
                        className="HeaderIconColumn"
                      >
                        <img
                          className="GroupsTableHeaderIcon"
                          src={Globe}
                          xs={2}
                        />
                      </div>
                      <div
                        data-tip={this.getTranslatedString(
                          ADMINSTRATORSTOOLTIP
                        )}
                        className="HeaderIconColumn"
                      >
                        <img
                          className="GroupsTableHeaderIcon"
                          src={Admin}
                          xs={2}
                        />
                      </div>
                      <div
                        data-tip={this.getTranslatedString(USERSTOOLTIP)}
                        className="HeaderIconColumn"
                      >
                        <img
                          className="GroupsTableHeaderIcon"
                          src={User}
                          xs={2}
                        />
                      </div>
                    </li>
                    {this.DisplayRootGroup()}
                  </ul>
                )}
              </Container>
              
              <RightDrawer
                customProps={{ right: this.state.offset }}
                open={this.state.right}
              >
                <AddGroup
                  openParallelDrawer={this.openParallelDrawer}
                  selectedItem={this.state.parentSelectionData}
                  setEditGroupId={this.setEditGroupId}
                  expandedGroups={this.expandedGroups}
                  onClose={this.toggleDrawer("right", false)}
                />
              </RightDrawer>
              <RightDrawer
                customProps={{ width: this.state.offset }}
                open={this.state.openParallelDrawer}
              >
                <ChangeGroup
                  selectedGroupId={
                    this.state.isFilter
                      ? this.state.filterSelectionData.value
                      : value
                  }
                  selectedGroupName={
                    this.state.isFilter
                      ? this.state.filterSelectionData.label
                      : label
                  }
                  callApiOnSave={false}
                  onParentChange={this.onParentSelectionChange}
                  onClose={this.closeChangeGroup}
                />
              </RightDrawer>
              <RightDrawer
                customProps={{ right: this.state.offset }}
                open={this.state.openFilterDrawer}
              >
                <AddFilter
                  openParallelDrawer={this.openParallelDrawer}
                  onAddUserClick={this.onAddUserClick}
                  onAddPermissions={this.onAddPermissions}
                  selectedItem={this.state.filterSelectionData}
                  onClearFilterSelection={this.onClearFilterSelection}
                  setFilteredData={this.filterSuccessCB}
                  onClose={this.toggleDrawer("openFilterDrawer", false)}
                  selectedValues={this.state.selectedUsersList}
                  selectedPermissions={this.state.selectedPermissions}
                  selectedApps={this.state.selectedApps}
                  usersSaved={this.state.usersSaved}
                  permissionName={this.state.permissionName}
                  permissionCount={this.state.permissionCount}
                />
              </RightDrawer>
              <RightDrawer
                customProps={{ width: this.state.offset }}
                open={this.state.openUserListDrawer}
              >
                <UsersList
                  onSaveUsers={this.onSaveUsers}
                  onClose={this.closeUserList}
                  selectedUsersList={this.state.selectedUsersList}
                />
              </RightDrawer>
              {this.state.openPermissionsDrawer && (
                <SettingsDrawer
                  open={true}
                  headerLabel={FILTER_PERMISSIONS}
                  groupLevel={DEFAULTVALUE}
                  callSaveApi={false}
                  unSelectByDefault
                  selectedPermissions={this.state.selectedApps}
                  onPermissionsSave={this.permissionsSave}
                  getPermissions={this.getPermissions}
                  isEditable={true}
                  onClose={this.closePermissionsDrawer}
                  offset={this.state.offset}
                />
              )}
              <RightDrawer fullScreen open={this.state.fullScreen}>
                <EditGroup
                  id={this.state.editGroupId}
                  expandedGroups={this.expandedGroups}
                  deleteGroupHandler={this.deleteGroupHandler}
                  onClose={this.setEditGroupId}
                />
              </RightDrawer>
            </React.Fragment>
          )}
          <ToastMessages
                statusMessage={this.state.statusMessage}
                message={this.state.message}
                variant={this.state.messageVariant}
                toastHPosition={this.state.toastHPosition}
                toastVPosition={this.state.toastVPosition}
                close={this.handleToast.bind(this)}
              />
        </div>
      </>
    );
  }
}

Groups.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  parentGroupFilter: state.filter.parentGroupFilter,
  usersList: state.filter.usersList,
  userPermissions: state.filter.userPermissions,
  AppsSelection: state.filter.AppsSelection,
});

export default injectIntl(
  connect(mapStateToProps, {
    setParentGroupFilter,
    setUsersSelection,
    setUserPermissions,
  })(withStyles(styles)(Groups))
);
