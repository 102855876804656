import { makeStyles } from '@material-ui/core/styles';

export const usersAutoCompleteStyles = makeStyles((theme) => ({
    autoCompleteOptions: {
        padding: "0px"
    },
    nonErrorBorder: {
        borderColor: "#bcbcbc !important"
    },
    autoCompleteDiv: {
        display: "flex",
        margin: "9px"
    }
}));