import { makeStyles } from '@material-ui/core/styles';

const DeveloperTabStyles = makeStyles((theme) => ({
    developerDesc: { 
        textAlign: 'justify',
    },
    instructions: {
        overflow: 'auto',
        height: '68vh'
    },
    refLink: {
        paddingLeft: '8px'
    },
    step1 : {
      paddingBottom :'5px'
    },
    developerDesc: { 
        textAlign: 'justify',
    },
    step1 : {
        paddingTop :'18px'
    },
    normalBtn: {
        maxHeight: "28px",
        minWidth: "102px",
        "background-color": "#0033AB !important",
        color: "#FFF!important",
        border: "1px solid #0033AB",
        "border-radius": "3px",
        fontSize: "1rem",
        "&:hover": {
            "box-shadow": "0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15)",
            "-webkit-box-shadow": "0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15)",
            "outline": 0
        },
        
    },
}));

export default DeveloperTabStyles;
