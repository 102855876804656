import axiosInstance from '../../../Shared/Interceptor/interceptor';
import appConfig from '../../../../Environment/environments';
import { encryptData } from '../../../../Common/Utilities/utility';
import { FETCHPERMISSIONS, DELETEROOTPERMISSION, DELETECHILDPERMISSION, CREATEPERMISSION} from '../../../../Common/Constants/constants';

export const getPermission = async (appId) => {
    try {
        const response = await axiosInstance.get(`${appConfig.api.development}${FETCHPERMISSIONS}${encryptData(appId)}`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            });
        return response.data;
    } catch (error) {
      throw error;
    }
}
  
export const deletePermission = async (appId, parentSettingId, settingId) => {
    try {
        let URL = (parentSettingId === 0) ? `${DELETEROOTPERMISSION}${encryptData(appId)}${'&settingId='}${encryptData(settingId)}` : (DELETECHILDPERMISSION + encryptData(settingId));
        const response = axiosInstance.delete(`${appConfig.api.development}${URL}`,
            {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            });
        return response;
    } catch (error) {
        throw error;
    }
}

export const addPermission = async (isRootPermission,appId, parentSettingId, settingName) => {
    try {
        let obj = !isRootPermission ? {"ParentSettingId": parentSettingId, "AppId": 0,"SettingName": settingName} : {"ParentSettingId": 0,"AppId": appId,"SettingName": settingName};
      const response = await axiosInstance.post(`${appConfig.api.development}${CREATEPERMISSION}`, JSON.stringify(obj), 
          {
            method: 'POST',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        });
        return response;
    } catch (error) {
        throw error;
    }
}

export const updatePermission = async (settingId, settingName) => {
    try {
        const response = await axiosInstance.put(`${appConfig.api.development}${CREATEPERMISSION}/${settingId}`, JSON.stringify({"SettingName": settingName}), 
        {
          method: 'PUT',
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      });
        return response;
    } catch (error) {
        throw error;
    }
}
